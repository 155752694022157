import { Component, Input, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialog } from '@angular/material/dialog';
import { AuthGuard } from '../../guards/access-allowed.guard';
import { OrdersService } from 'src/app/pages/orders/services/orders.service';
import { taskHistoryFile } from 'nx/src/utils/task-history';
@Component({
  selector: 'app-show-preauth',
  templateUrl: './show-preauth.component.html',
  styleUrls: ['./show-preauth.component.scss'],
})
export class ShowPreAuthComponent {
  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    public Auth: AuthGuard,
    private ordersService: OrdersService
  ) { }

  action: any = '';
  confirmDialog: any = false;

  confirm(action: any) {
    console.log(this.data);
    this.confirmDialog = true;
    this.action = action;
    if (action === 'accept') {
      this.action = 'AKCEPTACJA';
    }
    if (action === 'reject') {
      this.action = 'ODRZUCONO';
    }
    if (action === 'total damage') {
      this.action = 'SZKODA CAŁKOWITA';
    }
  }
  cancel() {
    this.confirmDialog = false;
  }

  send() {
    const relatedOrder = this.data.preAuth.relatedOrder.replace(
      '/api/v1/internal/orders/',
      ''
    );
    this.ordersService
      .sendPreAuthDecision(relatedOrder, this.action)
      .subscribe(() => {
        this.confirmDialog = false;
        this.getPreAuth();
      });
  }

  getPreAuth() {
    const relatedOrder = this.data.preAuth.relatedOrder.replace(
      '/api/v1/internal/orders/',
      ''
    );
    this.ordersService.getOrderPreAuth(relatedOrder).subscribe(res => {
      console.log(res);
      this.data.preAuth = res;
    });
  }
}
