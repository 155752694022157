<div
  infiniteScroll
  (scrolled)="onScrollDown()"
  [infiniteScrollDistance]="2"
  [infiniteScrollThrottle]="50"
  [infiniteScrollContainer]="selector"
  [fromRoot]="true"
  class="container-comments"
>
@if(isLoading){
  <div class="spinner-container">
    <mat-spinner/>
  </div>
}
@else if(comments && comments.length){
  @for (comment of comments; track $index) {
    <app-notification-item
      [routerLink]="'/orders/order/' + comment.orderId"
      [notificationType]="comment.commentType"
      [title]="(comment.commentType | translate) + ' | '+ (comment.orderFaultNumber | dashIfEmpty)"
      [description]="comment.commentText | dashIfEmpty"
      [time]="comment.commentCreatedAt"
      (click)="onDismissOpenModal()"
    />
  }
  @if(commentsFilterCategory === "ALL" || (commentsFilterCategory === "UNREAD" && comments.length >=10)){
    <div class="load-comments-container">
      @if(isMoreCommentsLoading){
        <mat-spinner class="mat-spinner" matTooltip="Ładowanie"/>
      }
      @else{
        <button mat-icon-button color="primary" (click)="onScrollDown()">
          <mat-icon 
            matTooltip="Załaduj więcej komentarzy"
          >
            arrow_downward
          </mat-icon>
        </button>
      }
    </div>
  }
}
@else{
  <app-no-content
    [title]="'Brak' + (commentsFilterCategory === 'UNREAD' ? ' nowych ' : ' ') + 'komentarzy'"
  />
}
</div>