import { Component, Inject, OnInit, inject } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ServicesService } from '../../../pages/services/services/services.service';
import { environment } from 'src/app/environments/environment';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { faXmark } from '@fortawesome/free-solid-svg-icons';
@Component({
  selector: 'edit-service-address',
  templateUrl: './edit-service-address.component.html',
  styleUrls: ['./edit-service-address.component.scss'],
})
export class EditServiceAddressComponent implements OnInit {

  public faXmark = faXmark;

  public formCompanyInfo!: FormGroup;
  public formAddressInfo!: FormGroup;
  public formContactInfo!: FormGroup;

  public submitted!: boolean;
  public companyNext!: boolean;
  public addressNext!: boolean;
  public gusError: any;
  public gusInfo!: boolean;
  public tab: any = 'companyInfo';
  id: any;
  service: any;
  constructor(
    private fb: FormBuilder,
    private serviceService: ServicesService,
    private dialogRef: MatDialogRef<EditServiceAddressComponent>,
    private _snackBar: MatSnackBar,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {
    this.id = data.id;
  }

  download(): void {}

  ngOnInit(): void {
    this.companyNext = true;
    this.formCompanyInfo = this.fb.group({
      number: ['', Validators.required],
      city: [
        null,
        [
          Validators.required,
          Validators.minLength(2),
          Validators.maxLength(20),
        ],
      ],
      postalCode: [
        '',
        [
          Validators.required,
          Validators.pattern(environment.validators.postal_code),
        ],
      ],
      street: [
        null,
        [
          Validators.required,
          Validators.minLength(3),
          Validators.maxLength(100),
        ],
      ],
      houseNumber: [
        '',
        [
          Validators.required,
          Validators.pattern(environment.validators.home_number),
        ],
      ],
      apartmentNumber: [''],
    });
    this.getData();
  }

  getData(): void {
    this.serviceService.getServiceById(this.id).subscribe(res => {
      this.service = res;
      this.formCompanyInfo.patchValue({
        name: this.service.name,
        nip: this.service.taxNumber,
        regon: this.service.regon,
        number: this.service.contractNumber,
        bankNumber: this.service.bankAccountNumber,
        postalCode: this.service.address.postalCode,
        city: this.service.address.city,
        street: this.service.address.streetName,
        houseNumber: this.service.address.buildingNumber,
        apartmentNumber: this.service.address.apartmentNumber,
      });
    });
  }

  save(): void {
    this.submitted = true;
    if (this.formCompanyInfo.valid) {
      let body = {
        name: this.formCompanyInfo.value.name,
        contractNumber: this.formCompanyInfo.value.number,
        bankAccountNumber: this.formCompanyInfo.value.bankNumber,
        taxNumber: this.formCompanyInfo.value.nip,
        regon: this.formCompanyInfo.value.regon,
        address: {
          city: this.formCompanyInfo.value.city,
          postalCode: this.formCompanyInfo.value.postalCode,
          post: this.formCompanyInfo.value.city,
          province: this.formCompanyInfo.value.city,
          streetName: this.formCompanyInfo.value.street,
          buildingNumber: this.formCompanyInfo.value.houseNumber,
          apartmentNumber: this.formCompanyInfo.value.apartmentNumber,
        },
      };

      this.serviceService
        .editServices(this.id, body)
        .subscribe((res: any) => {});
      this.dialogRef.close();
      this._snackBar.open('Edycja zakończona sukcesem', 'Zamknij', {
        duration: 3000,
      });
    }
  }
}
