import { Component, OnInit } from '@angular/core';
import {
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from '@angular/forms';
import { ServicesService } from '../../../../pages/services/services/services.service';
import { environment } from 'src/app/environments/environment';
import { MatDialogRef } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { faXmark } from '@fortawesome/free-solid-svg-icons';
@Component({
  selector: 'app-add-service-info',
  templateUrl: './add-service-info.component.html',
  styleUrls: ['./add-service-info.component.scss'],
})
export class AddServiceInfoComponent implements OnInit {

  public faXmark = faXmark;

  public formCompanyInfo!: FormGroup;
  public formContactInfo!: FormGroup;

  public gusError: any;
  public submitted!: boolean;
  public companyNext!: boolean;

  public gusInfo!: boolean;

  public tab: any = 'companyInfo';
  phone1!: FormControl<string | null>;
  phone2!: FormControl<string | null>;

  constructor(
    private fb: FormBuilder,
    private serviceService: ServicesService,
    private dialogRef: MatDialogRef<AddServiceInfoComponent>,
    private _snackBar: MatSnackBar
  ) {}

  download(): void {}

  ngOnInit(): void {
    this.formCompanyInfo = this.fb.group({
      isAutoOrderConfirmChecked: [true, [Validators.required]],
      name: ['', [Validators.required]],
      nip: [
        '',
        [Validators.required, Validators.pattern(environment.validators.nip)],
      ],
      regon: [
        '',
        [Validators.required, Validators.pattern(environment.validators.regon)],
      ],
      number: ['', Validators.required],
      bankNumber: [
        '',
        [
          Validators.required,
          Validators.pattern(environment.validators.bank_account_number),
        ],
      ],
      active: [true],
      city: [
        null,
        [
          Validators.required,
          Validators.minLength(2),
          Validators.maxLength(20),
        ],
      ],
      postalCode: [
        '',
        [
          Validators.required,
          Validators.pattern(environment.validators.postal_code),
        ],
      ],
      street: [
        null,
        [
          Validators.required,
          Validators.minLength(3),
          Validators.maxLength(100),
        ],
      ],
      houseNumber: [
        '',
        [
          Validators.required,
          Validators.pattern(environment.validators.home_number),
        ],
      ],
      apartmentNumber: [''],
    });

    this.formContactInfo = this.fb.group({
      name: [
        '',
        [Validators.required, Validators.pattern(environment.validators.name)],
      ],
      surname: [
        '',
        [
          Validators.required,
          Validators.pattern(environment.validators.surname),
        ],
      ],
      phoneNumber: ['', [Validators.required, Validators.pattern('[+0-9 ]*')]],

      secondNumber: ['', [Validators.pattern('[+0-9 ]*')]],

      email: [
        '',
        [Validators.required, Validators.pattern(environment.validators.email)],
      ],
      type: ['', Validators.required],
    });

    this.phone1 = this.formContactInfo.get('phoneNumber') as FormControl;
    this.phone2 = this.formContactInfo.get('secondNumber') as FormControl;
  }

  back(): void {
    if (this.tab === 'contact') {
      this.tab = 'companyInfo';
    }
  }

  next(): void {
    if (this.tab === 'companyInfo') {
      this.companyNext = true;
      if (this.formCompanyInfo.valid) {
        this.tab = 'contact';
      }
    }
  }

  getGusInfo(): void {
    this.gusError = false;
    if (this.formCompanyInfo.value.nip.length === 10) {
      this.gusInfo = false;
      this.serviceService
        .getGusInfo(this.formCompanyInfo?.value?.nip)
        .subscribe(
          (res: any) => {
            this.formCompanyInfo.patchValue({
              name: res?.name,
              regon: res?.regon,
            });
            this.formCompanyInfo.patchValue({
              city: res?.address?.city,
              postalCode: res?.address?.postalCode,
              street: res?.address?.streetName,
              houseNumber: res?.address?.buildingNumber,
              apartmentNumber: res?.address?.apartmentNumber,
            });
          },
          (error: any) => {
            if (error == 404) {
              this.gusError = 404;
            } else if (error == 400) {
              this.gusError = 400;
            } else {
              this.gusError = 'other';
            }
          }
        );
    } else {
      this.gusInfo = true;
    }
  }

  save(): void {
    this.submitted = true;
    if (this.formCompanyInfo.valid && this.formContactInfo.valid) {
      const body = {
        name: this.formCompanyInfo.value.name,
        address: {
          city: this.formCompanyInfo.value.city,
          postalCode: this.formCompanyInfo.value.postalCode,
          post: this.formCompanyInfo.value.city,
          province: this.formCompanyInfo.value.city,
          streetName: this.formCompanyInfo.value.street,
          buildingNumber: this.formCompanyInfo.value.houseNumber,
          apartmentNumber: this.formCompanyInfo.value.apartmentNumber,
        },
        contractNumber: this.formCompanyInfo.value.number,
        bankAccountNumber: this.formCompanyInfo.value.bankNumber,
        taxNumber: this.formCompanyInfo.value.nip,
        regon: this.formCompanyInfo.value.regon,
        active: this.formCompanyInfo.value.active === 'true',
        autoConfirmOrders: this.formCompanyInfo.value.isAutoOrderConfirmChecked
      };

      this.serviceService.addServices(body).subscribe((res: any) => {
        const body = {
          workshop: res['@id'],
          contactDetails: {
            email: this.formContactInfo.value.email,
            mobileNumber: this.formContactInfo.value.phoneNumber.replace(
              / /g,
              ''
            ),
            phoneNumber: this.formContactInfo.value.secondNumber.replace(
              / /g,
              ''
            ),
            description: 'string',
            name: this.formContactInfo.value.name,
            surname: this.formContactInfo.value.surname,
            type: 'generic',
          },
        };
        this.serviceService.addServiceContact(body).subscribe(res => {
          this.dialogRef.close();

          this._snackBar.open('Dodano serwis', 'Zamknij', {
            duration: 3000,
          });
        });
      });
    }
    else{
      this._snackBar.open('Fromularz zawiera nieprawidłowe informacje', 'Zamknij', {
        duration: 3000,
      });
    }
  }
}
