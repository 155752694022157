<div class="shared-modal-container">
  <div class="shared-modal-header" mat-dialog-draggable-title>
    <h6>
      {{
        !isEdit
          ? ('users-front.user_data' | translate)
          : ('users-front.user_edit' | translate)
      }}
    </h6>
    <button class="shared-modal-close-btn" mat-dialog-close="cancel">
        <fa-icon [icon]="faXmark"/>
    </button>
  </div>
  <div class="shared-modal-body">
      @if(showError){
        <div class="error-message">
          <fa-icon [icon]="faBell"/>
          {{
            'users-front.a_user_with_the_specified_email_address_already_exists' | translate
          }}
        </div>
      }
      @if(showGeneralError){
        <div class="error-message">
          <fa-icon [icon]="faBell"/>
          Nie udało się zedytować użytkownika
        </div>
      }
    <form class="filters-inputs" [formGroup]="form">
      <div class="row grid-1">
        <mat-form-field appearance="outline">
          <mat-label>{{ 'users-front.email_address' | translate }}</mat-label>
          <input matInput formControlName="email" type="text" />
          <mat-error *ngIf="submitted && form.controls['email'].errors?.['required']">
            {{ 'users-front.field_is_required' | translate }}
          </mat-error>
          <mat-error *ngIf="submitted && form.controls['email'].errors?.['email']">
            {{ 'users-front.email_is_incorrect' | translate }}
          </mat-error>
        </mat-form-field>
      </div>

      <div class="row grid-2">
        <mat-form-field appearance="outline">
          <mat-label>Imię</mat-label>
          <input matInput formControlName="firstName" type="text" />
          <mat-error *ngIf="submitted && form.controls['firstName'].errors?.['required']">
            {{ 'users-front.field_is_required' | translate }}
          </mat-error>
          <mat-error *ngIf="submitted && form.controls['firstName'].errors?.['minlength']">
            {{ 'users-front.a_minimum_of_2_characters_is_required' | translate }}
          </mat-error>
        </mat-form-field>
        
        <mat-form-field appearance="outline">
          <mat-label>{{ 'users-front.surname' | translate }}</mat-label>
          <input matInput formControlName="lastName" type="text" />
          <mat-error *ngIf="submitted && form.controls['lastName'].errors?.['required']">
            {{ 'users-front.field_is_required' | translate }}
          </mat-error>
          <mat-error *ngIf="submitted && form.controls['lastName'].errors?.['minlength']">
            {{ 'users-front.a_minimum_of_2_characters_is_required' | translate }}
          </mat-error>
        </mat-form-field>
      </div>

      <div class="row grid-1">
        <mat-form-field appearance="outline">
          <mat-label>{{ 'users-front.service' | translate }}</mat-label>
          <mat-select formControlName="workshop">
            <mat-option *ngFor="let service of services" [value]="service.id">
              {{ service.name }}
            </mat-option>
          </mat-select>
          <mat-error *ngIf="submitted && form.controls['workshop'].errors?.['required']">
            {{ 'services-front.field_is_required' | translate }}
          </mat-error>
        </mat-form-field>
      
        <mat-form-field appearance="outline">
          <mat-label>{{ 'users-front.repairUnit' | translate }}</mat-label>
          <mat-select 
            multiple 
            [disabled]="form.controls['workshop'].errors?.['required']"
            formControlName="repairUnits"
            >
            <mat-option *ngFor="let repairUnit of repairUnits" [value]="repairUnit.id">
              {{ repairUnit.name }}
            </mat-option>
          </mat-select>
        </mat-form-field>
      </div>

      <div class="row grid-2">
        <mat-form-field appearance="outline">
          <mat-label>Platforma</mat-label>
          <mat-select formControlName="platforms" multiple>
            <mat-option *ngFor="let platform of platforms" [value]="platform.techId">
              {{ platform.name }}
            </mat-option>
          </mat-select>
          <mat-error *ngIf="submitted && form.controls['platforms'].errors?.['required']">
            {{ 'services-front.field_is_required' | translate }}
          </mat-error>
        </mat-form-field>

        <mat-form-field appearance="outline">
          <mat-label>{{ 'users-front.user_group' | translate }}</mat-label>
      
          <mat-select formControlName="groups" [ngModel]="defaultGroupId">
            <mat-option *ngFor="let userGroup of userGroups" [value]="userGroup.id">
              {{ userGroup.name | translate }}
            </mat-option>
          </mat-select>
      
          <mat-error *ngIf="submitted && form.controls['groups'].errors?.['required']">
            {{ 'services-front.field_is_required' | translate }}
          </mat-error>
        </mat-form-field>
      </div>
    </form>
  </div>
  <div class="shared-modal-footer">
      <div class="shared-modal-footer-buttons-container">
          @if(isEdit){
            <button class="shared-modal-secondary-btn" mat-dialog-close="cancel">
              {{ 'users-front.cancel' | translate }}
            </button>
            <button
              [disabled]="isLoading"
              class="shared-modal-primary-btn"
              (click)="save()"
            >
              {{ 'users-front.save' | translate }}
            </button>
          }
          @else{
            <button class="shared-modal-secondary-btn" mat-dialog-close="cancel">
              {{ 'users-front.cancel' | translate }}
            </button>
            <button
              class="shared-modal-primary-btn"
              (click)="editOnOff()">
              {{ 'users-front.edit' | translate }}
            </button>
          }
      </div>
  </div>
</div>

<!-- <h1 mat-dialog-title class="title">
  {{
    !isEdit
      ? ('users-front.user_data' | translate)
      : ('users-front.user_edit' | translate)
  }}
</h1>
<hr class="custom-hr" />
<div mat-dialog-content>
  <mat-card class="card-error" *ngIf="showError">
    <mat-card-content>{{
      'users-front.a_user_with_the_specified_email_address_already_exists'
        | translate
    }}</mat-card-content>
  </mat-card>
  <mat-card class="card-error" *ngIf="showGeneralError">
    <mat-card-content>Nie udało się zedytować użytkownika</mat-card-content>
  </mat-card>
  <form [formGroup]="form">
    <div class="flex">
      <div class="did-floating-label-content full-width">
        <input
          class="did-floating-input"
          formControlName="email"
          type="text"
          placeholder=" "
          [ngClass]="{ 'gray-color': !isEdit }" />
        <label
          class="did-floating-label"
          [ngClass]="{ 'gray-color': !isEdit }"
          >{{ 'users-front.email_address' | translate }}</label
        >
        <mat-error
          *ngIf="submitted && form.controls['email'].errors?.['required']"
          >{{ 'users-front.field_is_required' | translate }}</mat-error
        >
        <mat-error
          *ngIf="submitted && form.controls['email'].errors?.['email']"
          >{{ 'users-front.email_is_incorrect' | translate }}</mat-error
        >
      </div>
    </div>

    <div class="flex">
      <div class="did-floating-label-content mr-10 mt-15">
        <input
          class="did-floating-input"
          formControlName="firstName"
          type="text"
          placeholder=" "
          [ngClass]="{ 'gray-color': !isEdit }" />
        <label class="did-floating-label" [ngClass]="{ 'gray-color': !isEdit }"
          >Imię</label
        >
        <mat-error
          *ngIf="submitted && form.controls['firstName'].errors?.['required']"
          >{{ 'users-front.field_is_required' | translate }}</mat-error
        >
        <mat-error
          *ngIf="submitted && form.controls['firstName'].errors?.['minlength']"
          >{{
            'users-front.a_minimum_of_2_characters_is_required' | translate
          }}</mat-error
        >
      </div>
      <div class="did-floating-label-content mt-15">
        <input
          class="did-floating-input"
          formControlName="lastName"
          type="text"
          placeholder=" "
          [ngClass]="{ 'gray-color': !isEdit }" />
        <label class="did-floating-label">{{
          'users-front.surname' | translate
        }}</label>
        <mat-error
          *ngIf="submitted && form.controls['lastName'].errors?.['required']"
          >{{ 'users-front.field_is_required' | translate }}</mat-error
        >
        <mat-error
          *ngIf="submitted && form.controls['lastName'].errors?.['minlength']"
          >{{
            'users-front.a_minimum_of_2_characters_is_required' | translate
          }}</mat-error
        >
      </div>
    </div>
    <div class="flex">
      <div class="did-floating-label-content mr-10 mt-15">
        <select
          class="did-floating-select"
          formControlName="workshop"
          *ngIf="isEdit">
          <option value="{{ service.id }}" *ngFor="let service of services">
            {{ service.name }}
          </option>
        </select>
        <input
          class="did-floating-input"
          formControlName="workshop"
          [value]="defaultWorkshop"
          type="text"
          placeholder=" "
          [ngClass]="{ 'gray-color': !isEdit }"
          *ngIf="!isEdit" />
        <label class="did-floating-label">{{
          'users-front.service' | translate
        }}</label>
        <mat-error
          *ngIf="submitted && form.controls['workshop'].errors?.['required']"
          >{{ 'services-front.field_is_required' | translate }}</mat-error
        >
      </div>
      <div class="did-floating-label-content mt-15">
        <mat-select
          multiple
          [disabled]="form.controls['workshop'].errors?.['required']"
          class="did-floating-select"
          formControlName="repairUnit"
          [ngClass]="{
            'gray-color': !isEdit,
          }"
          [(ngModel)]="repairUnitsArray"
          [disabled]="!isEdit">
          <mat-option
            value="{{ repairUnit.id }}"
            *ngFor="let repairUnit of repairUnits">
            {{ repairUnit.name }}
          </mat-option>
        </mat-select>
        <mat-label
          class="did-floating-label"
          [ngClass]="{ 'active': repairUnitsArray.length > 0 }"
          >{{ 'users-front.repairUnit' | translate }}</mat-label
        >
      </div>
    </div>
    <div class="flex">
      <div class="did-floating-label-content mt-15">
        <select
          class="did-floating-select"
          formControlName="groups"
          *ngIf="isEdit"
          [ngModel]="defaultGroupId">
          <option
            value="{{ userGroup.id }}"
            *ngFor="let userGroup of userGroups">
            {{ userGroup.name | translate }}
          </option>
        </select>
        <input
          class="did-floating-input"
          formControlName="groups"
          type="text"
          placeholder=" "
          [ngClass]="{ 'gray-color': !isEdit }"
          [value]="defaultGroup | translate"
          *ngIf="!isEdit" />
        <label class="did-floating-label">{{
          'users-front.user_group' | translate
        }}</label>
        <mat-error
          *ngIf="submitted && form.controls['groups'].errors?.['required']"
          >{{ 'services-front.field_is_required' | translate }}</mat-error
        >
      </div>
    </div>
  </form>
</div>
<div mat-dialog-actions>
  <button
    mat-raised-button
    *ngIf="isEdit"
    class="secondary-btn"
    [mat-dialog-close]="'cancel'">
    {{ 'users-front.cancel' | translate }}
  </button>
  <button
    *ngIf="isEdit"
    [disabled]="isLoading"
    mat-raised-button
    class="primary-btn"
    (click)="save()">
    {{ 'users-front.save' | translate }}
  </button>
  <button
    *ngIf="!isEdit"
    mat-raised-button
    class="secondary-btn"
    [mat-dialog-close]="'cancel'">
    {{ 'users-front.close' | translate }}
  </button>
  <button
    *ngIf="!isEdit"
    mat-raised-button
    class="primary-btn"
    (click)="editOnOff()">
    {{ 'users-front.edit' | translate }}
  </button>
</div> -->
