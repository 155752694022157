import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { flattenFormValues } from 'dashboard-libs/helpers/helpers';
import { ConfirmationModalComponent } from 'dashboard-libs/modals/confirmation-modal/confirmation-modal.component';
import { ReuploadTallyModalComponent } from 'dashboard-libs/modals/reupload-tally-modal/reupload-tally-modal.component';
import { Subscription } from 'rxjs';
import { CostsService, Paginator } from 'src/app/shared/services/costs.service';

export interface CompilationDetails {
  "@context": string
  "@id": string
  "@type": string
  id: string
  invoiceListNumber: string
  invoiceNumber: string
  ordersCount: number
  totalNet: number
  totalGross: number
  ordersSettlementsData: OrdersSettlementsDaum[]
}

export interface OrdersSettlementsDaum {
  "@id": string
  "@type": string
  id: string
  faultNumber: string
  finishDate: string
  repairType: any
  customer: string
  closeDate: string
  state: string
  period: any
  totalNet: number
  totalGross: number
  billingInvoice: string
}

@Component({
  selector: 'app-compilation-details',
  standalone: false,
  templateUrl: './compilation-details.component.html',
  styleUrl: './compilation-details.component.scss',
})
export class CompilationDetailsComponent implements OnInit, OnDestroy{

  compilationDetails: any[] = [];

  private routerSubscription!: Subscription;

  private compilationDetailsId!: string | null;

  public readonly tableHeaders = [
    "Numer zlecenia",
    "Data zakończenia zlecenia",
    "Rodzaj naprawy",
    "Klient",
    "Data akceptacji",
    "Okres rozl.",
    "Koszt netto",
    "Koszt brutto",
  ];

  public totalElementsCount : number = 0;

  public compilationDetailsList : OrdersSettlementsDaum[] = [];

  public isLoading: boolean = true;

  public totalSummaryData = {
    gross: 0,
    net: 0
  }

  public paginatorForm = this.formBuilder.group({
    limit: [10],
    offset: [0],
  })

  public compilationDetailsForm = this.formBuilder.group({
    invoiceNumber: ['', []],
    dataWystawienia: ['', []],
  });

  constructor(
    private formBuilder: FormBuilder,
    private costsService: CostsService,
    private route: ActivatedRoute,
    private dialog: MatDialog,
    private router: Router
  ) { 
    this.compilationDetailsId = this.route.snapshot.paramMap.get('id');
  }

  ngOnInit(): void {
    this.fetchCompilationDetailsList();
  }

  onSubmit(e: Event) : void {
    e.preventDefault();
  }

  resetPaginatorForm(): void {
    const currentLimit = this.paginatorForm.get('limit')?.value;
    const currentOffset = this.paginatorForm.get('offset')?.value;
  
    if (currentLimit !== 10 || currentOffset !== 0) {
      this.paginatorForm.patchValue({
        limit: 10,
        offset: 0
      }, { emitEvent: false });
    }
  }

  fetchCompilationDetailsList(compilationDetailsFormData?: any): void {
    this.isLoading = true;
    this.costsService.getCompilationDetails(this.compilationDetailsId || '', this.paginatorForm.value as Partial<Paginator>, compilationDetailsFormData).subscribe({
      next: (compilationDetails : CompilationDetails) => {
        this.compilationDetailsList = compilationDetails.ordersSettlementsData;
        this.totalElementsCount = compilationDetails.ordersCount;
        this.totalSummaryData = {
          gross: compilationDetails.totalGross,
          net: compilationDetails.totalNet
        }
        this.compilationDetailsForm.patchValue({
          invoiceNumber: compilationDetails.invoiceNumber,
          dataWystawienia: ''
        })
      },
      error: (error) => {
        console.error(error);
      },
      complete: () => {
        this.isLoading = false;
      }
    });
  }

  onNextPage(event: Event): void {
    event.preventDefault();
    const offset = this.paginatorForm.value.offset || 0;
    const limit = this.paginatorForm.value.limit || 10;
    this.paginatorForm.get('offset')?.patchValue(offset + limit);
    this.fetchCompilationDetailsList();
  }
  
  onPreviousPage(event: Event): void {
    event.preventDefault();
    const offset = this.paginatorForm.value.offset || 0;
    const limit = this.paginatorForm.value.limit || 10;
    this.paginatorForm.get('offset')?.patchValue(Math.max(0, offset - limit));
    this.fetchCompilationDetailsList();
  }

  onCompilationDetailsDelete() : void {
    const dialogRef = this.dialog.open(ConfirmationModalComponent, {
      disableClose: false,
      width: '420px',
      data: {
        title: 'Usuwanie zestawienia',
        description: `Czy napewno chcesz usunąć zestawienie?`,
      },
    });
    dialogRef.afterClosed().subscribe((actionResult: 'cancel' | 'success') => {
      if(actionResult === 'success' && this.compilationDetailsId) {
        this.costsService.deleteCompilation(this.compilationDetailsId).subscribe({
          next: () => {
            this.router.navigate(['/costs/compilations']);
          },
          error: (error) => {
            console.error(error);
          }
        })
      }
    });
  }

  public onCompilationDetailsEdit() : void {
    const dialogRef = this.dialog.open(ReuploadTallyModalComponent, {
      disableClose: false,
      width: '620px',
      data: {
        routeId: this.compilationDetailsId,
      },
    });
    dialogRef.afterClosed().subscribe((actionResult: 'cancel' | 'success') => {
      if(actionResult === 'success') {
        this.fetchCompilationDetailsList();
      }
    });
  }

  ngOnDestroy(): void {
    if (this.routerSubscription) {
      this.routerSubscription.unsubscribe();
    }
  }
}
