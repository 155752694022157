<div class="order-info">
  <mat-card>
    <div class="order-info-panel">
      <div>
        <p class="gray">
          {{ 'orders-front.info.adoption_date' | translate }}
        </p>
        <p>
          {{ order?.createdAt | date: 'YYYY-MM-dd | HH:mm' | dashIfEmpty }}
        </p>
      </div>
      <div>
        <p class="gray">
          {{ 'orders-front.info.state' | translate }}
        </p>
        <p>
          {{ order?.state | dashIfEmpty | translate }}
        </p>
      </div>
      <div>
        <p class="gray">
          {{ 'orders-front.info.margin' | translate }}
        </p>
        <p
          class="like-link"
          (click)="openMargin()"
          *ngIf="order?.workshop?.name"
          (disabled)="
            !Auth.isViewAllowed(['ROLE_BILLINGS_MARGIN_VIEW', 'ROLE_ADMIN'])
          ">
          Domyślna
        </p>
        <p *ngIf="!order?.workshop?.name">-</p>
      </div>
    </div>
  </mat-card>
  <mat-card>
    <div class="order-info-panel">
      <div>
        <p class="gray">
          {{ 'orders-front.info.service' | translate }}
        </p>
        <p
          *ngIf="order?.workshop?.name"
          class="like-link wrap"
          [routerLink]="'/workshops/workshop/' + this.order?.workshopId">
          {{ order?.workshop?.name }}
        </p>
        <p *ngIf="!order?.workshop?.name">-</p>
      </div>
      <div>
        <p class="gray">
          {{ 'orders-front.info.begin_service_in_workshop' | translate }}
        </p>
        <p>
          {{
            order?.dates?.deliveryDate
              | date: 'YYYY-MM-dd | HH:mm'
              | dashIfEmpty
          }}
        </p>
      </div>
      <div>
        <p *ngIf="order?.preauthorization" class="gray">
          {{ 'orders-front.info.pre_auth' | translate }}
          <mat-icon
            class="preauth-icon accepted"
            *ngIf="order?.preauthorization?.decision == 'AKCEPTACJA'"
            >check_circle_outline</mat-icon
          >
          <mat-icon
            class="preauth-icon neutral"
            *ngIf="order?.preauthorization?.decision == 'SZKODA CAŁKOWITA'"
            >restore_from_trash</mat-icon
          >
          <mat-icon
            class="preauth-icon error"
            *ngIf="order?.preauthorization?.decision == 'ODMOWA'"
            >error_outline</mat-icon
          >
        </p>
        <p *ngIf="!order?.preauthorization" class="gray">
          {{ 'orders-front.info.pre_auth' | translate }}
        </p>
        <p
          [class]="{ 'like-link': order?.preauthorization?.decision }"
          (click)="order?.preauthorization?.decision && openPreAuth()">
          {{ order?.preauthorization?.decision | translate }}
        </p>
        <p
          [class]="{ 'like-link': order?.preauthorization }"
          (click)="order?.preauthorization && openPreAuth()"
          *ngIf="
            order?.preauthorization &&
            !order?.preauthorization?.decision &&
            order?.preauthorization?.requestedAt
          ">
          {{ 'waiting_for_authorization' | dashIfEmpty | translate }}
        </p>
        <p
          *ngIf="
            !order?.preauthorization ||
            (order?.preauthorization &&
              !order?.preauthorization?.decision &&
              !order?.preauthorization?.requestedAt)
          ">
          -
        </p>
      </div>
    </div>
  </mat-card>
  <mat-card>
    <div class="order-info-panel">
      <div>
        <p class="gray">
          {{ 'orders-front.info.order_date_repairs' | translate }}
        </p>
        <p>
          {{
            order?.dates?.repairEndDate
              | date: 'YYYY-MM-dd | HH:mm:ss'
              | dashIfEmpty
          }}
        </p>
      </div>
      <div>
        <p class="gray">
          {{ 'orders-front.info.closing_date' | translate }}
        </p>
        <p>
          {{
            order?.dates?.finishDate
              | date: 'YYYY-MM-dd | HH:mm:ss'
              | dashIfEmpty
          }}
        </p>
      </div>
      <div>
        <p class="gray">
          {{ 'orders-front.info.settlement_date' | translate }}
        </p>
        <p>
          {{
            order?.dates?.closedAt | date: 'YYYY-MM-dd | HH:mm:ss' | dashIfEmpty
          }}
        </p>
      </div>
    </div>
  </mat-card>
</div>
