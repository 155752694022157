import { Component, OnInit } from '@angular/core';
import {
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from '@angular/forms';
import { UserService } from '../../services/user.service';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { ServicesService } from '../../../pages/services/services/services.service';
import { UserConfirmActionComponent } from '../user-confirm-action/user-confirm-action.component';
import { OrdersService } from 'src/app/pages/orders/services/orders.service';
import { faXmark } from '@fortawesome/free-solid-svg-icons';
import { faBell } from '@fortawesome/free-regular-svg-icons';
import { Platform, PlatformsResponse } from 'dashboard-libs/models/platformsResponse.model';

@Component({
  selector: 'app-add-user',
  templateUrl: './add-user.component.html',
  styleUrls: ['./add-user.component.scss'],
})
export class AddUserComponent{

  faXmark = faXmark;

  faBell = faBell;

  public isLoading: boolean = false;
  public showError: boolean = false;
  public showGeneralError: boolean = false;
  public form!: FormGroup;
  public submitted: boolean = false;
  public services: any;
  public repairUnits: any;
  public repairUnit: any;
  public defaultRepairUnit: any;
  repairUnitsArray: any = [];
  public platforms: Platform[] = [];

  public userGroups: any;

  constructor(
    private dialogRef: MatDialogRef<AddUserComponent>,
    private dialog: MatDialog,
    private fb: FormBuilder,
    private servicesService: ServicesService,
    private userService: UserService,
    private orderService: OrdersService
  ) {
    this.form = this.fb.group({
      email: ['', [Validators.required, Validators.email]],
      firstName: ['', [Validators.required, Validators.minLength(2)]],
      lastName: ['', [Validators.required, Validators.minLength(2)]],
      workshop: ['', Validators.required],
      groups: ['', Validators.required],
      repairUnit: [[]],
      enabled: [false],
      platforms: [[], Validators.required]
    });
    this.userService.getUsersGroups().subscribe((res: any) => {
      this.userGroups = res['hydra:member'];
    });
    this.servicesService.getServices().subscribe((res: any) => {
      this.services = res['hydra:member'];
    });
    this.servicesService.getPlatformsAsAdmin().subscribe({
      next: (platformsData: PlatformsResponse) => {
       this.platforms = platformsData['hydra:member'];
      }
    });
    this.form?.get('workshop')?.valueChanges.subscribe(value => {
      this.refreshRepairUnits(value);
      console.log(this.form.controls['workshop'].errors?.['required']);
    });
  }

  add(): void {
    this.submitted = true;
    if (this.form.invalid) {
      this.form.markAllAsTouched();
    } else {
      this.isLoading = true;
      this.userService
        .createUser({
          username: this.form.value.email,
          email: this.form.value.email,
          firstName: this.form.value.firstName,
          lastName: this.form.value.lastName,
          workshop: this.form.value.workshop,
          repairUnits: this.form.value.repairUnit
            ? this.form.value.repairUnit
            : [],
          groups: [
            this.userGroups.find(
              (group: any) => group.id === this.form.value.groups
            ),
          ],
          enabled: this.form.value.enabled,
          platforms: this.form.value.platforms
        })
        .subscribe(
          res => {
            this.dialogRef.close();
            this.isLoading = false;
            const dialogRef = this.dialog.open(UserConfirmActionComponent, {
              disableClose: true,
            });

            dialogRef.afterClosed().subscribe(result => {});
          },
          error => {
            this.isLoading = false;
            if (error.status === 409) {
              this.showError = true;
            } else {
              this.showGeneralError = true;
            }
          }
        );
    }
  }

  refreshRepairUnits(workshopId: string): void {
    if (workshopId !== '') {
      this.orderService
        .getRepairUnitsByWorkshop(workshopId)
        .subscribe((res: any) => {
          this.repairUnits = res.items['hydra:member'];
          this.defaultRepairUnit = '';
          this.form.value.repairUnit = '';
        });
    }
  }
}
