<div class="shared-modal-container">
    <div class="shared-modal-header" mat-dialog-draggable-title>
        <h6>Edycja dokumentu rozliczeniowego</h6>
        <button class="shared-modal-close-btn" (click)="onModalClose()">
            <fa-icon [icon]="faXmark"/>
        </button>
    </div>
    <div class="shared-modal-body">
        @if (isLoading && !isFormSubmitted) {
            <div class="loading-spinner-container">
                <p>Zapisywanie zestawienia</p>
                <mat-spinner/>
            </div>
        }
        @else if(!isLoading && isFormSubmitted) {
            <div class="summary-content-container">
                <fa-icon class="checkmark-icon" [icon]="faCheck"/>
                <h6>Zestawienie zostało zaktualizowane</h6>
            </div>
        }
        @else{
            <div class="main-content-container">
                <div class="form-container">
                    <form [formGroup]="updateTallyFrom">
                        <div class="shared-form-inputs-container">
                            <mat-form-field class="mat-form-field-input" appearance="outline">
                                <mat-label>Numer faktury</mat-label>
                                <input matInput formControlName="invoiceNumber">
                            </mat-form-field>
                        </div>
        
                        <div class="shared-form-inputs-container">
                            <div class="upload-btn-wrapper">
                                <button class="add-attachment-btn">
                                    <p>{{uploadFileData.isUploaded ? uploadFileData.fileName : 'Dodaj załącznik (PDF)'}}</p>
                                    <fa-icon [class.uploaded]="uploadFileData.isUploaded" [icon]="uploadFileData.isUploaded ? faCheck : faArrowUpFromBracket"/>
                                </button>
                                <input 
                                    type="file" 
                                    (change)="uploadAttachment($event)" 
                                    accept="application/pdf"
                                />
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        }
    </div>
    <div class="shared-modal-footer">
        <div class="shared-modal-footer-buttons-container">
            @if(!isLoading && isFormSubmitted){
                <button mat-stroked-button mat-dialog-close="success">Zamknij</button>
            }
            @else{
                <button mat-stroked-button (click)="onModalClose()">
                    Anuluj
                </button>
                <button mat-raised-button (click)="onSave()" [class.disabled]="!(uploadFileData.isUploaded && updateTallyFrom.valid)">
                    <span style="color: #FFF">Zaktualizuj zestawienie</span>
                </button>
            }
        </div>
    </div>
</div>