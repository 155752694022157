<div class="sidenav" [class.partner]="applicationName === 'DRS Partner'">
  <div class="sidenav-logo-container">
    <img class="sidenav-logo" [src]="appLogo"/>
  </div>
  <div class="sidenav-routes-container">
    @for (appRoute of appRoutes; track $index) {
      @if(appRoute.type === 'link' && appRoute.canActivate) {
        <div class="item" [routerLink]="appRoute.route" [class.sectionEnd]="appRoute.sectionEnd" routerLinkActive="active">
          <div class="expand-item-content-container">
            <div class="icon" [matTooltip]="appRoute.title | translate">
              <mat-icon>{{appRoute.icon}}</mat-icon>
            </div>
            <div class="text">{{ appRoute.title | translate }}</div>
          </div>
        </div>
      }
  
      @if(appRoute.type === 'expand' && appRoute.canActivate && appRoute.childRoutes.length > 0) {
      <div class="item" (click)="toggleProperty(appRoute.expandProperty || '')">
        <div class="expand-item-content-container">
          <div class="icon" [matTooltip]="appRoute.title | translate">
            <mat-icon>{{ appRoute.icon }}</mat-icon>
          </div>
          <div class="text">{{ appRoute.title | translate}}</div>
        </div>
        <div class="arrow">
          <mat-icon>{{ expandedStates[appRoute.expandProperty || ''] ? 'keyboard_arrow_up' : 'keyboard_arrow_down' }}</mat-icon>
        </div>
      </div>
        @if(expandedStates[appRoute.expandProperty || '']) {
          @for (childRoute of appRoute.childRoutes; track $index) {
            @if(childRoute.canActivate){ 
              <div class="item child-route" [routerLink]="childRoute.route" routerLinkActive="active" [routerLinkActiveOptions]="{ exact: true }">
                <div class="expand-item-content-container">
                  <mat-icon [style.transform]="childRoute.icon == 'fiber_manual_record' && 'scale(0.6)'">{{childRoute.icon}}</mat-icon>
                  <div class="text">{{ childRoute.title | translate}}</div>
                </div>
              </div>
            }
          }
        }
      }
    }
  </div>

  <div class="user-details-footer">
    <div
      class="user-info"
      [matMenuTriggerFor]="menu"
      (mouseover)="createMenuTrigger.openMenu()"
      #createMenuTrigger="matMenuTrigger">
      <div class="icon">
        <mat-icon>person</mat-icon>
      </div>
      <div class="name">
        <p>{{ user?.name }} {{ user?.surname }}</p>
      </div>
    </div>
    <mat-menu
      class="user-menu"
      #menu="matMenu"
      yPosition="above"
      (mouseleave)="createMenuTrigger.closeMenu()">
      <div class="menu-item" [routerLink]="'/my-profile'">
        <mat-icon>person</mat-icon> {{ 'users-front.my_account' | translate }}
      </div>
      <div (click)="logout()" class="menu-item">
        <mat-icon>power_settings_new</mat-icon> {{ 'users-front.logout' | translate }}
      </div>
    </mat-menu>
  </div>
</div>