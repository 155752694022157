@if(showChildRoute){
  <router-outlet/>
}
@else{
  <ng-container>
    <div>
      <div class="workshop-filters-container">
        @if(Auth.isViewAllowed(['ROLE_WORKSHOP_SEARCH', 'ROLE_ADMIN'])){
          <div class="filters-inputs">
            <mat-form-field appearance="outline">
              <mat-label>{{ 'services-front.search' | translate }}</mat-label>
              <input 
                matInput 
                (keyup)="applyFilter($event)" 
                type="text" 
                #input />
              <mat-icon matSuffix>search</mat-icon>
            </mat-form-field> 
          </div>
        }
        @if(Auth.isViewAllowed(['ROLE_WORKSHOP_MANAGE', 'ROLE_ADMIN'])){
          <button mat-raised-button (click)="addService()" style="color: #FFF">
            {{ 'services-front.add_service' | translate }}
          </button>
        }
      </div>
  
      <div class="main-table-container">
        <table mat-table [dataSource]="dataSource">
          <ng-container matColumnDef="name">
            <th mat-header-cell *matHeaderCellDef>
              {{ 'services-front.table.name' | translate }}
            </th>
            <td mat-cell *matCellDef="let element">
              <ng-container *ngIf="element.name">
                {{ element.name }}</ng-container
              >
            </td>
          </ng-container>
  
          <ng-container matColumnDef="createdAt">
            <th mat-header-cell *matHeaderCellDef>
              {{ 'services-front.added' | translate }}
            </th>
            <td mat-cell *matCellDef="let element">
              <ng-container *ngIf="element.createdAt">
                {{ element.createdAt | date: 'YYYY.MM.dd HH:mm' }}</ng-container
              >
            </td>
          </ng-container>
  
          <ng-container matColumnDef="city">
            <th mat-header-cell *matHeaderCellDef>
              {{ 'services-front.table.city' | translate }}
            </th>
            <td mat-cell *matCellDef="let element">
              <ng-container *ngIf="element.address.city"
                >{{ element.address.city }}
              </ng-container>
            </td>
          </ng-container>
  
          <ng-container matColumnDef="owner">
            <th mat-header-cell *matHeaderCellDef>
              {{ 'services-front.table.owner' | translate }}
            </th>
            <td mat-cell *matCellDef="let element">
              <ng-container
                *ngIf="
                  element.contacts[0] &&
                  element.contacts[0].contactDetails &&
                  element.contacts[0].contactDetails.name
                ">
                {{ element.contacts[0].contactDetails.name }}
                {{ element.contacts[0].contactDetails.surname }}
              </ng-container>
            </td>
          </ng-container>
  
          <ng-container matColumnDef="mobile">
            <th mat-header-cell *matHeaderCellDef>
              {{ 'services-front.table.mobile' | translate }}
            </th>
            <td mat-cell *matCellDef="let element">
              <ng-container
                *ngIf="
                  element.contacts[0] &&
                  element.contacts[0].contactDetails &&
                  element.contacts[0].contactDetails.mobileNumber
                ">
                {{ element.contacts[0].contactDetails.mobileNumber }}
              </ng-container>
            </td>
          </ng-container>
  
          <ng-container matColumnDef="email">
            <th mat-header-cell *matHeaderCellDef>
              {{ 'services-front.table.email' | translate }}
            </th>
            <td mat-cell *matCellDef="let element">
              <ng-container
                *ngIf="
                  element.contacts[0] &&
                  element.contacts[0].contactDetails &&
                  element.contacts[0].contactDetails.email
                ">
                {{ element.contacts[0].contactDetails.email }}
              </ng-container>
            </td>
          </ng-container>
  
          <ng-container matColumnDef="nip">
            <th mat-header-cell *matHeaderCellDef>
              {{ 'services-front.table.nip' | translate }}
            </th>
            <td mat-cell *matCellDef="let element">
              <ng-container *ngIf="element.taxNumber">
                {{ element.taxNumber }}
              </ng-container>
            </td>
          </ng-container>
  
          <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
          <tr
            class="hovered"
            (click)="goToDetails(row?.id)"
            mat-row
            *matRowDef="let row; columns: displayedColumns"></tr>
        </table>
        <div class="empty-table-view" *ngIf="dataSource?.data?.length === 0">
          <p>{{ 'no_data' | translate }}</p>
        </div>
        <mat-paginator
          [pageSizeOptions]="[10, 20, 30, 50]"
          showFirstLastButtons
          aria-label="Select page of periodic elements">
        </mat-paginator>
      </div>
    </div>
  </ng-container>  
}