<div class="shared-modal-container">
  <div class="shared-modal-header" mat-dialog-draggable-title>
      <h6>Edycja części</h6>
      <button class="shared-modal-close-btn" mat-dialog-close="cancel">
          <fa-icon [icon]="faXmark"/>
      </button>
  </div>
  <div class="shared-modal-body">
    <form [formGroup]="partsForm" (ngSubmit)="onSubmit()">
      <div formArrayName="parts">
        <div class="parts-row-container filters-inputs" *ngFor="let part of parts.controls; let i = index" [formGroupName]="i">
          <mat-form-field appearance="outline">
            <mat-label>Numer części</mat-label>
            <input matInput formControlName="partNumber" type="text" />
          </mat-form-field>
    
          <mat-form-field appearance="outline">
            <mat-label>Nazwa części</mat-label>
            <input matInput formControlName="name" type="text" />
          </mat-form-field>
    
          <mat-form-field appearance="outline">
            <mat-label>Ilość</mat-label>
            <input matInput formControlName="quantity" type="number" />
          </mat-form-field>
    
          <mat-form-field appearance="outline">
            <mat-label>Koszt</mat-label>
            <input matInput formControlName="price" type="number" />
          </mat-form-field>
    
          <button class="remove-item-btn" type="button" mat-icon-button color="warn" (click)="removePart(i)">
            <mat-icon>delete_outline</mat-icon>
          </button>
        </div>
      </div>
    
      <button type="button" mat-button color="primary" (click)="addPart()">
        <mat-icon>add</mat-icon> Dodaj kolejną część
      </button>
    </form>    
    <section class="example-section">
      <mat-checkbox [(ngModel)]="synchro" class="example-margin"
        >Włącz synchronizacje</mat-checkbox
      >
    </section>    
  </div>
  <div class="shared-modal-footer">
      <div class="shared-modal-footer-buttons-container">
        <button
          mat-stroked-button
          color="primary"
          mat-dialog-close="cancel"
        >
          {{ 'orders-front.cancel' | translate }}
        </button>
        <button mat-raised-button (click)="onSubmit()" color="primary">
          <span style="color:#FFF">Zapisz</span>
        </button>
      </div>
  </div>
</div>
<!-- 
<h1 mat-dialog-title class="title">Edycja części</h1>
<hr class="custom-hr" />
<div mat-dialog-content>
  <form [formGroup]="partsForm" (ngSubmit)="onSubmit()">
    <div formArrayName="parts">
      <div
        class="types"
        *ngFor="let part of parts.controls; let i = index"
        [formGroupName]="i">
        <div class="did-floating-label-content">
          <input
            class="did-floating-input"
            formControlName="partNumber"
            type="text"
            placeholder=" " />
          <label class="did-floating-label">Numer części</label>
        </div>
        <div class="did-floating-label-content">
          <input
            class="did-floating-input"
            formControlName="name"
            type="text"
            placeholder=" " />
          <label class="did-floating-label">Nazwa częsci</label>
        </div>

        <div class="did-floating-label-content">
          <input
            class="did-floating-input"
            formControlName="quantity"
            type="number"
            placeholder=" " />
          <label class="did-floating-label">Ilość</label>
        </div>
        <div class="did-floating-label-content">
          <input
            class="did-floating-input"
            formControlName="price"
            type="number"
            placeholder=" " />
          <label class="did-floating-label">Koszt</label>
        </div>
        <div class="did-floating-label-content">
          <button type="button" mat-button (click)="removePart(i)">Usuń</button>
        </div>
      </div>
    </div>

    <button type="button" mat-button (click)="addPart()">
      Dodaj kolejną część
    </button>
  </form>
</div>
<section class="example-section">
  <mat-checkbox [(ngModel)]="synchro" class="example-margin"
    >Włącz synchronizacje</mat-checkbox
  >
</section>

<mat-error *ngIf="error" class="error"> {{ error }}</mat-error>
<div mat-dialog-actions>
  <button
    mat-button
    class="secondary-btn"
    color="accent"
    [mat-dialog-close]="'cancel'">
    {{ 'orders-front.cancel' | translate }}
  </button>
  <button mat-button class="primary-btn" (click)="onSubmit()" color="primary">
    Zapisz
  </button>
</div> -->
