import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { faXmark } from '@fortawesome/free-solid-svg-icons';

export interface ModalInputData {
  title: string;
  description: string;
  list?: string[] | number[];
  singleAction?: boolean;
  cancelButton?: string;
  confirmButton?: string;
}

@Component({
  selector: 'app-confirmation-modal',
  standalone: false,
  templateUrl: './confirmation-modal.component.html',
  styleUrl: './confirmation-modal.component.scss',
})
export class ConfirmationModalComponent implements OnInit{

  public title!: string;

  public description!: string;

  public list: string[] | number[];

  public singleAction: boolean = false;

  public confirmButton: string;

  public cancelButton: string;

  faXmark = faXmark;

  constructor(
    public dialogRef: MatDialogRef<ConfirmationModalComponent>,
    @Inject(MAT_DIALOG_DATA) public data: ModalInputData
  ) { }

  ngOnInit(): void {
    this.title = this.data.title;
    this.description = this.data.description;
    if(this.data?.list){
      this.list = this.data.list;
    }
    if(this.data?.singleAction){
      this.singleAction = this.data.singleAction;
    }
    if(this.data?.confirmButton){
      this.confirmButton = this.data.confirmButton;
    }
    if(this.data?.cancelButton){
      this.cancelButton = this.data.cancelButton;
    }
  }

  onModalClose() : void {
    this.dialogRef.close();
  }
}
