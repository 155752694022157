import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { Compilation } from 'dashboard-libs/models/compilation.model';
import { filter, Subscription } from 'rxjs';
import { flattenFormValues } from 'src/app/shared/helpers/helpers';
import { CostsService, Paginator } from 'src/app/shared/services/costs.service';

@Component({
  selector: 'app-compilations',
  standalone: false,
  templateUrl: './compilations.component.html',
  styleUrl: './compilations.component.scss',
})

export class CompilationsComponent implements OnInit, OnDestroy{

  private routerSubscription!: Subscription;

  public isChildRoute: boolean = false;

  public readonly tableHeaders = [
    "Numer zestawienia",
    "Numer faktury",
    "Data utworzenia",
    "Status",
    "Data akceptacji",
    "Koszt brutto",
  ];

  public totalElementsCount : number = 0;

  public checkedItemsCount: number = 0;

  public checkedItemsTotalNet : number = 0;

  public checkedItemsTotalGross : number = 0;

  public compilations : Compilation[] = [];

  public isLoading: boolean = true;

  public paginatorForm = this.formBuilder.group({
    limit: [10],
    offset: [0],
  })

  public compilationsForm = this.formBuilder.group({
    faultNumber: ['', []],
    invoiceListNumber: ['',[]],
    createdAtRange: this.formBuilder.group({
      createdAtDateFrom: ['', []],
      createdAtDateTo: ['', []],
    }),
  });

  constructor(
    private formBuilder: FormBuilder,
    private costsService: CostsService,
    private route: ActivatedRoute,
    private router: Router,
  ) { }

  ngOnInit(): void {
    this.isChildRoute = this.router.url.includes('/compilations/compilation/');
    console.log(this.router.url);
    
    this.fetchCompilations();
  }

  onSubmit() : void {
    if (this.compilationsForm.valid) {
      this.resetPaginatorForm();
      const formData = flattenFormValues(this.compilationsForm);
      this.fetchCompilations(formData);
    }
  }

  resetPaginatorForm(): void {
    const currentLimit = this.paginatorForm.get('limit')?.value;
    const currentOffset = this.paginatorForm.get('offset')?.value;
  
    if (currentLimit !== 10 || currentOffset !== 0) {
      this.paginatorForm.patchValue({
        limit: 10,
        offset: 0
      }, { emitEvent: false });
    }
  }

  onClearFilters() : void {
    this.compilationsForm.reset();
  }

  clearFaultNumber() : void{
    this.compilationsForm.get('faultNumber')?.setValue('');
  }

  clearinvoiceListNumber() : void{
    this.compilationsForm.get('invoiceListNumber')?.setValue('');
  }

  fetchCompilations(compilationsFormData?: any): void {
    this.isLoading = true;
    this.costsService.getCompilations(this.paginatorForm.value as Paginator, compilationsFormData).subscribe({
      next: (compilations) => {
        console.log(compilations);
        this.totalElementsCount = compilations.totalItems;
        this.compilations = compilations.items['hydra:member'];
      },
      error: (error) => {
        console.error(error);
      },
      complete: () => {
        this.isLoading = false;
      }
    });
  }

  onNextPage(event: Event): void {
    event.preventDefault();
    const offset = this.paginatorForm.value.offset;
    const limit = this.paginatorForm.value.limit;
    this.paginatorForm.get('offset')?.patchValue((offset as number) + (limit as number));
    this.fetchCompilations();
  }
  
  onPreviousPage(event: Event): void {
    event.preventDefault();
    const offset = this.paginatorForm.value.offset;
    const limit = this.paginatorForm.value.limit;
    this.paginatorForm.get('offset')?.patchValue(Math.max(0, (offset as number) - (limit as number)));
    this.fetchCompilations();
  }

  ngOnDestroy(): void {
    if (this.routerSubscription) {
      this.routerSubscription.unsubscribe();
    }
  }
}