<div class="navbar-component">
  <div class="breadcrumb-container">
    <app-breadcrumb/>
  </div>
  <div class="notofications-container">
    
  </div>
</div>

<div class="user-info">
  <div class="notification">
    <div class="notification-icons-container">
      @if(checkEngine) {
        <mat-icon
        [matMenuTriggerFor]="errors"
        svgIcon="check-engine"
        class="check-engine"></mat-icon>
      <mat-menu #errors="matMenu" class="menu-errors">
        <p class="errors">
          {{ 'lastRequestDate' | translate }} :
          {{
            checkEngineError?.lastRequestDate
              | date: 'YYYY-MM-dd | HH:mm:ss'
              | dashIfEmpty
          }}
        </p>
        <p
          class="errors"
          [ngClass]="{
            'red': checkEngineError?.lastRequestResponseCode > 299,
            'green': checkEngineError?.lastRequestResponseCode < 300,
          }">
          {{ 'lastRequestResponseCode' | translate }} :
          {{ checkEngineError?.lastRequestResponseCode | dashIfEmpty }}
        </p>
        <p class="errors">
          {{ 'lastRequestUri' | translate }} :
          {{ checkEngineError?.lastRequestUri | dashIfEmpty }}
        </p>
      </mat-menu>
      }
      @else{
        <div></div>
      }
      <div
          [matBadge]="notificationsCount"
          matBadgeColor="accent"
          [matBadgeHidden]="!notificationsCount || notificationsCount === 0"
          matBadgeOverlap="false"
          class="mat-icon"
        >
          <mat-icon
            [class.iconActive]="isOrdersMenuOpen"
            (click)="toggleOrdersMenuOpen()"
            (menuOpened)="menuNotificationsOpened()"
            (menuClosed)="menuNotificationsClosed()"
            >list_alt
          </mat-icon>
          <app-notifications-window 
            title="Nowe zlecenia"
            [isOpen]="isOrdersMenuOpen"
            (onClickOutside)="toggleOrdersMenuOpen()"
          >
            <app-notifications 
              (click)="$event.stopPropagation()"
              (dismissOpenModal)="toggleOrdersMenuOpen()"
            />
          </app-notifications-window>
      </div>
      <div
        [matBadge]="commentsCount"
        matBadgeColor="accent"
        matBadgeOverlap="false"
        class="mat-icon"
        [matBadgeHidden]="!commentsCount || commentsCount === 0"
      >
        <mat-icon
          [class.iconActive]="isCommentsMenuOpen"
          (menuOpened)="menuCommentsOpened()"
          (click)="toggleCommentsMenuOpen()"
          >chat_bubble_outline</mat-icon
        >
          <app-notifications-window 
            title="Komentarze"
            [isOpen]="isCommentsMenuOpen"
            (onClickOutside)="toggleCommentsMenuOpen()"
          >
            <div header class="sub-header">
              <p>
                <span [class.active]="commentsFilterCategory === 'UNREAD'" (click)="changeCommentsFilterCategory('UNREAD')">Nowe</span>
                <span class="divider"> | </span>
                <span [class.active]="commentsFilterCategory === 'ALL'" (click)="changeCommentsFilterCategory('ALL')">Wszystkie</span>
              </p>
            </div>
            <app-comments
              (click)="$event.stopPropagation()"
              (dismissOpenModal)="toggleCommentsMenuOpen()"
            />
          </app-notifications-window>
      </div>
      <div
        [matBadge]="preAuthCount"
        matBadgeColor="accent"
        matBadgeOverlap="false"
        class="mat-icon"
        [matBadgeHidden]="!preAuthCount || preAuthCount === 0">
        <mat-icon
          [class.iconActive]="isNotificationsMenuOpen"
          (click)="toggleNotificationsMenuOpen()"
          (menuOpened)="menuPreAuthOpened()"
          (menuClosed)="menuPreAuthClosed()"
        >
          notifications_none
        </mat-icon>
        <app-notifications-window 
          title="Nowe autoryzacje"
          [isOpen]="isNotificationsMenuOpen"
          (onClickOutside)="toggleNotificationsMenuOpen()"
        >
          <app-preAuth
            (click)="$event.stopPropagation()"
            (dismissOpenModal)="toggleNotificationsMenuOpen()"
          />
        </app-notifications-window>
      </div>
    </div>
  </div>
  
  
  
  <div class="input-container-wrapper">
    <div class="input-container">
      <input 
        type="text"
        #toggleButton
        class="search-input"
        (keyup)="filterSearch($event)"
        (click)="setListVisibility(true)"
        [placeholder]="('search' | translate) + '...'"
        [class.menuOpen]="isListVisible"
      >
    </div>
    <div class="search-menu" #menu>
      @if(isListVisible && searchValue.searchText.length >= 3){
        <div class="list-container">
          <ul>
            @for (filteredItem of filteredItems; track i; let i = $index) {
              <li
                #listItemRef
                (click)="goToDetails(filteredItem.id)">
                {{ filteredItem.faultNumber }} | {{ filteredItem.state | translate }}
              </li>
            }
            @if(filteredItems.length == 0 && searchValue.searchText.length >= 3){
              <li #listItemRef>Brak danych</li>
            }
          </ul>
        </div>
      }
    </div>
  </div>

  <mat-select
    class="language"
    [(value)]="language"
    placeholder="Mode"
    (selectionChange)="setLang(language)">
    <mat-select-trigger>{{ language | uppercase }}</mat-select-trigger>
    <mat-option value="pl">PL</mat-option>
    <mat-option value="en">EN</mat-option>
    <mat-option value="fr">FR</mat-option>
  </mat-select>
</div>
