import { AddServiceInfoComponent } from './../../shared/modals/add-service/add-service-info/add-service-info.component';
import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import { MatPaginator } from '@angular/material/paginator';
import { ServicesService } from './services/services.service';
import { Router } from '@angular/router';
import { MatDialog } from '@angular/material/dialog';
import { AuthGuard } from 'src/app/shared/guards/access-allowed.guard';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-services',
  templateUrl: './services.component.html',
  styleUrls: ['./services.component.scss'],
})
export class ServicesComponent implements OnInit, OnDestroy {
  
  private routerSubscription!: Subscription;

  public showChildRoute = false;
  
  isFirst = true;

  displayedColumns: string[] = [
    'name',
    'city',
    'owner',
    'mobile',
    'email',
    'nip',
    'createdAt',
  ];
  dataSource: any;

  @ViewChild(MatPaginator) paginator!: MatPaginator;

  constructor(
    private router: Router,
    private dialog: MatDialog,
    private servicesService: ServicesService,
    public Auth: AuthGuard
  ) {}

  ngOnInit(): void {
    this.routerSubscription = this.router.events.subscribe(() => {
      const currentUrl = this.router.url;
      this.showChildRoute = currentUrl.includes('/workshops/workshop/');
    });

    // getting data from API
    this.servicesService.getServices().subscribe((res: any) => {
      // setting data source
      this.dataSource = new MatTableDataSource(res['hydra:member']);
      // fetching contacts for each service
      this.dataSource.filteredData.forEach(
        (
          element: { contacts: string[] },
          filteredDataIndex: string | number
        ) => {
          // for each contact - fetch data from API
          element.contacts.forEach((contact, contactsIndex) => {
            this.servicesService
              .getServiceContactByUrl(contact)
              .subscribe((res: any) => {
                // set contact data instead of URL
                this.dataSource.filteredData[filteredDataIndex].contacts[
                  contactsIndex
                ] = res;
              });
          });
        }
      );
      // setting paginator
      this.dataSource.paginator = this.paginator;
    });
  }

  addService(): void {
    // Open the AddServiceNipComponent in a dialog box
    const dialogRef = this.dialog.open(AddServiceInfoComponent, {
      disableClose: true,
    });

    // Subscribe to the result of the dialog box
    dialogRef.afterClosed().subscribe(result => {
      // If the result is true, reload the page

      this.ngOnInit();
    });
  }

  // Set up filter for the table
  applyFilter(event: Event) {
    // Get the value of the input field
    const filterValue = (event.target as HTMLInputElement).value;
    // Use the value to filter the table
    this.dataSource.filter = filterValue.trim().toLowerCase();
  }

  goToDetails(id: string): void {
    this.router.navigate(['/workshops/workshop/' + id]);
  }

  ngOnDestroy(): void {
    if (this.routerSubscription) {
      this.routerSubscription.unsubscribe();
    }
  }
}
