import { Component, EventEmitter, Input, Output } from '@angular/core';
import { AssignOrdersToWorkshopModalComponent } from '../assign-orders-to-workshop-modal/assign-orders-to-workshop-modal.component';
import { MatDialog } from '@angular/material/dialog';

@Component({
  selector: 'app-assign-orders-to-workshop',
  templateUrl: './assign-orders-to-workshop.component.html',
  styleUrl: './assign-orders-to-workshop.component.scss',
})
export class AssignOrdersToWorkshopComponent {
  
  @Input({ required: true }) selectedOrdersToAssignList: string[] = [];

  @Output() refetchOrders = new EventEmitter<void>();

  constructor(
    private dialog: MatDialog,
  ) {}

  public onAssignOrdersToWorkshopModalOpen() : void {
    const dialogRef = this.dialog.open(AssignOrdersToWorkshopModalComponent, {
      disableClose: false,
      width: '620px',
      data: {
        selectedOrdersToAssignList: this.selectedOrdersToAssignList,
      },
    });
    dialogRef.afterClosed().subscribe((actionResult: 'cancel' | 'success') => {
      if(actionResult === 'success') {
        this.refetchOrders.emit();
      }
    });
  }
}
