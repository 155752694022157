<div class="order-info-overview-container">
    <div class="order-info-overview-item">
        <ul>
            @for (item of generalOrderData; track $index) {
                @if(item.type === 'state'){
                    <li><p>{{item.name}}</p> <p [matTooltip]="item.name" matTooltipPosition="right">{{item.value | dashIfEmpty | translate}}</p></li>
                }
                @else if(item.type === 'date'){
                    <li><p>{{item.name}}</p> <p [matTooltip]="item.name" matTooltipPosition="right">{{item.value | date: 'YYYY-MM-dd | HH:mm' | dashIfEmpty}}</p></li>
                }
                @else if(item.type === 'action'){
                    <li><p>{{item.name}}</p> <p [matTooltip]="item.name" matTooltipPosition="right" matTooltipClass="full-width-tooltip">{{item.value | dashIfEmpty}}</p></li>
                }
                @else{
                    <li><p>{{item.name}}</p> <p [matTooltip]="item.name" matTooltipPosition="right">{{item.value | dashIfEmpty}}</p></li>
                }
            }
        </ul>
    </div>
    <div class="order-info-overview-item">
        <ul>
            @for (item of orderOverviewData; track $index) {
                @if(item.type === "text-copy" || item.type === "text"){
                    <li><p>{{item.name}}
                        @if(item.type === "text-copy"){
                            <button 
                                class="copy-btn"
                                matTooltip="Skopiuj do schowka"
                                (click)="copyToClipboard(item.value || '')"
                            >
                                <mat-icon class="copy-btn-icon">file_copy</mat-icon>
                            </button>
                        }
                    </p> <p [matTooltip]="item.name || ''" matTooltipPosition="right">{{item.value}}</p></li>
                }
                @else if (item.type === "date") {
                    <li><p>{{item.name}}</p> <p [matTooltip]="item.name || ''" matTooltipPosition="right">{{item.value | date: 'YYYY-MM-dd | HH:mm' | dashIfEmpty}}</p></li>
                }
                @else{
                    <div class="progress-bar-wrapper">
                        @if(item.purchaseDate && item.insurancePeriod){
                            <div class="progress-bar-wrapper">
                                <app-progress-bar 
                                    [purchaseDate]="item.purchaseDate || null"
                                    [insurancePeriod]="item.insurancePeriod || ''"
                                />
                            </div>
                        }
                    </div>
                }
            }
        </ul>
    </div>
    <div class="order-info-overview-item">
        <ul>
            @for (item of orderSummaryData; track $index) {
                @if(item.type === 'cost'){
                    <li><p [style.fontWeight]="item.color && 600" [style.color]="item.color && item.color">{{item.name}}</p> <p [style.color]="item.color && item.color" [matTooltip]="item.name" matTooltipPosition="right">{{item.value | dashIfEmpty}} PLN</p></li>
                }
                @else if(item.type === 'date'){
                    <li><p>{{item.name}}</p> <p [matTooltip]="item.name" matTooltipPosition="right">{{item.value | date: 'YYYY-MM-dd' | dashIfEmpty}}</p></li>
                }
                @else if(item.type === 'percent'){
                    <li><p [style.fontWeight]="item.color && 600" [style.color]="item.color && item.color">{{item.name}}</p> <p [style.color]="item.color && item.color" [matTooltip]="item.name" matTooltipPosition="right" matTooltipClass="full-width-tooltip">{{((+item.value) / 100) | percent | dashIfEmpty}}</p></li>
                }
                @else{
                    <li><p [style.fontWeight]="item.color && 600" [style.color]="item.color && item.color">{{item.name}}</p> <p [style.color]="item.color && item.color" [matTooltip]="item.name" matTooltipPosition="right">{{item.value | dashIfEmpty}}</p></li>
                }
            }
        </ul>
    </div>
</div>