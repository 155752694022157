import { Component, inject, Input } from '@angular/core';
import { Observable } from 'rxjs';
import { BreadcrumbService } from '../../../services/breadcrumb.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ActivatedRoute } from '@angular/router';

export interface Breadcrumb {
  label: string;
  title: string;
  url: string;
  disabled?: any;
}

@Component({
  selector: 'app-breadcrumb',
  templateUrl: './breadcrumb.component.html',
  styleUrls: ['./breadcrumb.component.scss'],
  standalone: false
})
export class BreadcrumbComponent {
  private _snackBar = inject(MatSnackBar);

  public breadcrumbDetails = "";

  breadcrumbs$: Observable<Breadcrumb[]>;

  route: string;

  constructor(private breadcrumbService: BreadcrumbService, private activatedRoute: ActivatedRoute) {
    this.breadcrumbs$ = this.breadcrumbService.breadcrumbs$;    
    this.route = this.activatedRoute.snapshot.routeConfig?.path || '';

    this.breadcrumbService.breadCrumbDetails$.subscribe({
      next: (breadcrumbDetails) => {
        this.breadcrumbDetails = breadcrumbDetails;
      }
    })

    setTimeout(() => {
      console.log(this.breadcrumbDetails)
    }, 1000);
  }

  copyToClipboard(textValue: string) : void {
    navigator.clipboard.writeText(textValue).then(() => {
      this._snackBar.open('Skopiowano do schowka',"Zamknij", {
        horizontalPosition: 'center',
        verticalPosition: 'top',
        duration: 1000
      })
    }).catch(error => {
      console.error(error);
    });
  }
}
