<div class="shared-modal-container">
    <div class="shared-modal-header" mat-dialog-draggable-title>
        <h6>Potwierdź zlecenie</h6>
        <button class="shared-modal-close-btn" mat-dialog-close="cancel">
            <fa-icon [icon]="faXmark"/>
        </button>
    </div>
    <div class="shared-modal-body">
        <form [formGroup]="submitOrderModalForm" class="filters-inputs">
            <mat-form-field class="example-full-width" appearance="outline">
                <mat-label>Numer referencyjny zlecenia</mat-label>
                <input matInput formControlName="refNumber">
              </mat-form-field>            
        </form>
    </div>
    <div class="shared-modal-footer">
        <div class="shared-modal-footer-buttons-container">
            <button mat-stroked-button mat-dialog-close="cancel">
                Anuluj
            </button>
            <button mat-raised-button mat-dialog-close="success">
                <span style="color: #FFF">Potwierdź</span>
            </button>
        </div>
    </div>
</div>