import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { faArrowUpFromBracket, faCheck, faXmark } from '@fortawesome/free-solid-svg-icons';
import { AddAttachmentComponent } from 'src/app/shared/modals/add-attachment/add-attachment.component';
import { CostsService } from 'src/app/shared/services/costs.service';

export interface resData {
  message: string;
  invoiceListNumber: string;
  invoiceListId: string;
}

export interface UploadedFileData {
  fileName: string,
  binaryData: any,
  isUploaded: boolean
}

@Component({
  selector: 'app-reupload-tally-modal',
  standalone: false,
  templateUrl: './reupload-tally-modal.component.html',
  styleUrl: './reupload-tally-modal.component.scss',
})
export class ReuploadTallyModalComponent implements OnInit{

  public uploadFileData: UploadedFileData = {
    isUploaded: false,
    fileName: '',
    binaryData: ''
  };

  private routeId!: string;

  faArrowUpFromBracket = faArrowUpFromBracket;

  faXmark = faXmark;

  faCheck = faCheck;

  public updateTallyFrom = this.formBuilder.group({
    invoiceNumber: ['', [Validators.required]],
  })

  constructor(
    public dialogRef: MatDialogRef<ReuploadTallyModalComponent>,
    private formBuilder: FormBuilder,
    private costsService: CostsService,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) { }

  ngOnInit(): void {
    this.routeId = this.data.routeId;
  }

  onModalClose() : void {
    this.dialogRef.close();
  }

  uploadAttachment(event: Event): void {
    const input = event.target as HTMLInputElement;
    if (input.files && input.files.length > 0) {
      const file = input.files[0];
  
      const reader = new FileReader();
      reader.onload = (e) => {
        const binaryData = new Blob([reader.result as ArrayBuffer], { type: file.type });
  
        this.uploadFileData = {
          isUploaded: true,
          fileName: file.name,
          binaryData: binaryData
        };
      };
  
      reader.onerror = () => {
        this.uploadFileData.isUploaded = false;
        console.error('File reading failed.');
      };
  
      reader.readAsArrayBuffer(file);
    }
  }

  public isLoading: boolean = false;

  public isFormSubmitted: boolean = false;

  onSave(): void {
    this.isLoading = true;
    if (!this.uploadFileData || !this.uploadFileData.binaryData) {
      console.error('Brak danych pliku, nie można wysłać');
      return;
    }
    if(this.updateTallyFrom.valid && this.updateTallyFrom.get('invoiceNumber').value) {
      const formData = new FormData();
      formData.append('invoiceNumber', this.updateTallyFrom.get('invoiceNumber').value);
      formData.append('invoiceFile', this.uploadFileData.binaryData, this.uploadFileData.fileName);
    
      this.costsService.updateInvoiceForOrders(this.routeId, formData).subscribe({
        next: (resData: resData) => {
          this.isLoading = false;
          this.isFormSubmitted = true;
        },
        error: (error) => {
          console.error(error);
        }
      });
    }
  }
}
