import { RouterModule, Routes } from '@angular/router';
import { NgModule } from '@angular/core';
import { OrdersComponent } from './pages/orders/orders.component';
import { ServicesComponent } from './pages/services/services.component';
import { OrderItemComponent } from './pages/orders/order-item/order-item.component';
import { ServiceItemComponent } from './pages/services/service-item/service-item.component';
import { SettlementComponent } from './pages/settlement/settlement.component';
import { CostsComponent } from './pages/costs/costs.component';
import { SettingsComponent } from './pages/settings/settings.component';
import { UsersComponent } from './pages/users/users.component';
import { SettlementItemComponent } from './pages/settlement/settlement-item/settlement-item.component';
import { CostsItemComponent } from './pages/costs/costs-item/costs-item.component';
import { AuthGuard } from './shared/guards/access-allowed.guard';
import { ArchivedOrderItemComponent } from './pages/archived-orders/archived-order-item/archived-order-item.component';
import { ArchivedOrdersComponent } from './pages/archived-orders/archived-orders.component';
import { AdminPanelComponent } from './pages/admin-panel/admin-panel.component';
import { LogsComponent } from './pages/logs/logs.component';
import { LogsLocalComponent } from './pages/logs-local/logs-local.component';
import { MyProfileComponent } from './pages/my-profile/my-profile.component';
import { ReportsComponent } from './pages/reports/reports.component';
import { CompilationsComponent } from './pages/costs/compilations/compilations.component';
import { CompilationDetailsComponent } from './pages/costs/compilations/compilation-details/compilation-details.component';
import { RepairUnitDetailsComponent } from 'dashboard-libs/components/repair-unit-details/repair-unit-details.component';
import { SystemSettingsComponent } from './pages/system-settings/system-settings.component';

const routes: Routes = [
  { path: '', redirectTo: 'orders', pathMatch: 'full' },
  {
    path: 'orders',
    component: OrdersComponent,
    data: {
      title: 'Zlecenia'
    },
    children: [
      {
        path: 'order/:id',
        component: OrderItemComponent,
        data: { 
          roles: ['ROLE_ORDERS_VIEW', 'ROLE_ADMIN'], 
          title: 'Szczegóły zlecenia' 
        },
        canActivate: [AuthGuard],
      },
    ]
  },
  {
    path: 'my-profile',
    component: MyProfileComponent,
    data: {
      title: 'Mój profil'
    }
  },

  {
    path: 'archived-orders',
    component: ArchivedOrdersComponent,
    data: {
      title: 'Zarchiwizowane zlecenia'
    }
  },
  {
    path: 'archived-orders/:id',
    component: ArchivedOrderItemComponent,
    data: { roles: ['ROLE_ORDERS_VIEW', 'ROLE_ADMIN'], title: 'Zarchiwizowane zlecenie'},
    canActivate: [AuthGuard],
  },

  {
    path: 'workshops',
    component: ServicesComponent,
    data: { roles: ['ROLE_WORKSHOP_VIEW', 'ROLE_ADMIN'], title: 'Serwisy' },
    canActivate: [AuthGuard],
    children: [
      {
        path: 'workshop/:id',
        component: ServiceItemComponent,
        data: { roles: ['ROLE_WORKSHOP_DETAILS_VIEW', 'ROLE_ADMIN'], title: 'Szczegóły serwisu' },
        canActivate: [AuthGuard],
        children: [
          {
            path: 'repair-unit-details/:id',
            component: RepairUnitDetailsComponent,
            data: { roles: ['ROLE_WORKSHOP_DETAILS_VIEW', 'ROLE_ADMIN'], title: 'Szczegóły punktu serwisowego' },
            canActivate: [AuthGuard],
          }
        ]
      },
    ],
  },
  {
    path: 'settlements',
    component: SettlementComponent,
    data: { roles: ['ROLE_BILLINGS_REVENUE_VIEW', 'ROLE_ADMIN'], title: 'Rozliczenia' },
    canActivate: [AuthGuard],
  },
  {
    path: 'settlements/:id',
    component: SettlementItemComponent,
    data: { roles: ['ROLE_BILLINGS_REVENUE_VIEW', 'ROLE_ADMIN'], title: 'Szczegóły rozliczenia' },
    canActivate: [AuthGuard],
  },
  {
    path: 'costs',
    component: CostsComponent,
    data: { roles: ['ROLE_BILLINGS_COSTS_VIEW', 'ROLE_ADMIN'], title: 'Wyceny' },
    canActivate: [AuthGuard],
    children: [
      {
        path: 'compilations',
        component: CompilationsComponent,
        data: { roles: ['ROLE_BILLINGS_VIEW', 'ROLE_ADMIN'], title: 'Zestawienia' },
        canActivate: [AuthGuard],
        children: [
          {
            path: 'compilation/:id',
            component: CompilationDetailsComponent,
            data: { roles: ['ROLE_BILLINGS_VIEW', 'ROLE_ADMIN'], title: 'Szczegóły zestawienia' },
            canActivate: [AuthGuard],
          }
        ]
      },
    ]
  },
  {
    path: 'costs/:id',
    component: CostsItemComponent,
    data: { roles: ['ROLE_BILLINGS_COSTS_VIEW', 'ROLE_ADMIN'], title: 'Szczegóły wyceny' },
    canActivate: [AuthGuard],
  },
  {
    path: 'settings',
    component: SettingsComponent,
    data: { roles: ['ROLE_SYSTEM_MARGIN_SETTINGS', 'ROLE_ADMIN'], title: 'Ustawienia' },
    canActivate: [AuthGuard],
  },
  {
    path: 'users',
    component: UsersComponent,
    data: { roles: ['ROLE_SETTINGS_USERS_VIEW', 'ROLE_ADMIN'], title: 'Użytkownicy' },
    canActivate: [AuthGuard],
  },
  {
    path: 'admin-panel',
    component: AdminPanelComponent,
    data: { roles: ['ROLE_CRONS', 'ROLE_ADMIN'], title: 'Panel administratora' },
    canActivate: [AuthGuard],
  },
  {
    path: 'logs/:id',
    component: LogsComponent,
    data: { roles: ['ROLE_LOGS', 'ROLE_ADMIN'], title: 'Szczegóły logów' },
    canActivate: [AuthGuard],
  },
  {
    path: 'logs',
    component: LogsComponent,
    data: { roles: ['ROLE_LOGS', 'ROLE_ADMIN'], title: 'Logi' },
    canActivate: [AuthGuard],
  },
  {
    path: 'system-settings',
    component: SystemSettingsComponent,
    data: { roles: ['ROLE_ADMIN', 'ROLE_SETTINGS'], title: 'Ustawienia systemowe' },
    canActivate: [AuthGuard],
  },
  {
    path: 'logs-local',
    component: LogsLocalComponent,
    data: { roles: ['ROLE_LOGS_LOCAL', 'ROLE_ADMIN'], title: 'Logi lokalne' },
    canActivate: [AuthGuard],
  },
  {
    path: 'reports',
    component: ReportsComponent,
    data: { roles: ['ROLE_REPORTS', 'ROLE_ADMIN'], title: 'Raporty' },
    canActivate: [AuthGuard],
    // loadComponent: () =>
    //   import('breadcrumbs').then(m => m.ProductListComponent),
  },
  { path: '**', redirectTo: '' },
];

@NgModule({
  declarations: [],
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule {}
