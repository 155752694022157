<ng-container>
  <div class="main-logs-container-wrapper">
    @if (Auth.isViewAllowed(['ROLE_ORDERS_SEARCH', 'ROLE_ADMIN'])) {
      <div class="filters filters-inputs">

        <mat-form-field class="filter-field" appearance="outline">
          <mat-label>{{ 'relatedOrder' | translate }}</mat-label>
          <input matInput class="form-field" [formControl]="relatedOrder" />
        </mat-form-field>

        <mat-form-field class="filter-field" appearance="outline">
          <mat-label>{{ 'method' | translate }}</mat-label>
          <mat-select
            [formControl]="method"
            multiple
            [(ngModel)]="methodSelected">
            <mat-option value="GET">GET</mat-option>
            <mat-option value="POST">POST</mat-option>
            <mat-option value="PUT">PUT</mat-option>
            <mat-option value="DELETE">DELETE</mat-option>
            <mat-option value="PATCH">PATCH</mat-option>
            <mat-option value="SOAP">SOAP</mat-option>
            <mat-option value="OPTIONS">OPTIONS</mat-option>
          </mat-select>
        </mat-form-field>

        <mat-form-field class="filter-field" appearance="outline">
          <mat-label>{{ 'responseCode' | translate }}</mat-label>
          <input matInput class="form-field" [formControl]="responseCode" />
        </mat-form-field>

        <mat-form-field class="filter-field" appearance="outline">
          <mat-label>{{ 'requestPayload' | translate }}</mat-label>
          <input matInput class="form-field" [formControl]="requestPayload" />
        </mat-form-field>

        <mat-form-field class="filter-field" appearance="outline">
          <mat-label>{{ 'responseMessage' | translate }}</mat-label>
          <input matInput class="form-field" [formControl]="responseMessage" />
        </mat-form-field>

        <mat-form-field class="filter-field" appearance="outline">
          <mat-label>{{ 'requestUri' | translate }}</mat-label>
          <input matInput class="form-field" [formControl]="requestUri" />
        </mat-form-field>
        
        <mat-form-field class="filter-field" appearance="outline">
          <mat-label>{{ 'after' | translate }}</mat-label>
          <input
            matInput
            [matDatepicker]="after"
            class="form-field"
            [formControl]="createdAt.after" />
          <mat-datepicker-toggle
            matIconSuffix
            [for]="after"></mat-datepicker-toggle>
          <mat-datepicker #after></mat-datepicker>
        </mat-form-field>

        <mat-form-field class="filter-field" appearance="outline">
          <mat-label>{{ 'before' | translate }}</mat-label>
          <input
            matInput
            [matDatepicker]="before"
            class="form-field"
            [formControl]="createdAt.before" />
          <mat-datepicker-toggle
            matIconSuffix
            [for]="before"></mat-datepicker-toggle>
          <mat-datepicker #before></mat-datepicker>
        </mat-form-field>

        @if(Auth.isViewAllowed(['ROLE_LOGS_SEARCH', 'ROLE_ADMIN'])){
        <div class="buttons">
          <button
            class="filter-button"
            mat-stroked-button
            (click)="clearFilters()"
            color="primary">
            {{ 'clear' | translate }}
          </button>
          <button
            class="filter-button"
            mat-raised-button
            (click)="sendFilters()"
            color="primary">
            {{ 'search' | translate }}
          </button>
        </div>
        }
      </div>
    }

    <div class="main-table-container">
      <table mat-table [dataSource]="dataSource">

        <ng-container matColumnDef="Lp">
          <th mat-header-cell *matHeaderCellDef>Lp.</th>
          <td mat-cell *matCellDef="let element; let i = index">
            {{ itemsPerPage * pageIndex + i + 1 }}
          </td>
        </ng-container>

        <ng-container matColumnDef="relatedOrder">
          <th mat-header-cell *matHeaderCellDef>
            {{ 'relatedOrder' | translate }}
          </th>
          <td mat-cell *matCellDef="let element">
            @if(!!element?.relatedOrder) {
              <div class="related-order-container">
                <button [disabled]="" mat-icon-button [matTooltip]="'Skopiuj ID zlecenia: '+element?.relatedOrder?.replace('/api/v1/internal/orders/', '')">
                  <fa-icon [icon]="faDatabase"/>
                </button>
                <button mat-icon-button matTooltip="Skopiuj adres URL">
                  <mat-icon>insert_link</mat-icon>
                </button>
                <button mat-icon-button matTooltip="Otwórz zlecenie w nowej karcie">
                  <mat-icon>open_in_new</mat-icon>
                </button>
              </div>
            }
            @else{
              -
            }
            <!-- <a
              class="like-link"
              [href]="element.relatedOrder.replace('/api/v1/internal/', '')"
              *ngIf="element.relatedOrder">
              {{ element.relatedOrder.replace('/api/v1/internal/', '') }}
            </a> -->
          </td>
        </ng-container>

        <ng-container matColumnDef="createdAt">
          <th mat-header-cell *matHeaderCellDef>
            {{ 'createdAt' | translate }}
          </th>
          <td mat-cell *matCellDef="let element">
            {{ element.createdAt }}
          </td>
        </ng-container>

        <ng-container matColumnDef="method">
          <th mat-header-cell *matHeaderCellDef>
            {{ 'method' | translate }}
          </th>
          <td mat-cell *matCellDef="let element">{{ element.method }}</td>
        </ng-container>

        <ng-container matColumnDef="requestUri">
          <th mat-header-cell *matHeaderCellDef>
            {{ 'requestUri' | translate }}
          </th>
          <td mat-cell *matCellDef="let element">
            <div style="display: flex; gap: 5px; align-items: center;">
            <button 
              mat-icon-button 
              matTooltip="Kopiuj adres zapytania"
              (click)="copyValue(element.requestUri)"
              style="z-index: 0;"
            >
              <mat-icon>content_copy</mat-icon>
            </button>
            {{ element.requestUri }}
            </div>
          </td>
        </ng-container>

        <ng-container matColumnDef="requestPayload">
          <th mat-header-cell *matHeaderCellDef>
            {{ 'requestPayload' | translate }}
          </th>
          <td mat-cell *matCellDef="let element">
            <div style="display: flex; align-items: center; gap: 5px;">
              <button 
                mat-icon-button 
                matTooltip="Kopiuj Payload"
                (click)="copyValue(element.requestPayload)"
                style="z-index: 1;"
              >
                <mat-icon>content_copy</mat-icon>
              </button>
              {{ element.requestPayload }}
            </div>
          </td>
        </ng-container>

        <ng-container matColumnDef="responseCode">
          <th mat-header-cell *matHeaderCellDef>
            {{ 'responseCode' | translate }}
          </th>
          <td
            mat-cell
            *matCellDef="let element">
            <div
              style="text-align: center; font-weight: 500;"
              [ngClass]="{
                'none': !element.responseCode,
                'success': element.responseCode < '300',
                'danger': element.responseCode > '399',
                'warning':
                  element.responseCode < '400' && element.responseCode > '299',
              }"
            >
              {{ element.responseCode ? element.responseCode : 'Brak' }}
            </div>
          </td>
        </ng-container>

        <ng-container matColumnDef="responseMessage">
          <th mat-header-cell *matHeaderCellDef>
            {{ 'responseMessage' | translate }}
          </th>
          <td mat-cell *matCellDef="let element">
            {{ element?.responseMessage | dashIfEmpty }}
          </td>
        </ng-container>

        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>

        <tr
          mat-row
          *matRowDef="let row; columns: displayedColumns"
          [contextMenu]="oneContextMenu"
          [contextMenuValue]="row">
        </tr>
      </table>
      <div class="empty-table-view" *ngIf="dataSource?.data?.length === 0">
        <p>{{ 'no_data' | translate }}</p>
      </div>
    </div>

    <mat-paginator
      [pageSizeOptions]="[10, 20, 50, 100, 150, 300, 500]"
      [pageSize]="itemsPerPage"
      [pageIndex]="pageIndex"
      (page)="onPaginateChange($event)"
      aria-label="Select page of periodic elements">
    </mat-paginator>
  </div>
</ng-container>

<context-menu #oneContextMenu menuClass="custom-style">
  <ng-template contextMenuItem let-value (execute)="copyRequestAdress($event)">
    Kopiuj adres zapytania</ng-template
  >

  <ng-template contextMenuItem (execute)="copyPayload($event)" let-value>
    Kopiuj payload</ng-template
  >
</context-menu>
