import { Directive, HostListener, OnInit, inject, DestroyRef } from '@angular/core';
import { MatDialogContainer, MatDialogRef } from '@angular/material/dialog';
import { fromEvent, Subscription } from 'rxjs';
import { takeUntil, tap } from 'rxjs/operators';

@Directive({
  selector: '[mat-dialog-draggable-title]',
  standalone: false,
})
export class DialogDraggableTitleDirective implements OnInit {
  private _subscription: Subscription | null = null;

  private mouseStart: Position = { x: 0, y: 0 };
  private mouseDelta: Position = { x: 0, y: 0 };
  private offset: Position = { x: 0, y: 0 };

  private destroyRef = inject(DestroyRef);

  constructor(
    private matDialogRef: MatDialogRef<any>,
    private container: MatDialogContainer
  ) {}

  ngOnInit() {
    this.offset = this._getOffset();
  }

  @HostListener('mousedown', ['$event'])
    onMouseDown(event: MouseEvent) {
        this.mouseStart = { x: event.pageX, y: event.pageY };

        if (!this.offset) { 
            this.offset = this._getOffset();
        }

        this._toggleBackdropVisibility(true);

        const mouseup$ = fromEvent<MouseEvent>(document, 'mouseup').pipe(
            tap(() => this.onMouseup()) 
        );

        const mousemove$ = fromEvent<MouseEvent>(document, 'mousemove').pipe(
            takeUntil(mouseup$),
            tap((e) => this.onMouseMove(e))
        );

        this._subscription = mousemove$.subscribe();
        this._subscription.add(mouseup$.subscribe())
    }

    private onMouseup() {
        this._subscription?.unsubscribe();
        this._subscription = null;
        this.offset!.x += this.mouseDelta.x;
        this.offset!.y += this.mouseDelta.y;
        this._toggleBackdropVisibility(false);
    }

    private _toggleBackdropVisibility(isDragging: boolean) {
        const backdrop = document.querySelector('.cdk-overlay-backdrop');
        console.log(isDragging)
        if (isDragging) {
            backdrop.classList.add('transparent-backdrop');
        } else {
            backdrop.classList.remove('transparent-backdrop');
        }
    }

  private onMouseMove(event: MouseEvent) {
    this.mouseDelta = {
      x: event.pageX - this.mouseStart.x,
      y: event.pageY - this.mouseStart.y,
    };
    this._updatePosition(this.offset.y + this.mouseDelta.y, this.offset.x + this.mouseDelta.x);
  }

  private _updatePosition(top: number, left: number) {
    this.matDialogRef.updatePosition({ top: `${top}px`, left: `${left}px` });
  }

  private _getOffset(): Position {
    const box = this.container['_elementRef'].nativeElement.getBoundingClientRect();
    return {
      x: box.left + window.scrollX,
      y: box.top + window.scrollY
    };
  }
  
}

interface Position {
  x: number;
  y: number;
}
