import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/app/environments/environment';
import { Observable } from 'rxjs';
import { SystemSettings } from 'dashboard-libs/models/systemSettings.model';

@Injectable({
  providedIn: 'root',
})
export class SettingsService {
  constructor(private http: HttpClient) {}

  getMargins() {
    return this.http.get(
      environment.API.apiHost +
        environment.API.apiPointInternal +
        '/order_margins'
    );
  }

  getSystemMargins() {
    return this.http.get(
      environment.API.apiHost +
        environment.API.apiPointInternal +
        '/system_margins/1'
    );
  }

  updateSystemMargins(body: any) {
    return this.http.put(
      environment.API.apiHost +
        environment.API.apiPointInternal +
        '/system_margins/1',
      body
    );
  }

  public getSystemSettings() : Observable<SystemSettings> {
    return this.http.get<SystemSettings>(`${environment.API.apiHost+environment.API.apiPointInternal}/admin/system_settings`);
  }

  public updateSystemSetting(systemSettingId: number, systemSettingKey: string, settingValue: string) : Observable<any> {
    return this.http.put<any>(`${environment.API.apiHost+environment.API.apiPointInternal}/admin/system_settings/${systemSettingId}`, {
      settingKey: systemSettingKey,
      settingValue: settingValue,
    });
  }
}
