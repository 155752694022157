<div class="shared-modal-container">
  <div class="shared-modal-header" mat-dialog-draggable-title>
    <div class="shared-modal-header-content">
        <h6>Generowanie raportu kosztów serwisu i kwota na polisie</h6>
        <p>Podaj zakres dat dla którego ma zostać wygenerowany raport</p>
    </div>
    <button class="shared-modal-close-btn" mat-dialog-close="cancel">
        <fa-icon [icon]="faXmark"/>
    </button>
  </div>
  <div class="shared-modal-body">
    <form class="filters-inputs">
      <mat-form-field class="filter-field" appearance="outline">
        <mat-label class="form-label"
          >{{ 'orderInvoiceDateFrom' | translate }}</mat-label
        >
        <input
          matInput
          class="form-label"
          [matDatepicker]="picker1"
          [formControl]="orderFinishDateFrom"
          required />
  
        <mat-datepicker-toggle
          matIconSuffix
          [for]="picker1"></mat-datepicker-toggle>
        <mat-error *ngIf="orderFinishDateFrom.hasError('required')">
          {{ 'requiredField' | translate }}
        </mat-error>
        <mat-datepicker #picker1></mat-datepicker>
      </mat-form-field>

      <mat-form-field class="filter-field" appearance="outline">
        <mat-label class="form-label"
          >{{ 'orderInvoiceDateTo' | translate }}</mat-label
        >
        <input
          matInput
          class="form-label"
          [matDatepicker]="picker2"
          [formControl]="orderFinishDateTo"
          required />
  
        <mat-datepicker-toggle
          matIconSuffix
          [for]="picker2"></mat-datepicker-toggle>
        <mat-datepicker #picker2></mat-datepicker>
        <mat-error *ngIf="orderFinishDateTo.hasError('required')">
          {{ 'requiredField' | translate }}
        </mat-error>
      </mat-form-field>
    </form>
  </div>
  <div class="shared-modal-footer">
      <div class="shared-modal-footer-buttons-container">
          <button class="shared-modal-secondary-btn" mat-dialog-close="cancel">
            {{ 'cancel' | translate }}
          </button>
          <button class="shared-modal-primary-btn" (click)="downloadSummary()">
            {{ 'generate' | translate }}
          </button>
      </div>
  </div>
</div>