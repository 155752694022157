import { Injectable } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { Router, ActivatedRouteSnapshot, NavigationEnd } from '@angular/router';
import { filter } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class TitleService {
  private defaultTitle = 'DRS';

  constructor(private titleService: Title, private router: Router) {}

  public setCustomTitle(customTitle: string): void {
    this.defaultTitle = customTitle;
  }

  public setCustomFullTitle(customTitle: string) : void {
    this.titleService.setTitle(`${this.defaultTitle} | ${customTitle}`)
  }

  setTitle() {
    this.router.events
      .pipe(
        filter((event) => event instanceof NavigationEnd)
      )
      .subscribe(() => {
        const root = this.router.routerState.snapshot.root;
        const routeTitle = this.getLastRouteTitle(root);
        this.titleService.setTitle(
          routeTitle ? `${this.defaultTitle} | ${routeTitle}` : `${this.defaultTitle} | Services`
        );
      });
  }

  private getLastRouteTitle(route: ActivatedRouteSnapshot): string | null {
    if (route.firstChild) {
      return this.getLastRouteTitle(route.firstChild);
    }

    return route.data && route.data['title'] ? route.data['title'] : null;
  }
}