import statusGroups from '../../../configs/statusGroups.json';
import settlements from '../../../configs/settlement.json';
import costs from '../../../configs/costs.json';
import Validators from '../../../configs/validators.json';
import statusGroupArchived from '../../../configs/statusGroupsArchived.json';
export const environment = {
  applicationName: 'DRS',
  production: false,
  API: {
    apiPointInternal: '/api/v1/internal',
    apiPointPartners: '/api/v1/partners',
    apiHost: 'https://app.dev.drsapp.pl',
  },
  Keycloak: 'https://sso.dev.drsapp.pl',
  statusGroup: statusGroups,
  statusGroupArchived: statusGroupArchived,
  settlements: settlements,
  costs: costs,
  validators: Validators,
  realm: 'drs',
  clientId: 'drsapp',
  configUrl: '/config.json',
  sessionTime: 300000,
  sessionWarning: 60000,
  language: 'pl',
};
