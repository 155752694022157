import { Component, Input, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ShowPreAuthComponent } from 'src/app/shared/modals/show-preauth/show-preauth.component';
import { Preauthorization } from 'dashboard-libs/models/preauthorization.model';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
  selector: 'app-order-info-status',
  standalone: false,
  templateUrl: './order-info-status.component.html',
  styleUrl: './order-info-status.component.scss',
})

export class OrderInfoStatusComponent implements OnInit{
  @Input({required: true}) preauthorizationData!: Preauthorization;

  constructor(
    private dialog: MatDialog, 
    private route: ActivatedRoute,
    private router: Router
  ) { }

  ngOnInit(): void { }

  openPreAuth() : void {
    const dialogRef = this.dialog.open(ShowPreAuthComponent, {
      data: { preAuth: this.preauthorizationData },
      minWidth: '600px',
      height: 'auto',
      disableClose: false,
    });
  }
}
