import { Component, inject, Inject } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { faXmark } from '@fortawesome/free-solid-svg-icons';

@Component({
  selector: 'app-submit-order-modal',
  templateUrl: './submitOrderModal.component.html',
  styleUrl: './submitOrderModal.component.scss',
})
export class SubmitOrderModalComponent {
  faXmark = faXmark;

  private formBuilder = inject(FormBuilder);

  submitOrderModalForm = this.formBuilder.group({
    refNumber: ['',[]]
  });

  constructor(
    public dialogRef: MatDialogRef<SubmitOrderModalComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
  ) { }
}
