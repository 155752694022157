<div class="shared-modal-container">
  <div class="shared-modal-header" mat-dialog-draggable-title>
      <h6>Edycja kosztów</h6>
      <button class="shared-modal-close-btn" mat-dialog-close="cancel">
          <fa-icon [icon]="faXmark"/>
      </button>
  </div>
  <div class="shared-modal-body">
    <form [formGroup]="formCosts" class="filters-inputs">
      <p class="p-content">Koszt transportu</p>

      <mat-form-field appearance="outline" class="full-width">
        <mat-label>Koszt kuriera(netto)</mat-label>
        <input matInput formControlName="courier_cost" type="number" />
      </mat-form-field>

      <mat-form-field appearance="outline" class="full-width">
        <mat-label>Koszt obsługi(netto)</mat-label>
        <input matInput formControlName="service_cost" type="number" />
      </mat-form-field>

      <p class="p-content">Całkowity koszt ekspertyzy</p>

      <mat-form-field appearance="outline" class="full-width">
        <mat-label>Koszt konsultacji(netto)</mat-label>
        <input matInput formControlName="consultation_cost" type="number" />
      </mat-form-field>

      <mat-form-field appearance="outline" class="full-width">
        <mat-label>Koszt wymiany(netto)</mat-label>
        <input matInput formControlName="replacement_cost" type="number" />
      </mat-form-field>

      <p class="p-content">Pozostałe koszty</p>

      <mat-form-field appearance="outline" class="full-width">
        <mat-label>Koszt robocizny(netto)</mat-label>
        <input matInput formControlName="labor_cost" type="number" />
      </mat-form-field>

      <mat-form-field appearance="outline" class="full-width">
        <mat-label>Udział własny</mat-label>
        <input matInput formControlName="own_share" type="text" placeholder="Udział własny wyliczy się automatycznie" />
      </mat-form-field>

      <mat-checkbox formControlName="synchro" class="example-margin">
        Synchronizuj z klientem
      </mat-checkbox>
    </form>

    <div>
        <mat-error *ngIf="formCosts.invalid" class="error">
          Wszystkie pola muszą byc wypełnione.
        </mat-error>
        <mat-error *ngIf="error"> {{ error }}</mat-error>
    </div>
  </div>
  <div class="shared-modal-footer">
      <div class="shared-modal-footer-buttons-container">
        <button
          mat-stroked-button mat-dialog-close="cancel"
        >
          {{ 'orders-front.cancel' | translate }}
        </button>
        <button mat-raised-button mat-dialog-close="success" (click)="onSubmit()" color="primary">
          <span style="color: #FFF;">{{ 'orders-front.add' | translate }}</span>
        </button>
      </div>
  </div>
</div>

<!-- <h1 mat-dialog-title class="title">Edycja kosztów</h1>
<hr class="custom-hr" />
<div mat-dialog-content class="mat-dialog-content" [formGroup]="formCosts">
  <p class="p-content">Koszt transportu</p>
  <div class="did-floating-label-content full-width">
    <input
      class="did-floating-input"
      formControlName="courier_cost"
      type="number"
      placeholder=" " />
    <label class="did-floating-label">Koszt kuriera(netto)</label>
  </div>
  <div class="did-floating-label-content full-width">
    <input
      class="did-floating-input"
      formControlName="service_cost"
      type="number"
      placeholder=" " />
    <label class="did-floating-label">Koszt obsługi(netto)</label>
  </div>
  <p class="p-content">Całkowity koszt ekspertyzy</p>
  <div class="did-floating-label-content full-width">
    <input
      class="did-floating-input"
      formControlName="consultation_cost"
      type="number"
      placeholder=" " />
    <label class="did-floating-label">Koszt konsultacji(netto)</label>
  </div>
  <div class="did-floating-label-content full-width">
    <input
      class="did-floating-input"
      formControlName="replacement_cost"
      type="number"
      placeholder=" " />
    <label class="did-floating-label">Koszt wymiany(netto)</label>
  </div>
  <p class="p-content">Pozostałe koszty</p>
  <div class="did-floating-label-content full-width">
    <input
      class="did-floating-input"
      formControlName="labor_cost"
      type="number"
      placeholder=" " />
    <label class="did-floating-label">Koszt robocizny(netto)</label>
  </div>
  <div class="did-floating-label-content full-width">
    <input
      class="did-floating-input"
      formControlName="own_share"
      type="text"
      placeholder="Udział własny wyliczy się automatycznie" />
    <label class="did-floating-label">Udział własny</label>
  </div>
  <section class="example-section">
    <mat-checkbox formControlName="synchro" class="example-margin"
      >Synchronizuj z klientem</mat-checkbox
    >
  </section>
  <mat-error *ngIf="formCosts.invalid" class="error">
    Wszystkie pola muszą byc wypełnione.
  </mat-error>
  <mat-error *ngIf="error"> {{ error }}</mat-error>
  <div mat-dialog-actions>
    <button
      mat-button
      class="secondary-btn"
      color="accent"
      [mat-dialog-close]="'cancel'">
      {{ 'orders-front.cancel' | translate }}
    </button>
    <button mat-button class="primary-btn" (click)="onSubmit()" color="primary">
      {{ 'orders-front.add' | translate }}
    </button>
  </div>
</div> -->
