import { Component, Inject, Input } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ActivatedRoute } from '@angular/router';
import { faXmark } from '@fortawesome/free-solid-svg-icons';
import { AuthGuard } from 'dashboard-libs/guards/access-allowed.guard';
import { OrdersService } from 'src/app/pages/orders/services/orders.service';

@Component({
  selector: 'app-add-repair-unit',
  templateUrl: './add-repair-unit.component.html',
  styleUrls: ['./add-repair-unit.component.scss'],
  //fix to standalone: true later
  standalone: false,
})
export class AddRepairUnitComponent {

  public faXmark = faXmark;

  name: any;
  streetName: any;
  buildingNumber: any;
  apartmentNumber: any;
  postalCode: any;
  city: any;
  submitted: boolean = false;
  error: any;
  public form!: FormGroup;
  constructor(
    public dialogRef: MatDialogRef<AddRepairUnitComponent>,
    public Auth: AuthGuard,
    public orderService: OrdersService,
    public fb: FormBuilder,
    public MatSnackBar: MatSnackBar,
    public route: ActivatedRoute,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {
    console.log(this.data);
    this.form = this.fb.group({
      name: ['', [Validators.required, Validators.minLength(3)]],
      streetName: ['', [Validators.required, Validators.minLength(3)]],
      buildingNumber: ['', [Validators.required, Validators.pattern('^[0-9A-Za-z/-]*$')]],
      apartmentNumber: ['', Validators.pattern('^[0-9A-Za-z/-]*$')],      
      postalCode: [
        '',
        [Validators.required, Validators.pattern('^[0-9]{2}-[0-9]{3}$')],
      ],
      city: ['', [Validators.required, Validators.minLength(2)]],
      email: ['', [Validators.email]],
      phoneNumber: ['', [Validators.pattern('^[0-9]*$')]],
      mobileNumber: ['', [Validators.pattern('^[0-9]*$')]],
    });

    if (this.data.edit == true) {
      this.form.patchValue({
        name: this.data.name,
        streetName: this.data.streetName,
        buildingNumber: this.data.buildingNumber,
        apartmentNumber: this.data.apartmentNumber,
        postalCode: this.data.postalCode,
        city: this.data.city,
        email: this.data.email,
        phoneNumber: this.data.phoneNumber,
        mobileNumber: this.data.mobile,
      });
    }
  }

  onSubmit() {
    this.submitted = true;
    const data = {
      name: this.form.value.name,
      streetName: this.form.value.streetName,
      buildingNumber: this.form.value.buildingNumber,
      apartmentNumber: this.form.value.apartmentNumber,
      postalCode: this.form.value.postalCode,
      city: this.form.value.city || "",
      edit: this.data.edit || "",
      email: this.form.value.email || "",
      phoneNumber: this.form.value.phoneNumber || "",
      mobileNumber: this.form.value.mobileNumber || "",
    };
    if (this.form.valid) {
      this.dialogRef.close(data);
    }
  }
}
