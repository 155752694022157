import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import {
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from '@angular/forms';
import {
  MAT_DIALOG_DATA,
  MatDialog,
  MatDialogRef,
} from '@angular/material/dialog';
import { UserService } from '../../services/user.service';
import { ServicesService } from '../../../pages/services/services/services.service';
import { UserConfirmActionComponent } from '../user-confirm-action/user-confirm-action.component';
import { OrdersService } from 'src/app/pages/orders/services/orders.service';
import { faXmark } from '@fortawesome/free-solid-svg-icons';
import { faBell } from '@fortawesome/free-regular-svg-icons';
import { Platform, PlatformsResponse } from 'dashboard-libs/models/platformsResponse.model';
import { filter, pairwise, Subject, takeUntil } from 'rxjs';

@Component({
  selector: 'app-edit-user',
  templateUrl: './edit-user.component.html',
  styleUrls: ['./edit-user.component.scss'],
})
export class EditUserComponent implements OnInit, OnDestroy {

  private destroy$ = new Subject<void>();

  public faXmark = faXmark;

  public faBell = faBell;

  public platforms: Platform[] = [];

  public isLoading: boolean = false;

  public showError: boolean = false;

  public showGeneralError: boolean = false;

  public submitted: boolean = false;

  public services: any;

  public defaultGroup: any;

  public userGroups: any;

  public defaultWorkshop: any;

  public defaultGroupId: any;

  public repairUnits: any;

  public repairUnit: any;

  public defaultRepairUnit: any;

  isEdit: boolean = false;

  public form = this.fb.group({
    email: ['', [Validators.required, Validators.email]],
    firstName: ['', [Validators.required, Validators.minLength(2)]],
    lastName: ['', [Validators.required, Validators.minLength(2)]],
    workshop: ['', [Validators.required]],
    repairUnits: [[]],
    groups: ['', [Validators.required]],
    platforms: [[], [Validators.required]]
  });

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    private userService: UserService,
    private dialog: MatDialog,
    private fb: FormBuilder,
    private servicesService: ServicesService,
    private orderService: OrdersService,
    private dialogRef: MatDialogRef<EditUserComponent>
  ) {
    this.servicesService.getPlatformsAsAdmin().subscribe({
      next: (platformsData: PlatformsResponse) => {
        this.platforms = platformsData['hydra:member'];
      }
    });
    this.userService.getUsersGroups().subscribe((res: any) => {
      this.userGroups = res['hydra:member'];
    });
  }

  ngOnInit(): void {
    this.servicesService.getServices().subscribe((res: any) => {
      this.services = res['hydra:member'];
      this.isEdit = this.data.isEdit;
      this.userService.getUserAdminInfo(this.data.id).subscribe({
        next: (res: any) => {
          this.form.patchValue(res);
          if (res.groups.length > 0) {
            this.defaultGroup = res.groups[0].name;
  
            this.defaultGroupId = res.groups[0].id;
  
            const serviceId = res.workshop;
            
            this.defaultWorkshop = this.services.find((service: any) => service.id === serviceId)?.name;

            if(serviceId) {
              this.orderService.getRepairUnitsByWorkshop(serviceId).subscribe((res: any) => {
                this.repairUnits = res.items['hydra:member'];
                this.defaultRepairUnit = this.repairUnits.find((repairUnit: any) => repairUnit.id === this.form.value.repairUnits)?.name;
                console.log(this.repairUnits);
                this.listenForWorkshopChange();
              });
            }
          }
        },
        error: (error) => {
          console.error(error);
        }
      });
    });

    if (this.data.isEdit) {
      this.form.enable();
    } else {
      this.form.disable();
    }
  }

  listenForWorkshopChange() : void {
    this.form?.get('workshop')?.valueChanges.pipe(
      pairwise(),
      filter(([prev, curr]) => prev !== curr && typeof curr === 'string'),
      takeUntil(this.destroy$)
    ).subscribe(([_, value]) => {
      if(typeof value === 'string'){
        this.refreshRepairUnits(value);
      }
    });
  }

  edit(): void {
    this.isEdit = true;
    this.form.enable();
  }

  save(): void {
    this.submitted = true;
    if (this.form.invalid) {
      this.form.markAllAsTouched();
    } else {
      this.isLoading = true;
      this.userService.updateUserAdmin(this.data.id, {
        username: this.form.value.email,
        email: this.form.value.email,
        firstName: this.form.value.firstName,
        lastName: this.form.value.lastName,
        workshop: this.form.value.workshop,
        repairUnits: this.form.value.repairUnits || [],
        groups: [
          this.userGroups.find(
            (group: any) => group.id === this.form.value.groups
          ),
        ],
        platforms: this.form.value.platforms
      }).subscribe({
        next: () => {
          this.dialogRef.close();
          this.isLoading = false;
          const dialogRef = this.dialog.open(UserConfirmActionComponent, {
            data: { isEdit: true },
            disableClose: true,
          });

          dialogRef.afterClosed().subscribe();
        }, error: (error) => {
          if (error.status === 409) {
            this.showError = true;
          } else {
            this.showGeneralError = true;
          }
        }
      });
    }
  }

  refreshRepairUnits(workshopId: string): void {
    if (workshopId !== '') {
      this.orderService
        .getRepairUnitsByWorkshop(workshopId)
        .subscribe((res: any) => {
          this.repairUnits = res.items['hydra:member'];
          this.defaultRepairUnit = '';
          this.form.patchValue({
            repairUnits: null
          });
        });
    }
  }

  editOnOff(): void {
    this.isEdit = !this.isEdit;

    if (this.isEdit) {
      this.form.enable();
    } else {
      this.form.disable();
    }
  }

  ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.complete();
  }
}
