import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { RepairUnitDetails } from 'dashboard-libs/models/repairUnitDetails.model';
import { OrdersService } from 'dashboard-libs/services/orders.service';

@Component({
  selector: 'app-repair-unit-details',
  standalone: false,
  templateUrl: './repair-unit-details.component.html',
  styleUrl: './repair-unit-details.component.scss',
})
export class RepairUnitDetailsComponent implements OnInit {

  public isLoading: boolean = false;
  
  private readonly routeId: string = this.route.snapshot.params['id'];

  public repairUnitDetails!: RepairUnitDetails;

  constructor(
    public orderService: OrdersService,
    public route: ActivatedRoute
  ) {

  }

  ngOnInit(): void {
    this.fetchServiceRepairUnitDetails(this.routeId);
  }

  private fetchServiceRepairUnitDetails(id: string) : void {
    this.isLoading = true;
    this.orderService.getServiceRepairUnitDetails(id).subscribe({
      next: (repairUnitDetails : RepairUnitDetails) => {
        this.repairUnitDetails = repairUnitDetails;
      },
      error: (error) => {
        console.error(error);
      },
      complete: () => {
        this.isLoading = false;
      }
    })
  }
}
