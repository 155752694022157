<div class="shared-modal-container">
  <div class="shared-modal-header" mat-dialog-draggable-title>
      <div class="shared-modal-header-content">
          <h6>{{data.edit ? 'Edytuj punkt serwisowy' : 'Dodaj punkt serwisowy'}}</h6>
          <p>{{ 'orders-front.fill_in_the_information_below' | translate }}</p>
      </div>
      <button class="shared-modal-close-btn" mat-dialog-close="cancel">
          <fa-icon [icon]="faXmark"/>
      </button>
  </div>
  <div class="shared-modal-body filters-inputs">
      <form [formGroup]="form">
        <div class="row grid-1">
          <mat-form-field class="mr-10 mt-15" appearance="outline">
            <mat-label>{{ 'name' | translate }}</mat-label>
            <input matInput formControlName="name" />
            <mat-error *ngIf="submitted && form.controls['name'].errors?.['required']">
              {{ 'users-front.field_is_required' | translate }}
            </mat-error>
            <mat-error *ngIf="submitted && form.controls['name'].errors?.['minlength']">
              Przynajmniej 3 znaki
            </mat-error>
          </mat-form-field>
        </div>

        <div class="row grid-2">
          <mat-form-field class="mr-10 mt-15" appearance="outline">
            <mat-label>{{ 'postalCode' | translate }}</mat-label>
            <input matInput formControlName="postalCode" />
            <mat-error *ngIf="submitted && form.controls['postalCode'].errors?.['required']">
              {{ 'users-front.field_is_required' | translate }}
            </mat-error>
            <mat-error *ngIf="submitted && form.controls['postalCode'].errors?.['pattern']">
              Niepoprawny format
            </mat-error>
          </mat-form-field>

          <mat-form-field class="mr-10 mt-15" appearance="outline">
            <mat-label>{{ 'city' | translate }}</mat-label>
            <input matInput formControlName="city" />
            <mat-error *ngIf="submitted && form.controls['city'].errors?.['required']">
              {{ 'users-front.field_is_required' | translate }}
            </mat-error>
            <mat-error *ngIf="submitted && form.controls['city'].errors?.['minlength']">
              Przynajmniej 2 znaki
            </mat-error>
          </mat-form-field>
        </div>

        <div class="row grid-2">
          <mat-form-field class="mr-10 mt-15" appearance="outline">
            <mat-label>{{ 'streetName' | translate }}</mat-label>
            <input matInput formControlName="streetName" />
            <mat-error *ngIf="submitted && form.controls['streetName'].errors?.['required']">
              {{ 'users-front.field_is_required' | translate }}
            </mat-error>
            <mat-error *ngIf="submitted && form.controls['streetName'].errors?.['minlength']">
              Przynajmniej 3 znaki
            </mat-error>
          </mat-form-field>
          
          <div class="row grid-2">
            <mat-form-field class="mr-10 mt-15" appearance="outline">
              <mat-label>{{ 'buildingNumber' | translate }}</mat-label>
              <input matInput formControlName="buildingNumber" />
              <mat-error *ngIf="submitted && form.controls['buildingNumber'].errors?.['required']">
                {{ 'users-front.field_is_required' | translate }}
              </mat-error>
              <mat-error *ngIf="submitted && form.controls['buildingNumber'].errors?.['pattern']">
                Niepoprawny format
              </mat-error>
            </mat-form-field>
  
            <mat-form-field class="mr-10 mt-15" appearance="outline">
              <mat-label>{{ 'apartmentNumber' | translate }}</mat-label>
              <input matInput formControlName="apartmentNumber" />
              <mat-error *ngIf="submitted && form.controls['apartmentNumber'].errors?.['pattern']">
                Niepoprawny format
              </mat-error>
            </mat-form-field>
          </div>
        </div>

        <div class="row grid-1">
          <mat-form-field class="mr-10 mt-15" appearance="outline">
            <mat-label>{{ 'email' | translate }}</mat-label>
            <input matInput formControlName="email" />
            <mat-error *ngIf="submitted && form.controls['email'].errors?.['email']">
              Błędny adress email
            </mat-error>
          </mat-form-field>
          
          <mat-form-field class="mr-10 mt-15" appearance="outline">
            <mat-label>Numer telefonu</mat-label>
            <input matInput formControlName="phoneNumber" />
            <mat-error *ngIf="submitted && form.controls['phoneNumber'].errors?.['pattern']">
              Niepoprawny format
            </mat-error>
          </mat-form-field>
          
          <mat-form-field class="mr-10 mt-15" appearance="outline">
            <mat-label>Numer telefonu komórkowego</mat-label>
            <input matInput formControlName="mobileNumber" />
            <mat-error *ngIf="submitted && form.controls['mobileNumber'].errors?.['pattern']">
              Niepoprawny format
            </mat-error>
          </mat-form-field>
        </div>
        <mat-error *ngIf="error">{{ error | translate }}</mat-error>
      </form>
  </div>
  <div class="shared-modal-footer">
      <div class="shared-modal-footer-buttons-container">
          <button class="shared-modal-secondary-btn" mat-stroked-button mat-dialog-close="cancel" mat-dialog-close="cancel">
            {{ 'orders-front.cancel' | translate }}
          </button>
          @if (!data.edit) {
            <button class="shared-modal-primary-btn" mat-raised-button type="submit" (click)="onSubmit()" color="primary">
              {{ 'orders-front.add' | translate }}
            </button>
          } @else {
            <button class="shared-modal-primary-btn" mat-raised-button type="submit" (click)="onSubmit()" color="primary">
              {{ 'orders-front.save' | translate }}
            </button>
          }
      </div>
  </div>
</div>

<!-- <h1 mat-dialog-title class="title">Dodaj jednostkę naprawczą</h1>
<hr class="custom-hr" />
<div mat-dialog-content>
  <p class="p-content">
    {{ 'orders-front.fill_in_the_information_below' | translate }}
  </p>

  <form [formGroup]="form" class="flex">
    <div class="did-floating-label-content mr-10 mt-15">
      <input
        class="did-floating-input"
        formControlName="name"
        placeholder=" " />
      <label class="did-floating-label">{{ 'name' | translate }}*</label>
      <mat-error
        *ngIf="submitted && form.controls['name'].errors?.['required']"
        >{{ 'users-front.field_is_required' | translate }}</mat-error
      >
      <mat-error
        *ngIf="submitted && form.controls['name'].errors?.['minlength']"
        >Przynajmniej 3 znaki</mat-error
      >
    </div>
    <div class="did-floating-label-content mr-10 mt-15">
      <input
        class="did-floating-input"
        formControlName="streetName"
        placeholder=" " />
      <label class="did-floating-label">{{ 'streetName' | translate }}*</label>
      <mat-error
        *ngIf="submitted && form.controls['streetName'].errors?.['required']"
        >{{ 'users-front.field_is_required' | translate }}</mat-error
      >
      <mat-error
        *ngIf="submitted && form.controls['streetName'].errors?.['minlength']"
        >Przynajmniej 3 znaki</mat-error
      >
    </div>
    <div class="did-floating-label-content mr-10 mt-15">
      <input
        class="did-floating-input"
        formControlName="buildingNumber"
        placeholder=" " />
      <label class="did-floating-label"
        >{{ 'buildingNumber' | translate }}*</label
      >
      <mat-error
        *ngIf="
          submitted && form.controls['buildingNumber'].errors?.['required']
        "
        >{{ 'users-front.field_is_required' | translate }}</mat-error
      >
      <mat-error
        *ngIf="submitted && form.controls['buildingNumber'].errors?.['pattern']"
        >Niepoprawny format</mat-error
      >
    </div>
    <div class="did-floating-label-content mr-10 mt-15">
      <input
        class="did-floating-input"
        formControlName="apartmentNumber"
        placeholder=" " />
      <label class="did-floating-label">{{
        'apartmentNumber' | translate
      }}</label>
      <mat-error
        *ngIf="
          submitted && form.controls['apartmentNumber'].errors?.['pattern']
        "
        >Niepoprawny format</mat-error
      >
    </div>
    <div class="did-floating-label-content mr-10 mt-15">
      <input
        class="did-floating-input"
        formControlName="postalCode"
        placeholder=" " />
      <label class="did-floating-label">{{ 'postalCode' | translate }}*</label>
      <mat-error
        *ngIf="submitted && form.controls['postalCode'].errors?.['required']"
        >{{ 'users-front.field_is_required' | translate }}</mat-error
      >
      <mat-error
        *ngIf="submitted && form.controls['postalCode'].errors?.['pattern']"
        >Niepoprawny format</mat-error
      >
    </div>
    <div class="did-floating-label-content mr-10 mt-15">
      <input
        class="did-floating-input"
        formControlName="city"
        placeholder=" " />
      <label class="did-floating-label">{{ 'city' | translate }}*</label>
      <mat-error
        *ngIf="submitted && form.controls['city'].errors?.['required']"
        >{{ 'users-front.field_is_required' | translate }}</mat-error
      >
      <mat-error
        *ngIf="submitted && form.controls['city'].errors?.['minlength']"
        >Przynajmniej 2 znaki</mat-error
      >
    </div>
    <div class="did-floating-label-content mr-10 mt-15">
      <input
        class="did-floating-input"
        formControlName="email"
        placeholder=" " />
      <label class="did-floating-label">{{ 'email' | translate }}</label>
      <mat-error *ngIf="submitted && form.controls['email'].errors?.['email']"
        >Błędny adress email</mat-error
      >
    </div>
    <div class="did-floating-label-content mr-10 mt-15">
      <input
        class="did-floating-input"
        formControlName="phoneNumber"
        placeholder=" " />
      <label class="did-floating-label">{{ 'phoneNumber' | translate }}</label>
      <mat-error
        *ngIf="submitted && form.controls['phoneNumber'].errors?.['pattern']"
        >Niepoprawny format</mat-error
      >
    </div>
    <div class="did-floating-label-content mr-10 mt-15">
      <input
        class="did-floating-input"
        formControlName="mobileNumber"
        placeholder=" " />
      <label class="did-floating-label">{{ 'mobileNumber' | translate }}</label>
      <mat-error
        *ngIf="submitted && form.controls['mobileNumber'].errors?.['pattern']"
        >Niepoprawny format</mat-error
      >
    </div>
    <mat-error *ngIf="error">{{ error | translate }}</mat-error>
  </form>
</div>
<div mat-dialog-actions>
  <button
    mat-button
    class="secondary-btn"
    color="accent"
    [mat-dialog-close]="'cancel'">
    {{ 'orders-front.cancel' | translate }}
  </button>
  @if (!data.edit) {
    <button
      mat-button
      class="primary-btn"
      type="submit"
      (click)="onSubmit()"
      color="primary">
      {{ 'orders-front.add' | translate }}
    </button>
  } @else {
    <button
      mat-button
      class="primary-btn"
      type="submit"
      (click)="onSubmit()"
      color="primary">
      {{ 'orders-front.save' | translate }}
    </button>
  }
</div> -->
