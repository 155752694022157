import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { environment } from 'src/app/environments/environment';

export interface Paginator {
  limit: number;
  offset: number;
}

@Injectable({
  providedIn: 'root'
})
export class CostsService {

  private readonly baseApiUrl = environment.API.apiHost + environment.API.apiPointInternal;

  constructor(private httpClient: HttpClient) { }

  public getCompilations(paginator: Partial<Paginator>, compilationsFormData?: any) : Observable<any> {
    let params = new HttpParams()
      .set('offset', paginator.offset?.toString() || '0')
      .set('limit', paginator.limit?.toString() || '10');

      params = params.append('sort[invoiceNumber]', 'desc');

    if (compilationsFormData) {
      Object.keys(compilationsFormData).forEach(key => {
        const value = compilationsFormData[key];
        
        if (value !== null && value !== undefined) {
          if (Array.isArray(value)) {
            value.forEach((v: string) => {
              params = params.append(key, v);
            });
          } else {
            params = params.set(key, value.toString());
          }
        }
      });
    }

    return this.httpClient.get<any>(`${this.baseApiUrl}/billing/invoices/list`, { params });
  }

  public getCompilationDetails(id: string, paginator: Partial<Paginator>, compilationDetailsFormData?: any) : Observable<any> {
    let params = new HttpParams()
      .set('offset', paginator.offset?.toString() || '0')
      .set('limit', paginator.limit?.toString() || '10');

      params = params.append('sort[invoiceNumber]', 'desc');

    if (compilationDetailsFormData as any) {
      Object.keys(compilationDetailsFormData).forEach(key => {
        const value = compilationDetailsFormData[key];
        
        if (value !== null && value !== undefined) {
          if (Array.isArray(value)) {
            value.forEach((v: string) => {
              params = params.append(key, v);
            });
          } else {
            params = params.set(key, value.toString());
          }
        }
      });
    }

    return this.httpClient.get<any>(`${this.baseApiUrl}/billing/invoices-list/${id}`, { params });
  }

  public deleteCompilation(id: string) : Observable<any> {
    return this.httpClient.delete<any>(`${this.baseApiUrl}/billing/invoice-list/${id}`);
  }

  public uploadInvoiceForOrders(formData: FormData): Observable<any> {
    return this.httpClient.post<Observable<any>>(`${this.baseApiUrl}/billing/upload-invoice-for-orders`, formData);
  }

  public updateInvoiceForOrders(id: string, formData: FormData): Observable<any> {
    return this.httpClient.post<Observable<any>>(`${this.baseApiUrl}/billing/${id}/reupload-invoice-document`, formData);
  }
}
