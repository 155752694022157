@if (showChildRoute) {
  <router-outlet />
} 
@else {
  <div class="main-service-item-container">
    <mat-tab-group class="no_pointer" mat-stretch-tabs="false" dynamicHeight disableRipple disabled>
      <mat-tab label="Serwis">
        <div class="main-content-container">
          <div class="actions">
            <button mat-button [matMenuTriggerFor]="menu" mat-stroked-button>
              <mat-icon>more_vert</mat-icon>
            </button>
            <mat-menu #menu="matMenu">
              <button mat-menu-item (click)="removeUser(service.id)">
                <mat-icon>delete</mat-icon>Usuń
              </button>
              <button *ngIf="!service?.active" mat-menu-item (click)="activate()">
                <mat-icon class="green">power_settings_new</mat-icon>Aktywuj
              </button>
              <button *ngIf="service?.active" mat-menu-item (click)="deactivate()">
                <mat-icon class="red">power_settings_new</mat-icon>Dezaktywuj
              </button>
            </mat-menu>
          </div>
  
          <div class="service-item-details-container">
            <div class="service-item-details-section">
              <div class="card">
                <div class="card-title">
                  <p>{{ 'services-front.contractor_data' | translate }}</p>
                  <button
                    (click)="editService()"
                    mat-stroked-button
                    *ngIf="
                      Auth.isViewAllowed([
                        'ROLE_WORKSHOP_SERVICE_EDIT',
                        'ROLE_ADMIN',
                      ])
                    ">
                    <p>{{ 'services-front.edit' | translate }}</p>
                  </button>
                </div>
                <hr class="custom-hr" />
                <div class="card-content">
                  <div class="content-item">
                    <p>Nazwa serwisu</p>
                    <p>{{ service?.name }}</p>
                  </div>
                  <hr class="custom-hr" />
                  <div class="content-item">
                    <p>{{ 'services-front.agreement_number' | translate }}</p>
                    <p>{{ service?.contractNumber }}</p>
                  </div>
                  <hr class="custom-hr" />
                  <div class="content-item">
                    <p>
                      {{ 'services-front.bank_account_number(IBAN)' | translate }}
                    </p>
                    <p>{{ service?.bankAccountNumber | iban }}</p>
                  </div>
                  <hr class="custom-hr" />
                  <div class="content-item">
                    <p>{{ 'services-front.nip' | translate }}</p>
                    <p>{{ service?.taxNumber }}</p>
                  </div>
                  <hr class="custom-hr" />
                  <div class="content-item">
                    <p>{{ 'services-front.regon' | translate }}</p>
                    <p>{{ service?.regon }}</p>
                  </div>
                  <hr class="custom-hr" />
                  <div class="content-item">
                    <p>{{ 'services-front.state' | translate }}</p>
                    <p>{{ service?.active ? 'Aktywny' : 'Nieaktywny' }}</p>
                  </div>
  
                  <hr class="custom-hr" />
                  <div class="content-item">
                    <p>{{ 'services-front.added' | translate }}</p>
                    <p>{{ service?.createdAt | date: 'YYYY.MM.dd HH:mm' }}</p>
                  </div>
                </div>
              </div>
  
              <div class="card">
                <div class="card-title">
                  <p>{{ 'services-front.address' | translate }}</p>
                  <button
                    (click)="editServiceAddress()"
                    mat-stroked-button
                    *ngIf="
                      Auth.isViewAllowed([
                        'ROLE_WORKSHOP_SERVICE_EDIT',
                        'ROLE_ADMIN',
                      ])
                    ">
                    <p>{{ 'services-front.editAddress' | translate }}</p>
                  </button>
                </div>
                <hr class="custom-hr" />
                <div class="card-content">
                  <div class="left-site">
                    <div class="content-item">
                      <p>{{ 'services-front.street' | translate }}</p>
                      <p>{{ service?.address?.streetName }}</p>
                    </div>
                    <hr class="custom-hr" />
                    <div class="content-item">
                      <p>{{ 'services-front.house_number' | translate }}</p>
                      <p>{{ service?.address?.buildingNumber }}</p>
                    </div>
                    <hr class="custom-hr" />
                    <div class="content-item">
                      <p>{{ 'services-front.city' | translate }}</p>
                      <p>{{ service?.address?.city }}</p>
                    </div>
                    <hr class="custom-hr" />
                    <div class="content-item">
                      <p>{{ 'services-front.postal_code' | translate }}</p>
                      <p>{{ service?.address?.postalCode | zipCode }}</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
    
            <div class="service-item-details-section">
              <div class="card">
                <div class="card-title">
                  <p>{{ 'services-front.contact' | translate }}</p>
                  <button
                    (click)="addContact()"
                    mat-stroked-button
                    *ngIf="
                      Auth.isViewAllowed(['ROLE_WORKSHOP_MANAGE', 'ROLE_ADMIN'])
                    ">
                    {{ 'services-front.add_contact' | translate }}
                  </button>
                </div>
                <hr class="custom-hr" />
                <div class="card-content">
                  <div class="left-site">
                    <div *ngFor="let contact of contacts; let i = index">
                      <div class="content-item">
                        <p style="display:flex; align-items: center; gap: 10px;">
                          <button mat-icon-button (click)="showContactDetails(i)">
                            <mat-icon>{{selectedDetails == i ? 'keyboard_arrow_down' : 'keyboard_arrow_up'}}</mat-icon>
                          </button>
                          {{ contact?.contactDetails?.name }}
                          {{ contact?.contactDetails?.surname }}
                        </p>
                        <p style="display:flex; align-items: center; gap: 10px;">
                          <span class="owner-chip">
                            {{
                              contact?.contactDetails?.type === 'Additional'
                                ? 'Dodatkowy'
                                : 'Główny'
                            }}
                          </span>
                          <button mat-icon-button (click)="showActions(contact?.id)">
                            <mat-icon>more_vert</mat-icon>
                          </button>
                        </p>
                      </div>
                      <div
                        (clickOutside)="removeActions()"
                        class="actions-dialog"
                        *ngIf="showActionsId == contact?.id">
                        <p (click)="editContact(contact)" class="click">
                          <mat-icon>edit</mat-icon
                          >{{ 'services-front.edit' | translate }}
                        </p>
                        <p (click)="deleteContact(contact)" class="click">
                          <mat-icon>delete</mat-icon
                          >{{ 'services-front.remove' | translate }}
                        </p>
                      </div>
                      <div class="contact-details" *ngIf="selectedDetails == i">
                        <div class="content-item">
                          <p>{{ 'services-front.email_address' | translate }}</p>
                          <p>{{ contact?.contactDetails?.email }}</p>
                        </div>
                        <hr class="custom-hr" />
                        <div
                          class="content-item"
                          *ngIf="contact?.contactDetails?.mobileNumber">
                          <p>{{ 'services-front.phone_number' | translate }}</p>
                          <p>{{ contact?.contactDetails?.mobileNumber }}</p>
                        </div>
                        <hr class="custom-hr" />
                        <div
                          class="content-item"
                          *ngIf="contact?.contactDetails?.phoneNumber">
                          <p>
                            {{
                              'services-front.phone_number(additional)' | translate
                            }}
                          </p>
                          <p>{{ contact?.contactDetails?.phoneNumber }}</p>
                        </div>
                      </div>
                    </div>
                    <hr class="custom-hr" />
                  </div>
                </div>
              </div>
    
              <div class="card">
                <div class="card-title">
                  <p>{{ 'services-front.margin' | translate }}</p>
                  <button
                    (click)="editMargin()"
                    mat-stroked-button
                    *ngIf="
                      Auth.isViewAllowed([
                        'ROLE_WORKSHOP_MARGIN_EDIT',
                        'ROLE_ADMIN',
                      ])
                    ">
                    <p>{{ 'services-front.edit' | translate }}</p>
                  </button>
                </div>
                <hr class="custom-hr" />
                <div class="card-content">
                  <div class="left-site">
                    <div class="content-item">
                      <p>{{ 'services-front.margin_name' | translate }}</p>
                      <p>Domyślna</p>
                    </div>
                  </div>
                </div>
              </div>
    
              <div class="card">
                <div class="card-title">
                  {{ 'supported_brands' | translate }}
                  <button
                    (click)="editBrands()"
                    mat-stroked-button
                    *ngIf="
                      Auth.isViewAllowed(['ROLE_WORKSHOP_MANAGE', 'ROLE_ADMIN'])
                    ">
                    <p>{{ 'services-front.edit' | translate }}</p>
                  </button>
                </div>
                <hr class="custom-hr" />
                <div class="card-content">
                  <div class="left-site">
                    <div class="content-item types">
                      <mat-chip-set>
                        @for (supportedBrand of service?.brand; track $index) {
                          <mat-chip class="example-box">{{ supportedBrand.name }}</mat-chip>
                        }
                      </mat-chip-set>
                    </div>
                  </div>
                </div>
              </div>
    
              <div class="card">
                <mat-checkbox [(ngModel)]="isAutoOrderConfirmChecked" (change)="onAutoOrderConfirm($event)">Autmatycznie przypisz zlecenie przy przyjęciu</mat-checkbox>
              </div>
            </div>
          </div>
        </div>
      </mat-tab>
  
      <mat-tab label="Punkty serwisowe">
        <app-repair-unit [workshopId]="workshopId"></app-repair-unit>
      </mat-tab>
    </mat-tab-group>
  </div>  
}