<div class="repair-unit-details-container">
    <div class="repair-unit-details-header">
        <h1>Szczegóły punktu serwisowego</h1>
        <button mat-icon-button>
            <mat-icon>settings</mat-icon>
        </button>
    </div>

    <div class="repair-unit-details-content-container">

        <div class="shared-card">
            <div class="shared-card-header">
                <h2>Dane podstawowe</h2>
                <button mat-stroked-button disabled>
                    Edytuj
                </button>
            </div>
            <div class="shared-card-body">
                <ul>
                    <li>
                        <p>Nazwa</p>
                        <p>-</p>
                    </li>
                    <li>
                        <p>NIP</p>
                        <p>-</p>
                    </li>
                    <li>
                        <p>REGON</p>
                        <p>-</p>
                    </li>
                    <li>
                        <p>Status</p>
                        <p>-</p>
                    </li>
                    <li>
                        <p>Data dodania</p>
                        <p>-</p>
                    </li>
                </ul>
            </div>
        </div>

        <div class="shared-card">
            <div class="shared-card-header">
                <h2>Kontakt</h2>
                <button mat-stroked-button disabled>
                    Edytuj
                </button>
            </div>
            <div class="shared-card-body">
                <ul>
                    <li>
                        <p>Adres e-mail</p>
                        <p>-</p>
                    </li>
                    <li>
                        <p>Numer telefonu</p>
                        <p>-</p>
                    </li>
                </ul>
            </div>
        </div>

        <div class="shared-card">
            <div class="shared-card-header">
                <h2>Adres</h2>
                <button mat-stroked-button disabled>
                    Edytuj
                </button>
            </div>
            <div class="shared-card-body">
                <ul>
                    <li>
                        <p>Ulica</p>
                        <p>-</p>
                    </li>
                    <li>
                        <p>Numer domu</p>
                        <p>-</p>
                    </li>
                    <li>
                        <p>Miejscowość</p>
                        <p>-</p>
                    </li>
                    <li>
                        <p>Kod pocztowy</p>
                        <p>-</p>
                    </li>
                </ul>
            </div>
        </div>

    </div>
</div>