<div class="shared-modal-container">
  <div class="shared-modal-header" mat-dialog-draggable-title>
    <div class="shared-modal-header-content">
      <h6>{{ 'services-front.adding_a_new_service' | translate }}</h6>
      <p class="title-desc">
        {{ 'services-front.fill_in_the_information_below' | translate }}:
        <span>
          @if(tab == 'companyInfo'){
            {{ 'services-front.company_details' | translate }}
          }
          @else{
            {{ 'services-front.contact_information' | translate }}
          }
        </span>
      </p>
    </div>
    <button class="shared-modal-close-btn" mat-dialog-close="cancel">
      <fa-icon [icon]="faXmark"/>
  </button>
  </div>
  <div class="shared-modal-body">
    <div [hidden]="tab !== 'companyInfo'" class="filters-inputs">
      <form [formGroup]="formCompanyInfo">
        
        <mat-form-field appearance="outline">
          <mat-label>{{ 'services-front.company_name' | translate }}</mat-label>
          <input matInput formControlName="name" type="text" required />
          <mat-error *ngIf="companyNext && formCompanyInfo.controls['name'].errors?.['required']">
            {{ 'services-front.field_is_required' | translate }}
          </mat-error>
        </mat-form-field>
  
        <div class="row grid-2">
          <mat-form-field appearance="outline">
            <mat-label>{{ 'services-front.regon' | translate }}</mat-label>
            <input matInput formControlName="regon" type="text" mask="000000000" required />
            <mat-error *ngIf="companyNext && formCompanyInfo.controls['regon'].errors?.['required']">
              {{ 'services-front.field_is_required' | translate }}
            </mat-error>
            <mat-error *ngIf="companyNext && formCompanyInfo.controls['regon'].errors?.['pattern']">
              To pole wymaga podania 9 cyfrowego numeru regon
            </mat-error>
          </mat-form-field>
        
          <mat-form-field appearance="outline">
            <mat-label>{{ 'services-front.nip' | translate }}</mat-label>
            <input matInput formControlName="nip" mask="0000000000" type="text" required />
            <mat-icon matPrefix (click)="getGusInfo()" matTooltip="{{ 'services-front.find_by_nip' | translate }}">
              search
            </mat-icon>
            <mat-error *ngIf="companyNext && formCompanyInfo.controls['nip']?.errors?.['required']">
              {{ 'services-front.field_is_required' | translate }}
            </mat-error>
            <mat-error *ngIf="gusError">
              <p *ngIf="gusError == 404">Nie znaleziono podmiotu o takim numerze NIP</p>
              <p *ngIf="gusError == 400">Błędny numer NIP</p>
              <p *ngIf="gusError == 'other'"></p>
            </mat-error>
            <mat-error *ngIf="!(companyNext && formCompanyInfo.controls['nip'].errors?.['required']) && companyNext && formCompanyInfo.controls['nip'].errors?.['pattern'] || !(companyNext && formCompanyInfo.controls['nip'].errors?.['required']) && gusInfo">
              To pole wymaga podania 10 cyfrowego numeru nip
            </mat-error>
          </mat-form-field>
        </div>
  
        <div class="row grid-2">
          <mat-form-field appearance="outline">
            <mat-label>{{ 'services-front.city' | translate }}</mat-label>
            <input matInput formControlName="city" required type="text" />
            <mat-error *ngIf="companyNext && formCompanyInfo.controls['city'].errors?.['required']">
              {{ 'services-front.field_is_required' | translate }}
            </mat-error>
            <mat-error *ngIf="companyNext && (formCompanyInfo.controls['city'].errors?.['minlength'] || formCompanyInfo.controls['city'].errors?.['maxlength'])">
              To pole wymaga podania prawidłowej nazwy miasta
            </mat-error>
          </mat-form-field>
        
          <mat-form-field appearance="outline">
            <mat-label>{{ 'services-front.postal_code' | translate }}</mat-label>
            <input matInput formControlName="postalCode" required mask="00-000" type="text" />
            <mat-error *ngIf="companyNext && formCompanyInfo.controls['postalCode']?.errors?.['required']">
              {{ 'services-front.field_is_required' | translate }}
            </mat-error>
            <mat-error *ngIf="companyNext && formCompanyInfo.controls['postalCode']?.errors?.['mask'] || companyNext && formCompanyInfo.controls['postalCode']?.errors?.['pattern']">
              To pole wymaga podania prawidłowego nr pocztowego
            </mat-error>
          </mat-form-field>
        </div>
  
        
        <div class="row grid-2">
          <mat-form-field appearance="outline">
            <mat-label>{{ 'services-front.street' | translate }}</mat-label>
            <input matInput formControlName="street" required type="text" />
            <mat-error *ngIf="companyNext && formCompanyInfo.controls['street'].errors?.['required']">
              {{ 'services-front.field_is_required' | translate }}
            </mat-error>
            <mat-error *ngIf="companyNext && (formCompanyInfo.controls['street'].errors?.['minlength'] || formCompanyInfo.controls['street'].errors?.['maxlength'])">
              To pole wymaga podania prawidłowej nazwy ulicy
            </mat-error>
          </mat-form-field>
        
          <div class="row grid-2">
            <mat-form-field appearance="outline">
              <mat-label>{{ 'services-front.house_number' | translate }}</mat-label>
              <input matInput formControlName="houseNumber" required type="text" />
              <mat-error *ngIf="companyNext && formCompanyInfo.controls['houseNumber'].errors?.['required']">
                {{ 'services-front.field_is_required' | translate }}
              </mat-error>
              <mat-error *ngIf="companyNext && formCompanyInfo.controls['houseNumber'].errors?.['pattern']">
                To pole wymaga podania prawidłowego numeru domu
              </mat-error>
            </mat-form-field>
  
            <mat-form-field appearance="outline">
              <mat-label>{{ 'services-front.apartment_number' | translate }}</mat-label>
              <input matInput formControlName="apartmentNumber" type="text" />
            </mat-form-field>
          </div>
        </div>
        
  
        <div class="row grid-2">
          <mat-form-field appearance="outline">
            <mat-label>{{ 'services-front.bank_account_number(IBAN)' | translate }}</mat-label>
            <input matInput formControlName="bankNumber" required mask="SS00-0000-0000-0000-0000-0000-0000" type="text" 
                   placeholder="AA00-0000-0000-0000-0000-0000-0000"/>
            <mat-error *ngIf="companyNext && formCompanyInfo.controls['bankNumber'].errors?.['required']">
              {{ 'services-front.field_is_required' | translate }}
            </mat-error>
            <mat-error *ngIf="companyNext && formCompanyInfo.controls['bankNumber'].errors?.['pattern'] || companyNext && formCompanyInfo.controls['bankNumber'].errors?.['mask']">
              To pole wymaga podania prawidłowego nr konta
            </mat-error>
          </mat-form-field>
        
          <mat-form-field appearance="outline">
            <mat-label>{{ 'services-front.agreement_number' | translate }}</mat-label>
            <input matInput formControlName="number" type="text" required />
            <mat-error *ngIf="companyNext && formCompanyInfo.controls['number'].errors?.['required']">
              {{ 'services-front.field_is_required' | translate }}
            </mat-error>
          </mat-form-field>
        </div>
        
        <div>
          <mat-checkbox formControlName="isAutoOrderConfirmChecked">Autmatycznie przypisz zlecenie przy przyjęciu</mat-checkbox>
        </div>
      </form>
    </div>
  
    <div [hidden]="tab !== 'contact'" class="filters-inputs contact-fields-container">
      <form [formGroup]="formContactInfo">
        <div class="row grid-2">
          <mat-form-field appearance="outline">
            <mat-label>{{ 'services-front.name' | translate }}</mat-label>
            <input matInput formControlName="name" required type="text" />
            <mat-error *ngIf="submitted && formContactInfo.controls['name'].errors?.['required']">
              {{ 'services-front.field_is_required' | translate }}
            </mat-error>
            <mat-error *ngIf="submitted && formContactInfo.controls['name'].errors?.['pattern']">
              To pole wymaga podania prawidłowego imienia
            </mat-error>
          </mat-form-field>
        
          <mat-form-field appearance="outline">
            <mat-label>{{ 'services-front.surname' | translate }}</mat-label>
            <input matInput formControlName="surname" required type="text" />
            <mat-error *ngIf="submitted && formContactInfo.controls['surname'].errors?.['required']">
              {{ 'services-front.field_is_required' | translate }}
            </mat-error>
            <mat-error *ngIf="submitted && formContactInfo.controls['surname'].errors?.['pattern']">
              To pole wymaga podania prawidłowego nazwiska
            </mat-error>
          </mat-form-field>
        </div>

        <div class="row grid-1">
          <div>
            <ngx-material-intl-tel-input
              class="phone-number"
              [fieldControl]="phone1"
              [preferredCountries]="['pl', 'de', 'us', 'gb']"
              [autoIpLookup]="true"
              [required]="true"
              [iconMakeCall]="false"
              [textLabels]="{
                mainLabel: 'Główny numer telefonu',
                codePlaceholder: 'Kod',
                searchPlaceholderLabel: 'Szukaj',
                noEntriesFoundLabel: 'Nie znaleziono krajów',
                nationalNumberLabel: 'Numer telefonu',
                hintLabel: '',
                invalidNumberError: 'Numer jest niepoprawny',
                requiredError: 'To pole jest wymagane'
              }">
            </ngx-material-intl-tel-input>
            <mat-error
              *ngIf="submitted && formContactInfo.controls['phoneNumber'].errors?.['required']"
              >{{ 'services-front.field_is_required' | translate }}</mat-error
            >
          </div>

          <div>
            <ngx-material-intl-tel-input
              class="phone-number"
              [fieldControl]="phone2"
              [autoIpLookup]="true"
              [iconMakeCall]="false"
              [numberValidation]="true"
              [preferredCountries]="['pl', 'de', 'us', 'gb']"
              [textLabels]="{
                mainLabel: 'Dodatkowy numer telefonu',
                codePlaceholder: 'Kod',
                searchPlaceholderLabel: 'Szukaj',
                noEntriesFoundLabel: 'Nie znaleziono krajów',
                nationalNumberLabel: 'Numer telefonu',
                hintLabel: '',
                invalidNumberError: 'Numer jest niepoprawny',
                requiredError: 'To pole jest wymagane'
              }">
            </ngx-material-intl-tel-input>
          </div>
        </div>
        <div class="row grid-2">
          <mat-form-field appearance="outline">
            <mat-label>{{ 'services-front.email_address' | translate }}</mat-label>
            <input matInput formControlName="email" required type="email" />
            <mat-error *ngIf="submitted && formContactInfo.controls['email'].errors?.['required']">
              {{ 'services-front.field_is_required' | translate }}
            </mat-error>
            <mat-error *ngIf="submitted && formContactInfo.controls['email'].errors?.['pattern']">
              To pole wymaga podania prawidłowego adresu email
            </mat-error>
          </mat-form-field>
        
          <mat-form-field appearance="outline">
            <mat-label>{{ 'services-front.contact_type' | translate }}</mat-label>
            <mat-select formControlName="type" required>
              <mat-option value="Main">Główny</mat-option>
              <mat-option value="Additional">Dodatkowy</mat-option>
            </mat-select>
            <mat-error *ngIf="submitted && formContactInfo.controls['type'].errors?.['required']">
              {{ 'services-front.field_is_required' | translate }}
            </mat-error>
          </mat-form-field>
        </div>
      </form>
    </div>

  </div>
  <div class="shared-modal-footer">
      <div class="shared-modal-footer-buttons-container">
        <button
          class="shared-modal-secondary-btn"
          [mat-dialog-close]="'cancel'">
          {{ 'services-front.cancel' | translate }}
        </button>
        <button
          class="shared-modal-primary-btn"
          *ngIf="tab === 'companyInfo'"
          (click)="getGusInfo()">
          {{ 'services-front.find_by_nip' | translate }}
        </button>
        <button
          class="shared-modal-secondary-btn"
          (click)="back()"
          *ngIf="tab === 'contact'">
          {{ 'services-front.back' | translate }}
        </button>
        <button
          class="shared-modal-primary-btn"
          (click)="next()"
          *ngIf="tab === 'companyInfo'">
          {{ 'services-front.next' | translate }}
        </button>
        <button
          class="shared-modal-primary-btn"
          *ngIf="tab === 'contact'"
          (click)="save()">
          {{ 'services-front.save' | translate }}
        </button>
      </div>
  </div>
</div>