import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from 'src/app/environments/environment';
import { PlatformsResponse } from 'dashboard-libs/models/platformsResponse.model';
@Injectable({
  providedIn: 'root',
})
export class ServicesService {
  constructor(private http: HttpClient) {}

  getServices() {
    return this.http.get(
      environment.API.apiHost + environment.API.apiPointInternal + `/workshops`
    );
  }

  getServiceById(id: string) {
    return this.http.get(
      environment.API.apiHost +
        environment.API.apiPointInternal +
        `/workshops/${id}`
    );
  }

  addServices(body: any) {
    return this.http.post(
      environment.API.apiHost + environment.API.apiPointInternal + `/workshops`,
      body
    );
  }

  updateBrands(body: any) {
    return this.http.put(
      environment.API.apiHost +
        environment.API.apiPointInternal +
        `/workshops/${body.id}` +
        `/brands`,
      { brandIds: body.brand }
    );
  }

  deleteService(id: any) {
    return this.http.delete(
      environment.API.apiHost +
        environment.API.apiPointInternal +
        `/workshops/${id}`
    );
  }

  updateService(body: any) {
    return this.http.put(
      environment.API.apiHost +
        environment.API.apiPointInternal +
        `/workshops/${body.id}`,
      body
    );
  }

  getServiceContacts() {
    return this.http.get(
      environment.API.apiHost +
        environment.API.apiPointInternal +
        `/workshop_contacts`
    );
  }

  getSupportedBrandsComponent() {
    return this.http.get(
      environment.API.apiHost + environment.API.apiPointInternal + `/brands`
    );
  }

  getDataFromIRI(iri: string) {
    return this.http.get(environment.API.apiHost + iri);
  }

  getServiceContactByUrl(url: string) {
    return this.http.get(environment.API.apiHost + url);
  }

  getServiceContactById(id: string) {
    return this.http.get(
      environment.API.apiHost +
        environment.API.apiPointInternal +
        `/workshop_contacts/` +
        id
    );
  }

  updateServiceContact(body: any) {
    return this.http.put(
      environment.API.apiHost +
        environment.API.apiPointInternal +
        `/workshop_contacts/${body.id}`,
      body
    );
  }

  addServiceContact(body: any) {
    return this.http.post(
      environment.API.apiHost +
        environment.API.apiPointInternal +
        `/workshop_contacts`,
      body
    );
  }

  deleteServiceContact(body: any) {
    return this.http.delete(
      environment.API.apiHost +
        environment.API.apiPointInternal +
        `/workshop_contacts/${body.id}`
    );
  }

  editServices(id: any, body: any) {
    return this.http.put(
      environment.API.apiHost +
        environment.API.apiPointInternal +
        `/workshops/` +
        id,
      body
    );
  }

  patchServices(id: any, body: any) {
    return this.http.patch(
      environment.API.apiHost +
        environment.API.apiPointInternal +
        `/workshops/` +
        id,
      body,
      {
        headers: {
          'Content-Type': 'application/merge-patch+json',
        },
      }
    );
  }

  getGusInfo(nip: string) {
    return this.http.get(
      environment.API.apiHost +
        environment.API.apiPointInternal +
        `/gus/data-by-nip/` +
        nip +
        `/workshop`
    );
  }

  getBillingPeriods() {
    return this.http.get(
      environment.API.apiHost +
        environment.API.apiPointInternal +
        `/billing/periods`
    );
  }

  public getPlatformsAsAdmin() : Observable<PlatformsResponse> {
    return this.http.get<PlatformsResponse>(`${environment.API.apiHost+environment.API.apiPointInternal}/admin/platforms`);
  }

  public reassignOrderToWorkshop(orderId: string, workshopId: string) : Observable<any> {
    return this.http.put(`${environment.API.apiHost+environment.API.apiPointInternal}/orders/${orderId}/reassign-workshop/${workshopId}`, {});
  }
}
