import {
  Component,
  ElementRef,
  Inject,
  QueryList,
  ViewChildren,
} from '@angular/core';
import { DeviceService } from '../../services/device.service';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

import { ServicesService } from 'src/app/pages/services/services/services.service';
import { MatCheckboxChange } from '@angular/material/checkbox';
import { faXmark } from '@fortawesome/free-solid-svg-icons';

@Component({
  selector: 'app-supported-brands',
  templateUrl: './supported-brands.component.html',
  styleUrls: ['./supported-brands.component.scss'],
})
export class SupportedBrandsComponent {

  public faXmark = faXmark;

  public addedTypes: any = [];
  public supportedBrands: any;
  checked: any = false;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    private dialogRef: MatDialogRef<SupportedBrandsComponent>,
    private deviceService: DeviceService,
    private servicesService: ServicesService
  ) {
    this.getSupportedBrandsComponent();

    this.deviceService.getServiceDeviceTypes().subscribe(res => {});
  }
  
  @ViewChildren('checkboxes') checkboxes: QueryList<ElementRef>;

  add(): void {
    let body: any = [];

    body['id'] = this.data.service.id;
    body['brand'] = [];
    this.addedTypes.forEach((brand: any) => {
      body['brand'].push(brand.id);
    });

    this.servicesService.updateBrands(body).subscribe(res => {
      this.dialogRef.close();
    });
  }

  getSupportedBrandsComponent(): void {
    this.servicesService.getSupportedBrandsComponent().subscribe((res: any) => {
      this.supportedBrands = res['hydra:member'];
    });
  }

  setAll() {
    this.supportedBrands.forEach((supportedBrand: any) => {
      if (!this.addedTypes.includes(supportedBrand['@id'])) {
        this.addedTypes.push(supportedBrand['id']);
      }
    });
  }

  uncheckAll() {
    if (this.checked) {
      this.checkboxes.forEach(element => {
        element.nativeElement.checked = true;
      });
    } else {
      this.checkboxes.forEach(element => {
        element.nativeElement.checked = false;
      });
    }
  }

  toggleAll(event: MatCheckboxChange) {
    if (event.checked) {
      this.supportedBrands.forEach((row: any) => {
        this.addedTypes.push(row);
      });
    } else {
      this.addedTypes.length = 0;
      this.data.service.brand = [];
    }
  }

  isChecked() {
    return this.addedTypes?.length === this.supportedBrands?.length;
  }

  isIndeterminate() {
    return this.addedTypes.length > 0 && !this.isChecked();
  }

  toggle(item: any, event: MatCheckboxChange) {
    if (event.checked) {
      this.addedTypes.push(item);
    } else {
      const index = this.addedTypes.indexOf(item);
      if (index >= 0) {
        this.addedTypes.splice(index, 1);
      }
    }
  }

  exists(item: any) {
    if (
      this.data.service.brand.some(
        (brand: { '@id': any }) => brand['@id'] === item['@id']
      )
    ) {
      return true;
    }
    return this.addedTypes.indexOf(item) > -1;
  }

  isBrandSelected(brandId: any): boolean {
    return this.data.service.brand.some(
      (brand: { '@id': any }) => brand['@id'] === brandId
    );
  }
}
