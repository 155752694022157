<div class="system-settings-container">
    <div class="system-settings-header-container">
        <h1>Ustawienia systemowe</h1>
    </div>

    <div class="system-settings-content-container">
        @for (systemSetting of systemSettings; track $index) {
            <div class="system-setting-item">
                <div class="system-setting-item-inner-content-container">
                    <b>{{systemSetting.name | dashIfEmpty}}</b>
                </div>
                <div class="system-setting-item-inner-content-container">
                    <b>Opis</b>
                    <p style="max-width: 300px;" 
                    [matTooltip]="systemSetting.description.length > 20 ? systemSetting.description : ''"
                    >{{(systemSetting.description.length > 20 ? systemSetting.description.substring(0,20) + '...' : systemSetting.description) | dashIfEmpty}}</p>
                </div>
                <div class="system-setting-item-inner-content-container">
                    <b>Data utworzenia</b>
                    <p>{{systemSetting.createdAt | date: 'YYYY-MM-dd | HH:mm' | dashIfEmpty}}</p>
                </div>
                <div class="system-setting-item-inner-content-container">
                    <b>Zaktualizowano przez</b>
                    <p>{{systemSetting.lastUpdateBy | dashIfEmpty}}</p>
                </div>
                <div class="system-setting-item-inner-content-container">
                    <b>Data aktualizacji</b>
                    <p>{{systemSetting.updatedAt | date: 'YYYY-MM-dd | HH:mm' | dashIfEmpty}}</p>
                </div>
                <div class="system-setting-item-inner-content-container filters-inputs">
                    <mat-form-field appearance="outline">
                        <mat-label>{{systemSetting.name}}</mat-label>
                        <mat-select [value]="systemSetting.settingValue" (selectionChange)="onSettingSelectionChange(systemSetting.id, systemSetting.settingKey, $event.value)">
                            <mat-option value="yes">Yes</mat-option>
                            <mat-option value="no">No</mat-option>
                        </mat-select>
                    </mat-form-field>
                </div>
            </div>
        }
    </div>
</div>