import { Component } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { AddInvoiceComponent } from '../../../shared/modals/add-invoice/add-invoice.component';
import { EditMarginComponent } from '../../../shared/modals/edit-margin/edit-margin.component';
import { OrdersService } from '../../orders/services/orders.service';
import { ActivatedRoute } from '@angular/router';
import FileSaver from 'file-saver';
import { MatSnackBar } from '@angular/material/snack-bar';
import { BreadcrumbService } from 'dashboard-libs/services/breadcrumb.service';
@Component({
  selector: 'app-settlement-item',
  templateUrl: './settlement-item.component.html',
  styleUrls: ['./settlement-item.component.scss'],
})
export class SettlementItemComponent {
  settlement: any;
  public marginId!: string;
  public margin: any;
  public order: any;

  constructor(
    private dialog: MatDialog,
    private ordersService: OrdersService,
    private route: ActivatedRoute,
    private _snackBar: MatSnackBar,
    private breadcrumbService: BreadcrumbService
  ) {}

  ngOnInit(): void {
    this.ordersService
      .getOrderById(this.route.snapshot.params['id'])
      .subscribe(res => {
        this.order = res;
        this.breadcrumbService.setBreadCrumbDetails(this.order?.faultNumber);
        this.ordersService
          .getDataFromIRI(this.order.billingRevenue)
          .subscribe(res => {
            this.order.billingRevenue = res;
          });
      });
  }

  addInvoice(): void {
    const dialogRef = this.dialog.open(AddInvoiceComponent, {
      disableClose: true,
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result === 'show') {
      }
    });
  }

  openMargin(): void {
    const dialogRef = this.dialog.open(EditMarginComponent, {
      data: { margin: this.margin },
    });
  }

  downloadFv() {
    this._snackBar.open('Pobieranie rozpoczęte', 'Zamknij', {
      duration: 1200,
    });
    return this.ordersService
      .downloadbillingRevenueInvoice(this.order?.id)
      .subscribe(
        response => {
          const data = new Blob([response.body as any], {
            type: 'application/pdf;charset=utf-8',
          });
          const contentDisposition = response.headers.get(
            'Content-Disposition'
          );
          let filename = contentDisposition
            .split(';')[1]
            .split('filename')[1]
            .split('=')[1]
            .trim();
          FileSaver.saveAs(data, filename);
        },
        error => {
          this._snackBar.open('Błąd pobierania', 'Zamknij', {
            duration: 1200,
          });
        }
      );
  }
}
