<div>
  <div class="tabs-container">
    <div class="tabs-container-inner">
      <div
        class="tab"
        (click)="tab = 'details'"
        [class.active]="tab == 'details'">
        {{ 'orders-front.item.tabs.order_details' | translate }}
      </div>
      <div
        class="tab"
        (click)="tab = 'documents'"
        [class.active]="tab == 'documents'"
        *ngIf="Auth.isViewAllowed(['ROLE_ORDER_DOCUMENTS_VIEW', 'ROLE_ADMIN'])">
        {{ 'orders-front.item.tabs.documents' | translate }}
      </div>
      <div
        class="tab"
        (click)="tab = 'costs'"
        [class.active]="tab == 'costs'"
        *ngIf="Auth.isViewAllowed(['ROLE_BILLINGS_VIEW', 'ROLE_ADMIN'])">
        {{ 'orders-front.item.tabs.costs' | translate }}
      </div>
      <div
        class="tab"
        (click)="tab = 'packages'"
        [class.active]="tab == 'packages'"
        *ngIf="Auth.isViewAllowed(['ROLE_SHIPMENTS_VIEW', 'ROLE_ADMIN'])">
        {{ 'orders-front.item.tabs.packages' | translate }}
      </div>
      <!-- <div
        class="tab"
        (click)="tab = 'history'"
        [class.active]="tab == 'history'"
        *ngIf="Auth.isViewAllowed(['ROLE_HISTORY_DETAILS_VIEW', 'ROLE_ADMIN'])">
        {{ 'orders-front.item.tabs.history' | translate }}
      </div> -->
    </div>
    <div class="info-container">
      <div class="details-info-container">
        <p>
          <span
            >Czas obsługi:
            <b
              [matTooltip]="
                order
                  ? 'Data zgłoszenia: ' +
                    (order.incident.eventDate | date: 'yyyy-MM-dd | HH:mm')
                  : ''
              "
              >{{ orderProcessingTime }}
              {{ orderProcessingTime === 1 ? 'dzień' : 'dni' }}</b
            ></span
          >
          |
          <span
            [matTooltip]="
              order
                ? 'Data przyjęcia: ' +
                  (order.createdAt | date: 'yyyy-MM-dd | HH:mm')
                : ''
            "
            >Czas likwidacji:
            <b [style.color]="orderLiquidationTime >= 20 ? 'red' : ''"
              >{{ orderLiquidationTime }}
              {{ orderLiquidationTime === 1 ? 'dzień' : 'dni' }}</b
            ></span
          >
        </p>
      </div>
      <div class="actions">
        <button mat-button [matMenuTriggerFor]="menu" mat-stroked-button>
          <mat-icon>more_vert</mat-icon>
        </button>
        <mat-menu #menu="matMenu">
          <!-- <button mat-menu-item
            *ngIf="Auth.isViewAllowed(['ROLE_ORDER_DETAILS_EDIT', 'ROLE_ADMIN'])"><mat-icon>edit</mat-icon>Zmień
            serwis</button> -->
          <button
            mat-menu-item
            (click)="cancel()"
            *ngIf="
              Auth.isViewAllowed(['ROLE_ORDER_DETAILS_EDIT', 'ROLE_ADMIN']) &&
              (order?.state == 'new' ||
                order?.state == 'data_error' ||
                order?.state == 'idle' ||
                order?.state == 'pending' ||
                order?.state == 'assigned' ||
                order?.state == 'no_workshop')
            ">
            <mat-icon>cancel</mat-icon>Anuluj zlecenie
          </button>
          <button mat-menu-item (click)="refresh()">
            <mat-icon>refresh</mat-icon>Odśwież
          </button>
          <button
            mat-menu-item
            (click)="changeService()"
            *ngIf="
              Auth.isViewAllowed(['ROLE_ORDER_DETAILS_EDIT', 'ROLE_ADMIN'])
            ">
            <mat-icon>change_circle</mat-icon>Zmień serwis
          </button>
          <button
            mat-menu-item
            (click)="updateData()"
            *ngIf="
              Auth.isViewAllowed(['ROLE_ORDER_DETAILS_EDIT', 'ROLE_ADMIN'])
            ">
            <mat-icon>update</mat-icon>Zaktualizuj dane
          </button>
          <button
            mat-menu-item
            (click)="updatePolicy()"
            *ngIf="Auth.isViewAllowed(['ROLE_ADMIN'])">
            <mat-icon>update</mat-icon>Zaktualizuj polisę
          </button>

          <button mat-menu-item (click)="addPreAuth()">
            <mat-icon>approval</mat-icon>Dodaj preautoryzację
          </button>

          <button
            mat-menu-item
            (click)="recalculate()"
            *ngIf="
              (Auth.isViewAllowed(['ROLE_ORDER_DETAILS_EDIT', 'ROLE_ADMIN']) &&
                order?.state != 'finished' &&
                order?.state != 'settlement_rejected' &&
                order?.state != 'verification_failed' &&
                order?.state != 'client_rejected' &&
                order?.state != 'client_accepted' &&
                order?.state != 'cancelled' &&
                order?.state != 'data_error' &&
                order?.state != 'settled' &&
                order?.state != 'invoiced' &&
                order?.state != 'verified' &&
                order?.state != 'no_workshop') ||
              (Auth.isViewAllowed(['ROLE_ADMIN']) &&
                order?.state != 'finished' &&
                order?.state != 'settlement_rejected' &&
                order?.state != 'verification_failed' &&
                order?.state != 'client_rejected' &&
                order?.state != 'client_accepted' &&
                order?.state != 'cancelled' &&
                order?.state != 'data_error')
            ">
            <mat-icon>calculate</mat-icon>Przelicz koszty
          </button>
          <button
            mat-menu-item
            (click)="archiveOrder()"
            *ngIf="Auth.isViewAllowed(['ROLE_ADMIN'])">
            <mat-icon>archive</mat-icon>Archiwizuj
          </button>
          <button
            mat-menu-item
            (click)="openLogsOfOrder()"
            *ngIf="Auth.isViewAllowed(['ROLE_ADMIN'])">
            <mat-icon>logo_dev</mat-icon>Logi zlecenia
          </button>
          @if(true || (Auth.isViewAllowed(['ROLE_ADMIN']) && order?.state == 'assigned')){
            <button
              mat-menu-item
              (click)="submitOrder()"
            >
              <mat-icon>done</mat-icon>Potwierdź zlecenie
            </button> 
          }
          <hr />
          <button
            mat-menu-item
            (click)="beginRepair()"
            *ngIf="
              Auth.isViewAllowed(['ROLE_ADMIN']) && order?.state == 'assigned'
            ">
            <mat-icon>airline_stops</mat-icon>Rozpoczęcie naprawy
          </button>
          <button
            mat-menu-item
            (click)="endRepair()"
            *ngIf="
              Auth.isViewAllowed(['ROLE_ADMIN']) && order?.state == 'in_repair'
            ">
            <mat-icon>airline_stops</mat-icon>Zakonczenie naprawy
          </button>
          <button
            mat-menu-item
            (click)="finishOrder()"
            *ngIf="
              Auth.isViewAllowed(['ROLE_ADMIN']) && order?.state == 'repaired'
            ">
            <mat-icon>airline_stops</mat-icon>Zakończ zlecenie
          </button>
          <button
            mat-menu-item
            (click)="assignOrder()"
            *ngIf="
              Auth.isViewAllowed(['ROLE_ADMIN']) && order?.state == 'pending'
            ">
            <mat-icon>airline_stops</mat-icon>Przypisz zlecenie
          </button>
          <button
            mat-menu-item
            (click)="editCosts()"
            *ngIf="Auth.isViewAllowed(['ROLE_ADMIN'])">
            <mat-icon>edit</mat-icon>Edytuj koszty
          </button>
          <button
            mat-menu-item
            (click)="editParts()"
            *ngIf="Auth.isViewAllowed(['ROLE_ADMIN'])">
            <mat-icon>edit</mat-icon>Edytuj części
          </button>
        </mat-menu>
      </div>
    </div>
  </div>
</div>
<div class="order-details-wrapper">
  <div class="order-details-container">
    <!-- <app-order-info [order]="order"></app-order-info> -->
    <app-order-info-overview
      [policy]="policy"
      [order]="order"
      [history]="history" />
    @if (tab === 'details' && order) {
      <app-order-progress-stepper [order]="order" />
      <div class="order-details">
        <div class="order-details-column">
          <app-order-info-details title="Sprzęt" [isOpen]="true">
            <div class="shared-details-content-container">
              <div class="shared-details-content-grid grid-col-2">
                <div>
                  <h6 class="shared-details-content-title">Producent</h6>
                  <p class="shared-details-content-desc">
                    {{ order?.device?.producer | dashIfEmpty }}
                  </p>
                </div>
                <div>
                  <h6 class="shared-details-content-title">Numer fabryczny</h6>
                  <p class="shared-details-content-desc">
                    {{ order?.device?.serialNumber | dashIfEmpty }}
                  </p>
                </div>
                <div>
                  <h6 class="shared-details-content-title">Model</h6>
                  <p class="shared-details-content-desc">
                    {{ order?.device?.model | dashIfEmpty }}
                  </p>
                </div>
                <div>
                  <h6 class="shared-details-content-title">
                    Asortyment / Kod asortymentu
                  </h6>
                  <p class="shared-details-content-desc">
                    {{ order?.device?.assortmentGroup | dashIfEmpty }} -
                    {{ order?.device?.type?.code | dashIfEmpty }}
                  </p>
                </div>
              </div>
            </div>
          </app-order-info-details>
          <app-order-info-details title="Usterka" [isOpen]="true">
            <div class="shared-details-content-container">
              <div>
                <h6 class="shared-details-content-title">Opis</h6>
                <p class="shared-details-content-desc">
                  {{ order.incident.description | dashIfEmpty }}
                </p>
              </div>
              <div>
                <h6 class="shared-details-content-title">
                  Opis stanu / akcesoria
                </h6>
                <p class="shared-details-content-desc">
                  {{ order.incident.accessoriesState | dashIfEmpty }}
                </p>
              </div>
              <div>
                <h6 class="shared-details-content-title">Opis opakowania</h6>
                <p class="shared-details-content-desc">
                  {{ order.incident.packaging | dashIfEmpty }}
                </p>
              </div>
            </div>
          </app-order-info-details>
          <app-order-info-details title="Diagnoza i opis" [isOpen]="true">
            <div class="shared-details-content-container">
              <div>
                <h6 class="shared-details-content-title">Ekspertyza</h6>
                <p class="shared-details-content-desc">
                  {{
                    preauthorizationData && preauthorizationData.diagnosis
                      | dashIfEmpty
                  }}
                </p>
                <!-- HERE jezeli diagnoza w preautoryzacji uwzgledniona-->
              </div>
              <div>
                <h6 class="shared-details-content-title">Powód uszkodzenia</h6>
                <p class="shared-details-content-desc">
                  {{ order.orderExecution.causesOfFailure | dashIfEmpty }}
                </p>
              </div>
              <div>
                <h6 class="shared-details-content-title">
                  Diagnoza / Opis naprawy
                </h6>
                <p class="shared-details-content-desc">
                  {{ order.orderExecution.diagnosis }}
                  {{ order.orderExecution.repairDescription }}
                </p>
              </div>
            </div>
          </app-order-info-details>
          <app-order-info-details title="Ubezpieczony" [isOpen]="true">
            <div header>
              <button (click)="toggleUserDetailsVisibility($event)" mat-button>
                <fa-icon [icon]="!isUserDetailsVisible ? faEye : faEyeSlash" />
                {{ !isUserDetailsVisible ? 'Pokaż' : 'Ukryj' }}
              </button>
            </div>
            <div class="shared-details-content-container">
              <div class="shared-details-content-grid grid-col-2">
                <div>
                  <h6 class="shared-details-content-title">
                    Rodzaj ubezpieczenia
                  </h6>
                  <p class="shared-details-content-desc">
                    {{
                      order.customer.contactInformation.taxType
                        | translate
                        | maskText: isUserDetailsVisible
                    }}
                  </p>
                </div>
                <div>
                  <h6 class="shared-details-content-title">Imię i nazwisko</h6>
                  <p class="shared-details-content-desc">
                    {{
                      (order.customer.firstName | dashIfEmpty) +
                        ' ' +
                        (order.customer.lastName | dashIfEmpty)
                        | maskText: isUserDetailsVisible
                    }}
                  </p>
                </div>
                <div>
                  <h6 class="shared-details-content-title">Nazwa / Firma</h6>
                  <p class="shared-details-content-desc">
                    {{
                      order.customer.contactInformation.companyName
                        | dashIfEmpty
                        | maskText: isUserDetailsVisible
                    }}
                  </p>
                </div>
                <div>
                  <h6 class="shared-details-content-title">NIP</h6>
                  <p class="shared-details-content-desc">
                    {{
                      order.customer.contactInformation.nip
                        | dashIfEmpty
                        | maskText: isUserDetailsVisible
                    }}
                  </p>
                </div>
              </div>
              <div>
                <h6 class="shared-details-content-title">Adres</h6>
                <p class="shared-details-content-desc">
                  @if (isUserDetailsVisible) {
                    {{ order.customer.address.postalCode | dashIfEmpty }}
                    {{ order.customer.address.city | dashIfEmpty }}
                    {{
                      'ul. ' + order.customer.address.streetName | dashIfEmpty
                    }}
                    {{ order.customer.address.buildingNumber | dashIfEmpty }}
                    @if (order.customer.address.apartmentNumber) {
                      /
                      {{
                        order?.customer?.address?.apartmentNumber | dashIfEmpty
                      }}
                    }
                  } @else {
                    <span>******</span>
                  }
                </p>
              </div>
              <div class="shared-details-content-grid grid-col-2">
                <div>
                  <h6 class="shared-details-content-title">Telefon</h6>
                  <p class="shared-details-content-desc">
                    {{
                      order.customer.contactInformation.mobileNumber
                        | dashIfEmpty
                        | maskText: isUserDetailsVisible
                    }}
                  </p>
                </div>
                <div>
                  <h6 class="shared-details-content-title">Email</h6>
                  <p class="shared-details-content-desc">
                    {{
                      order.customer.contactInformation.email
                        | dashIfEmpty
                        | maskText: isUserDetailsVisible
                    }}
                  </p>
                </div>
              </div>
            </div>
          </app-order-info-details>
          <app-order-info-details title="Gwarancja" [isOpen]="true">
            <div class="shared-details-content-container">
              <div class="shared-details-content-grid grid-col-2">
                <div>
                  <h6 class="shared-details-content-title">Data zakupu</h6>
                  <p class="shared-details-content-desc">
                    {{
                      order.device.purchaseDate
                        | date: 'YYYY-MM-dd'
                        | dashIfEmpty
                    }}
                  </p>
                </div>
                <div>
                  <h6 class="shared-details-content-title">Numer gwarancji</h6>
                  <p class="shared-details-content-desc">
                    {{ order.warranty.number | dashIfEmpty }}
                  </p>
                </div>
                <div>
                  <h6 class="shared-details-content-title">Numer faktury</h6>
                  <p class="shared-details-content-desc">
                    {{ order.warranty.invoiceNo | dashIfEmpty }}
                  </p>
                </div>
                <div>
                  <h6 class="shared-details-content-title">
                    Kwota zakupu / Wartość
                  </h6>
                  <p class="shared-details-content-desc">
                    {{
                      order.warranty.price && order.warranty.price + 'zł'
                        | dashIfEmpty
                    }}
                  </p>
                </div>
              </div>
            </div>
          </app-order-info-details>
          @if (order.orderExecution.repairUnit) {
            <app-order-info-details title="Punkt serwisowy" [isOpen]="true">
              <div class="shared-details-content-container">
                <div>
                  <h6 class="shared-details-content-title">Nazwa serwisu</h6>
                  <p class="shared-details-content-desc">
                    {{ order.orderExecution.repairUnit.name | dashIfEmpty }}
                  </p>
                </div>
                <div class="shared-details-content-grid grid-col-2">
                  <div>
                    <h6 class="shared-details-content-title">Adres serwisu</h6>
                    <p class="shared-details-content-desc">
                      @if (order.orderExecution.repairUnit.address) {
                        <span>
                          {{
                            order.orderExecution.repairUnit.address.streetName +
                              ' ' +
                              (order?.orderExecution?.repairUnit?.address
                                ?.buildingNumber
                                ? order?.orderExecution?.repairUnit?.address
                                    ?.buildingNumber +
                                  (order?.orderExecution?.repairUnit?.address
                                    ?.apartmentNumber
                                    ? '/' +
                                      order?.orderExecution?.repairUnit?.address
                                        ?.apartmentNumber
                                    : '')
                                : '') +
                              ' ' | dashIfEmpty
                          }}
                        </span>
                      }
                      <br />
                      @if (order.orderExecution.repairUnit.address) {
                        <span>
                          {{
                            order?.orderExecution?.repairUnit?.address
                              ?.postalCode +
                              ' ' +
                              order?.orderExecution?.repairUnit?.address?.city
                              | dashIfEmpty
                          }}
                        </span>
                      }
                      @if (!order.orderExecution.repairUnit.address) {
                        <span>-</span>
                      }
                    </p>
                  </div>
                  <div>
                    @for (
                      contactDetail of order.orderExecution.repairUnit
                        .contactDetails;
                      track $index;
                      let i = $index
                    ) {
                      <div>
                        <h6 class="shared-details-content-title">Email</h6>
                        <p class="shared-details-content-desc">
                          {{
                            order.orderExecution.repairUnit.contactDetails[i]
                              .email
                          }}
                        </p>
                      </div>
                    }
                  </div>
                </div>
                @for (
                  contactDetail of order.orderExecution.repairUnit
                    .contactDetails;
                  track $index;
                  let i = $index
                ) {
                  <div class="shared-details-content-grid grid-col-2">
                    <div>
                      <h6 class="shared-details-content-title">Telefon</h6>
                      <p class="shared-details-content-desc">
                        {{
                          order.orderExecution.repairUnit.contactDetails[i]
                            .phoneNumber | dashIfEmpty
                        }}
                      </p>
                    </div>
                    <div>
                      <h6 class="shared-details-content-title">
                        Telefon komórkowy
                      </h6>
                      <p class="shared-details-content-desc">
                        {{
                          order.orderExecution.repairUnit.contactDetails[i]
                            .mobileNumber | dashIfEmpty
                        }}
                      </p>
                    </div>
                  </div>
                }
              </div>
            </app-order-info-details>
          }
        </div>
        <div class="order-details-column">
          @if (preauthorizationData) {
            <app-order-info-status
              [preauthorizationData]="preauthorizationData" />
          }
          <app-order-info-comments
            [order]="order"
            [comments]="comments"
            (refetchComments)="fetchOrderComments()" />
          <app-order-info-timeline [timelineEvents]="timelineEvents" />
        </div>
      </div>
    }

    @if (tab === 'documents') {
      <app-order-documents [data]="order" />
    }
    @if (tab === 'costs') {
      <app-order-costs [order]="order" />
    }
    @if (tab === 'packages') {
      <app-order-packages [order]="order" />
    }
    <!-- @if(tab === 'history'){
      <app-order-repair
        [history]="history"
      />
    } -->
    <!-- <app-order-details
      *ngIf="tab === 'details'"
      [order]="order"
      [history]="history"/> 
    -->
  </div>
</div>
