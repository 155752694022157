import { Component, OnInit } from '@angular/core';
import { SettingsService } from '../settings/services/settings.service';
import { SystemSettings, SystemSettingsHydraMember } from 'dashboard-libs/models/systemSettings.model';

@Component({
  selector: 'app-system-settings',
  templateUrl: './system-settings.component.html',
  styleUrl: './system-settings.component.scss',
})
export class SystemSettingsComponent implements OnInit {

  systemSettings: SystemSettingsHydraMember[] = [];

  constructor(private settingsService: SettingsService) {

  }

  ngOnInit(): void {
    this.fetchSystemSettings();
  }

  public onSettingSelectionChange(systemSettingId: number, systemSettingKey: string, systemSettingValue: any) : void {
    this.settingsService.updateSystemSetting(systemSettingId, systemSettingKey, systemSettingValue).subscribe({
      next: (resData) => {
        
      },
      error: (error) => {
        console.error(error)
      }
    })
  }

  public fetchSystemSettings() : void {
    this.settingsService.getSystemSettings().subscribe({
      next: (systemSettingsData : SystemSettings) => {
        this.systemSettings = systemSettingsData['hydra:member'];
        console.log(this.systemSettings);
      },
      error: (error) => {
        console.error(error);
      }
    })
  }
}
