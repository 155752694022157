<div class="shared-modal-container">
  <div class="shared-modal-header" mat-dialog-draggable-title>
    <div class="shared-modal-header-content">
      <h6>{{ 'services-front.edit_service' | translate }}</h6>
      <p class="title-desc">
        {{ 'services-front.fill_in_the_information_below' | translate }}
      </p>
    </div>
    <button class="shared-modal-close-btn" mat-dialog-close="cancel">
        <fa-icon [icon]="faXmark"/>
    </button>
  </div>
  <div class="shared-modal-body">
      <form [formGroup]="formCompanyInfo" class="filters-inputs">
        <div class="row grid-1">
          <mat-form-field appearance="outline">
            <mat-label>{{ 'services-front.company_name' | translate }}</mat-label>
            <input matInput formControlName="name" type="text" required />
            @if(companyNext && formCompanyInfo.controls['name'].errors?.['required']){
              <mat-error>
                {{ 'services-front.field_is_required' | translate }}
              </mat-error>
            }
          </mat-form-field>
        </div> 
        
        <div class="row grid-2">
          <mat-form-field appearance="outline">
            <mat-label>{{ 'services-front.regon' | translate }}</mat-label>
            <input
              matInput
              formControlName="regon"
              type="text"
              mask="000000000"
              required
              placeholder=" "
            />
            <mat-error
              *ngIf="companyNext && formCompanyInfo.controls['regon'].errors?.['required']"
            >
              {{ 'services-front.field_is_required' | translate }}
            </mat-error>
            <mat-error
              *ngIf="companyNext && formCompanyInfo.controls['regon'].errors?.['pattern']"
            >
              To pole wymaga podania 9 cyfrowego numeru regon
            </mat-error>
          </mat-form-field>

          <mat-form-field appearance="outline" class="full-width">
            <mat-label>{{ 'services-front.nip' | translate }}</mat-label>
            <input
              matInput
              formControlName="nip"
              mask="0000000000"
              type="text"
              required
              placeholder=" "
            />
            <mat-icon
              matPrefix
              class="icon-inside-input"
              (click)="getGusInfo()"
              matTooltip="{{ 'services-front.find_by_nip' | translate }}"
              >search</mat-icon
            >
            <mat-error
              *ngIf="companyNext && formCompanyInfo.controls['nip']?.errors?.['required']"
            >
              {{ 'services-front.field_is_required' | translate }}
            </mat-error>
            <mat-error *ngIf="gusError">
              <p *ngIf="gusError == 404">
                Nie znaleziono podmiotu o takim numerze NIP
              </p>
              <p *ngIf="gusError == 400">Błędny numer NIP</p>
              <p *ngIf="gusError == 'other'"></p>
            </mat-error>
            <mat-error
              *ngIf="
                !(
                  companyNext && formCompanyInfo.controls['nip'].errors?.['required']
                ) &&
                companyNext &&
                formCompanyInfo.controls['nip'].errors?.['pattern'] ||
                !(
                  companyNext && formCompanyInfo.controls['nip'].errors?.['required']
                ) &&
                gusInfo
              "
            >
              To pole wymaga podania 10 cyfrowego numeru nip
            </mat-error>
          </mat-form-field>
        </div>

        <div class="row grid-2">
          <mat-form-field appearance="outline" class="full-width">
            <mat-label>{{ 'services-front.bank_account_number(IBAN)' | translate }}</mat-label>
            <input
              matInput
              formControlName="bankNumber"
              required
              mask="SS00-0000-0000-0000-0000-0000-0000"
              type="text"
              placeholder="AA00-0000-0000-0000-0000-0000-0000"
            />
            <mat-error
              *ngIf="companyNext && formCompanyInfo.controls['bankNumber'].errors?.['required']"
            >
              {{ 'services-front.field_is_required' | translate }}
            </mat-error>
            <mat-error
              *ngIf="
                (companyNext && formCompanyInfo.controls['bankNumber'].errors?.['pattern']) ||
                (companyNext && formCompanyInfo.controls['bankNumber'].errors?.['mask'])
              "
            >
              To pole wymaga podania prawidłowego nr konta
            </mat-error>
          </mat-form-field>

          <mat-form-field appearance="outline" class="full-width">
            <mat-label>{{ 'services-front.agreement_number' | translate }}</mat-label>
            <input
              matInput
              formControlName="number"
              type="text"
              required
              placeholder=" "
            />
            <mat-error
              *ngIf="companyNext && formCompanyInfo.controls['number'].errors?.['required']"
            >
              {{ 'services-front.field_is_required' | translate }}
            </mat-error>
          </mat-form-field>
        </div>
      </form>
  </div>
  <div class="shared-modal-footer">
      <div class="shared-modal-footer-buttons-container">
          <button class="shared-modal-secondary-btn" mat-dialog-close="cancel">
            {{ 'services-front.cancel' | translate }}
          </button>
          <button class="shared-modal-primary-btn" mat-dialog-close="success" (click)="save()">
            {{ 'services-front.save' | translate }}
          </button>
      </div>
  </div>
</div>
<!-- 
<h1 mat-dialog-title class="title">
  {{ 'services-front.edit_service' | translate }}
</h1>
<p class="title-desc">
  {{ 'services-front.fill_in_the_information_below' | translate }}
</p>
<hr class="custom-hr" />

<div mat-dialog-content>
  <div [hidden]="tab !== 'companyInfo'">
    <form [formGroup]="formCompanyInfo">
      <div class="did-floating-label-content col-2 mt-15">
        <input
          class="did-floating-input"
          formControlName="name"
          type="text"
          placeholder=" "
          required />
        <label class="did-floating-label">{{
          'services-front.company_name' | translate
        }}</label>
        <mat-error
          *ngIf="
            companyNext && formCompanyInfo.controls['name'].errors?.['required']
          "
          >{{ 'services-front.field_is_required' | translate }}</mat-error
        >
      </div>
      <div class="flex first-flex">
        <div class="did-floating-label-content mr-10 mt-15">
          <input
            class="did-floating-input"
            formControlName="regon"
            type="text"
            mask="000000000"
            required
            placeholder=" " />
          <label class="did-floating-label">{{
            'services-front.regon' | translate
          }}</label>
          <mat-error
            *ngIf="
              companyNext &&
              formCompanyInfo.controls['regon'].errors?.['required']
            "
            >{{ 'services-front.field_is_required' | translate }}</mat-error
          >
          <mat-error
            *ngIf="
              companyNext &&
              formCompanyInfo.controls['regon'].errors?.['pattern']
            "
            >To pole wymaga podania 9 cyfrowego numeru regon</mat-error
          >
        </div>

        <div class="did-floating-label-content mt-15">
          <input
            class="did-floating-input"
            formControlName="nip"
            mask="0000000000"
            type="text"
            required
            placeholder=" " />

          <label class="did-floating-label">{{
            'services-front.nip' | translate
          }}</label>
          <mat-icon
            matPrefix
            class="icon-inside-input"
            (click)="getGusInfo()"
            matTooltip="{{ 'services-front.find_by_nip' | translate }}"
            >search</mat-icon
          >
          <mat-error
            *ngIf="
              companyNext &&
              formCompanyInfo.controls['nip']?.errors?.['required']
            "
            >{{ 'services-front.field_is_required' | translate }}</mat-error
          >
          <mat-error *ngIf="gusError">
            <p *ngIf="gusError == 404">
              Nie znaleziono podmiotu o takim numerze NIP
            </p>
            <p *ngIf="gusError == 400">Błędny numer NIP</p>
            <p *ngIf="gusError == 'other'"></p>
          </mat-error>
          <mat-error
            *ngIf="
              (!(
                companyNext &&
                formCompanyInfo.controls['nip'].errors?.['required']
              ) &&
                companyNext &&
                formCompanyInfo.controls['nip'].errors?.['pattern']) ||
              (!(
                companyNext &&
                formCompanyInfo.controls['nip'].errors?.['required']
              ) &&
                gusInfo)
            "
            >To pole wymaga podania 10 cyfrowego numeru nip
          </mat-error>
        </div>
      </div>
      <div class="flex">
        <div class="did-floating-label-content mr-10 mt-15">
          <input
            class="did-floating-input"
            formControlName="bankNumber"
            required
            mask="SS00-0000-0000-0000-0000-0000-0000"
            type="text"
            placeholder="AA00-0000-0000-0000-0000-0000-0000"
            oninput="this.value = this.value.toUpperCase()" />
          <label class="did-floating-label did-floating-label-focus">{{
            'services-front.bank_account_number(IBAN)' | translate
          }}</label>
          <mat-error
            *ngIf="
              companyNext &&
              formCompanyInfo.controls['bankNumber'].errors?.['required']
            "
            >{{ 'services-front.field_is_required' | translate }}</mat-error
          >
          <mat-error
            *ngIf="
              (companyNext &&
                formCompanyInfo.controls['bankNumber'].errors?.['pattern']) ||
              (companyNext &&
                formCompanyInfo.controls['bankNumber'].errors?.['mask'])
            "
            >To pole wymaga podania prawidłowego nr konta</mat-error
          >
        </div>
        <div class="did-floating-label-content mt-15">
          <input
            class="did-floating-input"
            formControlName="number"
            type="text"
            required
            placeholder=" " />
          <label class="did-floating-label">{{
            'services-front.agreement_number' | translate
          }}</label>
          <mat-error
            *ngIf="
              companyNext &&
              formCompanyInfo.controls['number'].errors?.['required']
            "
            >{{ 'services-front.field_is_required' | translate }}</mat-error
          >
        </div>
      </div>
    </form>
  </div>
</div>
<div class="text-right buttons" mat-dialog-actions>
  <button
    mat-button
    class="secondary-btn"
    color="accent"
    [mat-dialog-close]="'cancel'">
    {{ 'services-front.cancel' | translate }}
  </button>

  <button mat-button class="primary-btn" color="primary" (click)="save()">
    {{ 'services-front.save' | translate }}
  </button>
</div> -->
