<ng-container class="main-card">
  <div class="main-workshop-repair-units-container">
    @if (showButtons && !Auth.isViewAllowed(['ROLE_ADMIN'])) {
      <div class="filters-container">
        <button mat-stroked-button class="primary-btn button" (click)="addRepairUnit()">
          Dodaj punkt serwisowy
        </button>
      </div>
    }
    @if (Auth.isViewAllowed(['ROLE_ADMIN'])) {
      <div class="filters-container">
        <button
          mat-stroked-button
          class="add-repair-unit-btn"
          (click)="addRepairUnitAsAdmin()"
        >
          Dodaj punkt serwisowy
        </button>
      </div>
    }
    @if (!isLoading) {
      <div class="main-table-container">
        <table
          mat-table
          [dataSource]="dataSource"
          *ngIf="workshop == workshopId">
          <ng-container matColumnDef="name">
            <th mat-header-cell *matHeaderCellDef>
              {{ 'name' | translate }}
            </th>
            <td mat-cell *matCellDef="let element">
              {{ element.name | dashIfEmpty }}
            </td>
          </ng-container>
          <ng-container matColumnDef="city">
            <th mat-header-cell *matHeaderCellDef>
              {{ 'city' | translate }}
            </th>
            <td mat-cell *matCellDef="let element">
              {{ (element.address.city | dashIfEmpty) + " | " + (element.address.postalCode | dashIfEmpty) }}
            </td>
          </ng-container>
          
          <ng-container matColumnDef="street">
            <th mat-header-cell *matHeaderCellDef>
              {{ 'street' | translate }}
            </th>
            <td mat-cell *matCellDef="let element">
              {{ (element.address.streetName | dashIfEmpty) + ' ' + ( element.address.buildingNumber | dashIfEmpty) + (element.address.apartmentNumber ? ('/' + element.address.apartmentNumber) : '') }}
            </td>
          </ng-container>

          <ng-container matColumnDef="contactPerson">
            <th mat-header-cell *matHeaderCellDef>
              Osoba kontaktowa
            </th>
            <td mat-cell *matCellDef="let element">
              {{ element.contactDetails[0]?.name || element.contactDetails[0]?.surname ? 
                ((element.contactDetails[0]?.name | dashIfEmpty) + ' ' + (element.contactDetails[0]?.surname | dashIfEmpty )) : "-"}}
            </td>
          </ng-container>
  
          <ng-container matColumnDef="phoneNumber">
            <th mat-header-cell *matHeaderCellDef>
              Telefon
            </th>
            <td mat-cell *matCellDef="let element">
              {{ element.contactDetails[0]?.phoneNumber | dashIfEmpty }}
            </td>
          </ng-container>
  
          <ng-container matColumnDef="mobileNumber">
            <th mat-header-cell *matHeaderCellDef>
              Telefon komórkowy
            </th>
            <td mat-cell *matCellDef="let element">
              {{ element.contactDetails[0]?.mobileNumber | dashIfEmpty }}
            </td>
          </ng-container>

          <ng-container matColumnDef="email">
            <th mat-header-cell *matHeaderCellDef>
              {{ 'email' | translate }}
            </th>
            <td mat-cell *matCellDef="let element">
              {{ element.contactDetails[0]?.email | dashIfEmpty }}
            </td>
          </ng-container>
  
          <ng-container matColumnDef="actions">
            <th mat-header-cell *matHeaderCellDef>
              {{ 'settlements-front.table.actions' | translate }}
            </th>
            <td
              mat-cell
              *matCellDef="let element"
            >
              <button mat-icon-button (click)="editRepairUnit($event, element)" color="primary">
                <mat-icon>edit</mat-icon>
              </button>
            </td>
          </ng-container>
  
          <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
          <tr mat-row *matRowDef="let row; columns: displayedColumns" [routerLink]="'repair-unit-details/'+row.id"></tr>
        </table>
      </div>
    }
    @else{
      <div class="mat-spinner-container">
        <mat-spinner/>
      </div>
    }
    <mat-paginator
      [pageSizeOptions]="[10, 20, 30, 50]"
      [pageSize]="itemsPerPage"
      [pageIndex]="pageIndex"
      (page)="onPaginateChange($event)"
      aria-label="Select page of periodic elements">
    </mat-paginator>
  </div>
</ng-container>
