import { Component, inject, Inject, OnInit } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { faXmark } from '@fortawesome/free-solid-svg-icons';
import { Workshop } from 'dashboard-libs/models/workshop.model';
import { concatMap, finalize, from } from 'rxjs';
import { ServicesService } from 'src/app/pages/services/services/services.service';

interface ModalData {
  selectedOrdersToAssignList: string[]
}

@Component({
  selector: 'app-assign-orders-to-workshop-modal',
  templateUrl: './assign-orders-to-workshop-modal.component.html',
  styleUrl: './assign-orders-to-workshop-modal.component.scss',
})

export class AssignOrdersToWorkshopModalComponent implements OnInit {

  private _snackBar = inject(MatSnackBar);

  public isLoading: boolean = false;

  public isCompleted: boolean = false;
  
  public faXmark = faXmark;

  public workshops: Workshop[] = [];

  public selectedOrdersToAssignList: string[] = [];

  public assignedOrdersCount: number = 0;

  public assignOrdersToWorkshopForm = this.formBuilder.group({
    workshop: [null, [Validators.required]]
  });

  constructor(
    private formBuilder: FormBuilder,
    private servicesService: ServicesService,
    @Inject(MAT_DIALOG_DATA) public data: ModalData
  ) {
    this.selectedOrdersToAssignList = data.selectedOrdersToAssignList;
    console.log(this.selectedOrdersToAssignList)
  }

  ngOnInit(): void {
    this.getWorkshops();
  }

  private getWorkshops() : void {
    this.servicesService.getServices().subscribe((workshopsData: any) => {
      this.workshops = workshopsData['hydra:member'];
    });
  }

  public onSave(): void {
    if (this.assignOrdersToWorkshopForm.valid && this.selectedOrdersToAssignList.length) {
      this.isLoading = true;
      this.assignedOrdersCount = 0;
  
      const workshopValue = this.assignOrdersToWorkshopForm.controls.workshop.value;
      
      if (typeof workshopValue === 'string') {
        from(this.selectedOrdersToAssignList)
          .pipe(
            concatMap(orderToAssign => 
              this.servicesService.reassignOrderToWorkshop(orderToAssign, workshopValue)
            ),
            finalize(() => {
              setTimeout(() => {
                this.isLoading = false
                this.isCompleted = true
              }, 500);
            })
          )
          .subscribe({
            next: () => {
              this.assignedOrdersCount++;
            },
            error: (error) => {
              console.error('Błąd podczas przypisywania zamówienia:', error);
              this._snackBar.open(`Wystąpił błąd podczas zapisu zlecenia ${this.assignedOrdersCount + 1}`, "Zamknij", {
                duration: 3000
              });
            }
          });
      }
    }
  }
}
