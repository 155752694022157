<div class="my-profile-container-wrapper">
  <div class="my-profile-container">
    <div class="my-profile-header">
      <h1>{{ 'my_data' | translate }}</h1>
    </div>
    <div class="my-profile-content-container">
      <div class="my-profile-content-item">
        <h6>{{ 'name_and_surname' | translate }}</h6>
        <p>{{ data?.name | dashIfEmpty }} {{ data?.surname | dashIfEmpty }}</p>
      </div>
      <div class="my-profile-content-item">
        <h6>{{ 'user_group' | translate }}</h6>
        <p>{{ data?.keycloakGroups[0]?.name | translate | dashIfEmpty }}</p>
      </div>
      <div class="my-profile-content-item">
        <h6>{{ 'email' | translate }}</h6>
        <p>{{ data?.email | dashIfEmpty }}</p>
      </div>
      <div class="my-profile-content-item">
        <h6>{{ 'Admin' | translate }}</h6>
        <p>{{ data?.admin ? ('yes' | translate)  : ('no' | translate) }}</p>
      </div>
      <div class="my-profile-content-item">
        <h6>{{ 'workshop' | translate }}</h6>
        <p>{{ data?.workshop?.name | dashIfEmpty }}</p>
      </div>
      <div class="my-profile-content-item">
        <h6>Przypisane punkty serwisowe</h6>
        <p>{{data.repairUnits?.length | dashIfEmpty}}</p>
      </div>
    </div>
  </div>
</div>