<ol id="breadcrumbNav" class="breadcrumb-nav">
  @for (breadcrumb of breadcrumbs$ | async; track breadcrumb.label; let i = $index) {
    <li>
      @if(i != (breadcrumbs$ | async)!.length - 1){
        @if(breadcrumb.label === 'home'){
          <a
            [routerLink]="breadcrumb.url"
            class="home-link"
          >
            <mat-icon>home</mat-icon>
          </a>
        }
        @else{
          <a
            [routerLink]="breadcrumb.url"
            [class.disabled]="breadcrumb.disabled"
          >
            {{ breadcrumb.label | translate }}
          </a>
        }
        <mat-icon class="arrow-icon">chevron_right</mat-icon>
      }
      @else{
        <span class="breadcrumb-last-li">
          {{ breadcrumbDetails ? (breadcrumbDetails | translate) : breadcrumb.title }} 
        </span>
        @if(breadcrumb && breadcrumb.url.includes("/orders/order/")){
          <button 
            class="copy-btn"
            matTooltip="Skopiuj do schowka"
            (click)="copyToClipboard(breadcrumbDetails ? breadcrumbDetails : breadcrumb.label)"
          >
            <mat-icon class="copy-btn-icon">file_copy</mat-icon>
          </button>
        }
      }
    </li>
  }
</ol>