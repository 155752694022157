import { Component } from '@angular/core';
import { faXmark } from '@fortawesome/free-solid-svg-icons';

@Component({
  selector: 'app-service-margin',
  templateUrl: './service-margin.component.html',
  styleUrls: ['./service-margin.component.scss']
})
export class ServiceMarginComponent {

  public faXmark = faXmark;

  public types = ['Domyślna', 'Marża mała', 'Marża średnia']

  constructor() {
  }

  add(): void {

  }
}
