<div class="shared-modal-container">
  <div class="shared-modal-header" mat-dialog-draggable-title>
    <div class="shared-modal-header-content">
      <h6>{{ "margin.margin_edition" | translate }}</h6>
      <p class="title-desc">
        {{ "margin.select_from_the_list_of_available_margins" | translate }}
      </p>
    </div>
    <button class="shared-modal-close-btn" mat-dialog-close="cancel">
        <fa-icon [icon]="faXmark"/>
    </button>
  </div>
  <div class="shared-modal-body">
    <form>
      <div class="row grid-1">
        <mat-form-field appearance="outline" class="full-width">
          <mat-label>{{ "margin.margin_selection" | translate }}</mat-label>
          <mat-select formControlName="marginSelection">
            <mat-option value=""> </mat-option>
            <mat-option *ngFor="let type of types" [value]="type">
              {{ type }}
            </mat-option>
          </mat-select>
        </mat-form-field>
      </div>
    </form>
    
    <hr class="custom-hr" />
    <div class="content-item">
      <p>{{ "margin.overall_margin" | translate }}</p>
      <p>2%</p>
    </div>
    <hr class="custom-hr" />
    <div class="content-item">
      <p>{{ "margin.parts" | translate }}</p>
      <p>2%</p>
    </div>
    <hr class="custom-hr" />
    <div class="content-item">
      <p>{{ "margin.labor" | translate }}</p>
      <p>2%</p>
    </div>
    <hr class="custom-hr" />
    <div class="content-item">
      <p>{{ "margin.expertise" | translate }}</p>
      <p>2%</p>
    </div>
    <hr class="custom-hr" />
    <div class="content-item">
      <p>{{ "margin.expertise" | translate }}</p>
      <p>2%</p>
    </div>
  </div>
  <div class="shared-modal-footer">
      <div class="shared-modal-footer-buttons-container">
        <button class="shared-modal-secondary-btn" mat-dialog-close="cancel">
          {{ "margin.cancel" | translate }}
        </button>
        <button class="shared-modal-primary-btn" mat-dialog-close="success" (click)="add()">
          {{ "margin.save" | translate }}
        </button>
      </div>
  </div>
</div>

<!-- <h1 mat-dialog-title class="title">
  {{ "margin.margin_edition" | translate }}
</h1>
<p class="title-desc p-content">
  {{ "margin.select_from_the_list_of_available_margins" | translate }}
</p>
<hr class="custom-hr" />
<div mat-dialog-content>
  <div class="did-floating-label-content full-width">
    <select class="did-floating-select">
      <option value=""></option>
      <option *ngFor="let type of types" [value]="type">
        {{ type }}
      </option>
    </select>
    <label class="did-floating-label">{{
      "margin.margin_selection" | translate
    }}</label>
  </div>
  <hr class="custom-hr" />
  <div class="content-item">
    <p>{{ "margin.overall_margin" | translate }}</p>
    <p>2%</p>
  </div>
  <hr class="custom-hr" />
  <div class="content-item">
    <p>{{ "margin.parts" | translate }}</p>
    <p>2%</p>
  </div>
  <hr class="custom-hr" />
  <div class="content-item">
    <p>{{ "margin.labor" | translate }}</p>
    <p>2%</p>
  </div>
  <hr class="custom-hr" />
  <div class="content-item">
    <p>{{ "margin.expertise" | translate }}</p>
    <p>2%</p>
  </div>
  <hr class="custom-hr" />
  <div class="content-item">
    <p>{{ "margin.expertise" | translate }}</p>
    <p>2%</p>
  </div>
</div>
<div class="text-right buttons" mat-dialog-actions>
  <button mat-button class="primary-btn" color="primary" (click)="add()">
    {{ "margin.save" | translate }}
  </button>
  <button
    mat-button
    class="secondary-btn"
    color="accent"
    [mat-dialog-close]="'cancel'"
  >
    {{ "margin.cancel" | translate }}
  </button>
</div> -->
