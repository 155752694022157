import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Observable, of } from 'rxjs';
import { environment } from 'src/app/environments/environment';
import { MyType } from 'src/app/shared/types/type-hydra';
import { TranslateService } from '@ngx-translate/core';
import { NotificationComment } from 'dashboard-libs/models/notificationComment.model';
import { NotificationOrder } from 'dashboard-libs/models/notificationOrder.model';
import { NotificationPreAuth } from 'dashboard-libs/models/notificationPreAuth.model';
import { UnreadNotificationCounters } from 'dashboard-libs/models/unreadNotificationCounters.model';
import { OrderToTally } from 'dashboard-libs/models/orderToTally.model';
import { RepairUnitDetails } from 'dashboard-libs/models/repairUnitDetails.model';
@Injectable({
  providedIn: 'root',
})
export class OrdersService {
  constructor(
    private http: HttpClient,
    private translate: TranslateService
  ) {}

  getOrders() {
    return this.http.get(
      environment.API.apiHost +
        environment.API.apiPointInternal +
        `/orders/partner-dashboard/list`
    );
  }

  getCommentsCodes(): Observable<any> {
    return this.http.get(
      `${environment.API.apiHost + environment.API.apiPointInternal}/comment_codes`
    );
  }

  sendComment(id: string, body: any) {
    return this.http.post(
      environment.API.apiHost +
        environment.API.apiPointInternal +
        `/comments/${id}`,
      body
    );
  }

  getOrdersFiltered(params: any) {
    return this.http.get(
      environment.API.apiHost +
        environment.API.apiPointInternal +
        `/orders/partner-dashboard/list`,
      { params: params }
    );
  }

  getWorkshop(id: string) {
    return this.http.get(environment.API.apiHost + id);
  }

  getOrderById(id: string) {
    return this.http.get(
      environment.API.apiHost +
        environment.API.apiPointInternal +
        `/partner-orders/${id}`
    );
  }

  getOrderHistory(id: string) {
    return this.http.get(
      environment.API.apiHost +
        environment.API.apiPointInternal +
        `/orders/${id}/history/` +
        (this.translate.currentLang || 'pl')
    );
  }

  getAttachments(orderId: any) {
    return this.http.get(
      environment.API.apiHost +
        environment.API.apiPointInternal +
        `/orders/${orderId}/attachments`
    );
  }

  downloadSummary(startDate: any, endDate: any) {
    return this.http.post<Blob>(
      environment.API.apiHost +
        environment.API.apiPointInternal +
        `/report/order-settlement/generate-and-download/${startDate}|${endDate}`,
      {},
      { observe: 'response', responseType: 'blob' as 'json' }
    );
  }

  downloadCostsSummary(startDate: any, endDate: any) {
    return this.http.post<Blob>(
      environment.API.apiHost +
        environment.API.apiPointInternal +
        `/report/order-settlement/costs/generate-and-download/${startDate}|${endDate}`,
      {},
      { observe: 'response', responseType: 'blob' as 'json' }
    );
  }

  getAttachmentTypes() {
    return this.http.get(
      environment.API.apiHost +
        environment.API.apiPointInternal +
        `/attachment_types`
    );
  }

  getAttachment(id: string) {
    return this.http.get(
      environment.API.apiHost +
        environment.API.apiPointInternal +
        `/attachments/${id}/download`,
      { responseType: 'blob' as 'json' }
    );
  }

  getPolicyByName(name: string) {
    return this.http.get(
      environment.API.apiHost +
        environment.API.apiPointInternal +
        `/insurances/by_number/${name}`
    );
  }

  getOrderMargin(id: string) {
    return this.http.get(
      environment.API.apiHost +
        environment.API.apiPointInternal +
        `/order_margins/${id}`
    );
  }

  updateOrderMargin(id: string, body: any) {
    return this.http.put(
      environment.API.apiHost +
        environment.API.apiPointInternal +
        `/order_margins/${id}`,
      body
    );
  }

  public uploadAttachment(
    orderId: string,
    formData: FormData
  ): Observable<any> {
    return this.http.post<Observable<any>>(
      `${environment.API.apiHost + environment.API.apiPointInternal}/orders/${orderId}/attachments/upload`,
      formData
    );
  }

  getPackages(id: string) {
    return this.http.get(
      environment.API.apiHost +
        environment.API.apiPointInternal +
        `/orders/${id}/shipments`
    );
  }

  addNote(id: string, submit: any) {
    return this.http.put(
      environment.API.apiHost +
        environment.API.apiPointInternal +
        `/orders/${id}/note`,
      {
        content: submit.note,
        type: '',
        code: '',
        toPartner: submit.toPartner,
        toOrderSource: submit.toOrderSource,
      }
    );
  }

  cancelOrder(id: string) {
    return this.http.put(
      environment.API.apiHost +
        environment.API.apiPointInternal +
        `/orders/${id}/cancel`,
      {}
    );
  }

  getBillingList() {
    return this.http.get(
      environment.API.apiHost +
        environment.API.apiPointInternal +
        `/billing/income`
    );
  }
  getBillingListFiltered(params: any) {
    return this.http.get(
      environment.API.apiHost +
        environment.API.apiPointInternal +
        `/billing/income`,
      { params: params }
    );
  }
  getCostsList() {
    return this.http.get(
      environment.API.apiHost +
        environment.API.apiPointInternal +
        `/billing/costs`
    );
  }
  getCostsListFiltered(params: any) {
    return this.http.get(
      environment.API.apiHost +
        environment.API.apiPointInternal +
        `/billing/costs`,
      { params: params }
    );
  }
  getStatesCount() {
    return this.http.get(
      environment.API.apiHost +
        environment.API.apiPointInternal +
        `/partner-orders/states-count`
    );
  }

  quickSearch(params: any) {
    return this.http.get(
      environment.API.apiHost +
        environment.API.apiPointInternal +
        `/orders/quick-search`,
      { params: params }
    );
  }

  public searchForOrdersToAddToTally(searchText: string, excludedOrders: string[]) : Observable<OrderToTally[]> {
    const params = new HttpParams();
    params.append('limit', 25);
    return this.http.post<OrderToTally[]>(`${environment.API.apiHost+environment.API.apiPointInternal}/billing/invoice/search-orders-to-add?limit=25`, {
      searchText,
      excludedOrders
    });
  }

  postAttachment(data: any, orderId: any) {
    let formData = new FormData();

    formData.append('attachmentFile', data.file);
    formData.append('typeId', data.type);
    formData.append('toOrderSource', data.toOrderSource);
    formData.append('toPartner', data.toPartner);
    return this.http.post(
      environment.API.apiHost +
        environment.API.apiPointInternal +
        `/orders/${orderId}/attachments/upload`,
      formData
    );
  }

  postXlsxToVerify(data: any) {
    let formData = new FormData();

    formData.append('verificationFile', data.file);

    return this.http.post(
      environment.API.apiHost +
        environment.API.apiPointInternal +
        `/billing/verification/upload-xlsx`,
      formData
    );
  }

  postInvoice(data: any, ordersId: any) {
    let formData = new FormData();

    formData.append('invoiceFile', data.file);
    formData.append('orderIds', JSON.stringify(ordersId));
    formData.append('verificationFileId', data.verificationFileId);
    formData.append(
      'verificationFileTotalGross',
      data.verificationFileTotalGross
    );

    return this.http.post(
      environment.API.apiHost +
        environment.API.apiPointInternal +
        `/billing/upload-invoice`,
      formData
    );
  }

  downloadbillingInvoice(orderId: any) {
    return this.http.get(
      environment.API.apiHost +
        environment.API.apiPointInternal +
        `/orders/${orderId}/billing/download`,
      { responseType: 'blob' }
    );
  }
  downloadbillingRevenueInvoice(orderId: any) {
    return this.http.get(
      environment.API.apiHost +
        environment.API.apiPointInternal +
        `/orders/${orderId}/billing-revenue/download`,
      { responseType: 'blob' }
    );
  }

  getDataFromIRI(iri: string) {
    return this.http.get(environment.API.apiHost + iri);
  }

  getNewComments(): Observable<NotificationComment> {
    return this.http.get<NotificationComment>(
      `${environment.API.apiHost + environment.API.apiPointInternal}/notifications/${environment.applicationName === 'DRS Partner' ? 'partner-unread-comments' : 'unread-comments'}`
    );
  }

  getNextComments(offset: any, limit: any): Observable<NotificationComment> {
    return this.http.get<NotificationComment>(
      `${environment.API.apiHost + environment.API.apiPointInternal}/notifications/${environment.applicationName === 'DRS Partner' ? 'partner-unread-comments' : 'unread-comments'}/${offset}/${limit}`
    );
  }

  getNextNewComments(offset: any, limit: any): Observable<NotificationComment> {
    return this.http.get<NotificationComment>(
      `${environment.API.apiHost + environment.API.apiPointInternal}/notifications/${environment.applicationName === 'DRS Partner' ? 'partner-unread-comments' : 'unread-comments'}/${offset}/${limit}?unread-only=yes`
    );
  }

  getNewOrders(): Observable<NotificationOrder> {
    return this.http.get<NotificationOrder>(
      `${environment.API.apiHost + environment.API.apiPointInternal}/notifications/${environment.applicationName === 'DRS Partner' ? 'partner-unread-orders' : 'unread-orders'}`
    );
  }

  getNewPreAuth(): Observable<NotificationPreAuth> {
    return this.http.get<NotificationPreAuth>(
      `${environment.API.apiHost + environment.API.apiPointInternal}/notifications/${environment.applicationName === 'DRS Partner' ? 'partner-unread-preauthorizations' : 'unread-preauthorizations'}`
    );
  }

  getUnreadNotificationsCount(): Observable<UnreadNotificationCounters> {
    return this.http.get<UnreadNotificationCounters>(
      `${environment.API.apiHost + environment.API.apiPointInternal}/notifications/${environment.applicationName === 'DRS Partner' ? 'partner-counters' : 'counters'}`
    );
  }

  getApiState() {
    return this.http.get(
      environment.API.apiHost +
        environment.API.apiPointInternal +
        '/partner_api_requests_logs/status'
    );
  }
  getOrderPreAuth(id: string) {
    return this.http.get(
      environment.API.apiHost +
        environment.API.apiPointInternal +
        `/orders/${id}/preauthorization`
    );
  }

  getStates() {
    return this.http.get(
      environment.API.apiHost +
        environment.API.apiPointInternal +
        '/workflow/places'
    );
  }

  getMaxSize() {
    return this.http.get(
      environment.API.apiHost +
        environment.API.apiPointInternal +
        '/files/upload/max-size'
    );
  }

  getLoggedUser() {
    return this.http.get(
      environment.API.apiHost +
        environment.API.apiPointInternal +
        '/users/logged-user'
    );
  }

  archiveOrder(id: string) {
    return this.http.put(
      environment.API.apiHost +
        environment.API.apiPointInternal +
        `/orders/${id}/move-to-archive`,
      {}
    );
  }

  confirmOrder(id: string) {
    return this.http.put(
      environment.API.apiHost +
        environment.API.apiPointInternal +
        `/orders/${id}/confirm`,
      {}
    );
  }

  orderExecutionData(id: string, data: any) {
    return this.http.put(
      environment.API.apiHost +
        environment.API.apiPointInternal +
        `/orders/${id}/execution-data`,
      data
    );
  }

  deviceDelivery(id: string, data: any) {
    return this.http.put(
      environment.API.apiHost +
        environment.API.apiPointInternal +
        `/orders/${id}/device/delivery`,
      data
    );
  }

  addParts(id: string, data: any) {
    return this.http.put(
      environment.API.apiHost +
        environment.API.apiPointInternal +
        `/orders/${id}/parts_list`,
      data
    );
  }

  addCosts(id: string, data: any) {
    return this.http.put(
      environment.API.apiHost +
        environment.API.apiPointPartners +
        `/orders/${id}/costs`,
      data
    );
  }

  public getServiceRepairUnitDetails(repairUnitId: string) : Observable<RepairUnitDetails> {
    return this.http.get<RepairUnitDetails>(`${environment.API.apiHost + environment.API.apiPointInternal}/repair_units/${repairUnitId}`);
  }

  editRepairUnit(id: string, data: any) {
    return this.http.put(
      environment.API.apiHost +
        environment.API.apiPointInternal +
        `/repair_units/${id}`,
      data
    );
  }

  addDiagnosis(id: string, data: any) {
    return this.http.post(
      environment.API.apiHost +
        environment.API.apiPointInternal +
        `/orders/${id}/diagnosis`,
      data
    );
  }

  addDiagnose(id: string, data: any) {
    return this.http.put(
      environment.API.apiHost +
        environment.API.apiPointInternal +
        `/orders/${id}/diagnose`,
      data
    );
  }

  sendFinishOrder(id: string, data: any) {
    return this.http.put(
      environment.API.apiHost +
        environment.API.apiPointInternal +
        `/orders/${id}/finished`,
      data
    );
  }

  sendEndRepair(id: string, data: any) {
    return this.http.put(
      environment.API.apiHost +
        environment.API.apiPointInternal +
        `/orders/${id}/repaired`,
      data
    );
  }

  sendShipment(id: string, data: any) {
    return this.http.post(
      environment.API.apiHost +
        environment.API.apiPointInternal +
        `/orders/${id}/shipment`,
      data
    );
  }

  getShipments(id: string) {
    return this.http.get(
      environment.API.apiHost +
        environment.API.apiPointInternal +
        `/orders/${id}/shipments`
    );
  }

  updateRepairDescription(id: string, data: any) {
    return this.http.put(
      environment.API.apiHost +
        environment.API.apiPointInternal +
        `/orders/${id}/repair-description`,
      data
    );
  }

  setProcessStep(id: string, processStep: string) {
    return this.http.put(
      environment.API.apiHost +
        environment.API.apiPointInternal +
        `/orders/${id}/process-step/${processStep}`,
      {}
    );
  }

  sendPreAuth(id: string, data: any) {
    return this.http.put(
      environment.API.apiHost +
        environment.API.apiPointInternal +
        `/orders/${id}/preauthorization`,
      data
    );
  }

  assignOrder(id: string, data: any) {
    return this.http.put(
      environment.API.apiHost +
        environment.API.apiPointInternal +
        `/orders/${id}/assigned`,
      data
    );
  }

  repairingOrder(id: string) {
    return this.http.put(
      environment.API.apiHost +
        environment.API.apiPointInternal +
        `/orders/${id}/repairing`,
      {}
    );
  }

  endOfRepairingOrder(id: string, data: any) {
    return this.http.put(
      environment.API.apiHost +
        environment.API.apiPointInternal +
        `/orders/${id}/repaired`,
      data
    );
  }

  deleteShipment(id: string) {
    return this.http.delete(
      environment.API.apiHost +
        environment.API.apiPointInternal +
        `/shipments/${id}`
    );
  }

  checkSerialNumber(id: string, checked: string) {
    return this.http.put(
      environment.API.apiHost +
        environment.API.apiPointInternal +
        `/orders/${id}/device/serial-number-correct`,
      { isCorrect: checked }
    );
  }

  addRepairUnit(data: any) {
    return this.http.post(
      environment.API.apiHost +
        environment.API.apiPointInternal +
        `/repair-unit`,
      data
    );
  }

  getRepairUnit(id: string) {
    return this.http.get(
      environment.API.apiHost +
        environment.API.apiPointInternal +
        `/repair_units/${id}`
    );
  }

  getRepairUnitsByWorkshop(id: string, params: HttpParams) {
    return this.http.get(
      environment.API.apiHost +
        environment.API.apiPointInternal +
        `/workshops/${id}/repair-units`, { params }
    );
  }

  public updateServiceNote(orderId: string, content: string): Observable<any> {
    const body = {
      content: content,
    };
    return this.http.put(
      `${environment.API.apiHost + environment.API.apiPointInternal}/orders/${orderId}/execution/internal-note`,
      body
    );
  }

  getComments(
    orderId: string,
    withCode?: 'yes' | 'no' | 'all'
  ): Observable<any> {
    let params = new HttpParams();
    params = params.append('withCode', withCode || 'all');
    return this.http.get(
      `${environment.API.apiHost + environment.API.apiPointInternal}/orders/${orderId}/comments`,
      { params }
    );
  }
}
