import { Component, inject, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { History } from 'dashboard-libs/models/history.model';
import { Order } from 'dashboard-libs/models/order.model';
import { Policy } from 'dashboard-libs/models/policy.model';

export interface GeneralOrderData {
  type: 'default' | 'state' | 'date' | 'action';
  name: string;
  value: string;
}

export interface OrderOverviewData {
  type: 'text' | 'date' | 'progress' | 'text-copy'; 
  name?: string; 
  value?: string, 
  purchaseDate? : Date; 
  insurancePeriod?: string; 
}

export interface OrderSummaryData {
  type: 'text' | 'cost' | 'date' | 'action' | 'percent';
  name: string; 
  color?: string;
  value: string | number;
}

@Component({
  selector: 'app-order-info-overview',
  templateUrl: './order-info-overview.component.html',
  styleUrl: './order-info-overview.component.scss',
})
export class OrderInfoOverwiewComponent implements OnInit, OnChanges{

  private _snackBar = inject(MatSnackBar);
  
  @Input({required: true}) order!: Order;

  @Input({required: true}) policy!: Policy;

  @Input({required: true}) history!: History[];

  generalOrderData : GeneralOrderData[] = [];

  orderOverviewData: OrderOverviewData[] = [];

  orderSummaryData : OrderSummaryData[] = [];

  constructor() {

  }
  
  ngOnInit(): void { }

  ngOnChanges(changes: SimpleChanges): void {
    if(this.order && this.policy){
      this.initOrderOverwiewData();
    }
  }

  initOrderOverwiewData() : void {
    this.generalOrderData = [
      {
        type: 'date',
        name: "Data zdarzenia",
        value: this.order.incident.eventDate
      },
      {
        type: 'date',
        name: "Data zgłoszenia",
        value: this.order.createdAt
      },
      {
        type: 'state',
        name: "Status",
        value: this.order.state
      },
      {
        type: 'action',
        name: "Akcja Serwisu",
        value: this.order?.orderExecution?.code && this.order?.orderExecution?.action ? `${this.order?.orderExecution?.code} | ${this.order?.orderExecution?.action}` : ''
      },
      {
        type: 'default',
        name: "Zlecający",
        value: this.order.orderer
      },
      {
        type: 'default',
        name: "Płatnik",
        value: this.order.payer
      }
    ];

    this.orderOverviewData = [
      {
        type: 'text-copy',
        name: "Nr. Polisy",
        value: this.order.insuranceNumber
      },
      {
        type: 'text',
        name: "Okres ubezpieczenia",
        value: `${this.policy.product} ${this.order.insurancePeriod}`
      },
      {
        type: 'text',
        name: "Sieć / Firma",
        value: this.order.company
      },
      {
        type: 'date',
        name: "Data wystawienia",
        value: this.order.warranty.startDate
      },
      {
        type: 'text',
        name: "Kod naprawy",
        value: this.order.repairCode
      },
      {
        type: 'progress',
        purchaseDate: this.order.device.purchaseDate,
        insurancePeriod: this.order.insurancePeriod,
      }
    ];

    this.orderSummaryData = [
      {
        type: 'cost',
        name: "Kwota na polise",
        value: this.policy?.summary?.value
      },
      {
        type: 'cost',
        name: "Maks. koszt naprawy",
        value: this.order?.incident?.maxinsured < 0 ? "Brak informacji" : this.order?.incident?.maxinsured,
        color: "red"
      },
      {
        type: 'cost',
        name: "Wycena",
        value: this.order?.costs?.totalGrossWithMarginAndServiceFee || 0,
        color: "green"
      },
      {
        type: 'percent',
        name: "Udział własny",
        value: this.policy?.deductiblePercent
      },
      {
        type: 'date',
        name: "Przew. term. napr.",
        value: this.order?.dates?.estimatedRepairDate
      }
    ];
  }

  copyToClipboard(textValue: string) : void {
    navigator.clipboard.writeText(textValue).then(() => {
      this._snackBar.open('Skopiowano do schowka',"Zamknij", {
        horizontalPosition: 'center',
        verticalPosition: 'top',
        duration: 1000
      })
    }).catch(error => {
      console.error(error);
    });
  }
}
