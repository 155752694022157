import { CommentsComponent } from './../../modals/comments/comments';
import {
  Component,
  Input,
  ElementRef,
  Renderer2,
  ViewChild,
  OnDestroy,
  AfterViewInit,
} from '@angular/core';
import { KeycloakService } from 'keycloak-angular';
import { TranslateService } from '@ngx-translate/core';
import { OrdersService } from 'src/app/pages/orders/services/orders.service';
import { Router } from '@angular/router';

import { environment } from 'src/app/environments/environment';
import { MatDialog } from '@angular/material/dialog';
import { OrderNotificationsComponent } from '../../modals/notifications/notifications';
import { PreAuthComponent } from '../../modals/preAuth/preAuth';
import { faCircleCheck } from '@fortawesome/free-regular-svg-icons';
import { faAngleDown, faInbox } from '@fortawesome/free-solid-svg-icons';
import { NotificationService } from '../../services/notification.service';
import { filter, Subject, takeUntil } from 'rxjs';

@Component({
  selector: 'app-top-navbar',
  templateUrl: './top-navbar.component.html',
  styleUrls: ['./top-navbar.component.scss'],
})
export class TopNavbarComponent implements OnDestroy {
  @Input() user: any;

  @ViewChild(CommentsComponent) commentsComponent!: CommentsComponent;

  @ViewChild('toggleButton') toggleButton!: ElementRef;

  @ViewChild('menu') menu!: ElementRef;

  private destroy$: Subject<void> = new Subject<void>();

  commentsFilterCategory:  'ALL' | 'UNREAD' = 'UNREAD';

  faCircleCheck = faCircleCheck;

  faInBox = faInbox;

  faAngleDown = faAngleDown;

  isListVisible: boolean = false;

  filteredItems: any[] = [];

  language: string = environment.language;

  searchValue!: any;

  focusedItemIndex: undefined;

  checkEngine: boolean = false;

  checkEngineError!: any;

  commentsCount!: number;

  notificationsCount!: number;

  preAuthCount!: number;

  inputValue: string = '';

  menuNotifications: boolean = false;

  menuComments: boolean = false;

  menuPreAuth: boolean = false;

  isOrdersMenuOpen: boolean = false;

  isCommentsMenuOpen: boolean = false;

  isNotificationsMenuOpen: boolean = false;
  
  constructor(
    private keycloak: KeycloakService,
    private dialog: MatDialog,
    private translateService: TranslateService,
    private ordersService: OrdersService,
    private renderer: Renderer2,
    private router: Router,
    private notificationService: NotificationService
  ) {
    this.notificationService.unreadCommentsCount$.pipe(takeUntil(this.destroy$)).subscribe((count) => {
      this.commentsCount = count;
    });

    this.notificationService.unreadOrdersCount$.pipe(takeUntil(this.destroy$)).subscribe((count) => {
      this.notificationsCount = count;
    });

    this.notificationService.unreadPreAuthCount$.pipe(takeUntil(this.destroy$)).subscribe((count) => {
      this.preAuthCount = count;
    });

    this.router.routeReuseStrategy.shouldReuseRoute = () => false;

    this.renderer.listen('window', 'click', (e: Event) => {
      if (
        e.target !== this.toggleButton.nativeElement &&
        e.target !== this.menu.nativeElement
      ) {
        this.isListVisible = false;
      }
    });

    this.getApiState();
    setInterval(() => {
      this.getApiState();
    }, 300000);

    this.notificationService.commentsCategory$.subscribe(commentsCategory => {
      this.commentsFilterCategory = commentsCategory;
    })
  }

  changeCommentsFilterCategory(filterCategory: 'ALL' | 'UNREAD') : void {
    console.log(filterCategory);
    this.notificationService.resetPagination();
    // this.commentsFilterCategory = filterCategory;
    this.notificationService.setMessagesCategory(filterCategory);
    // this.commentsComponent.loadComments();
  }

  logout(): void {
    localStorage.clear();
    this.keycloak.logout();
  }

  getApiState() {
    this.ordersService.getApiState().subscribe((res: any) => {
      this.checkEngine = res.problem;
      this.checkEngineError = res;
    });
  }

  filterSearch(event: Event) {
    this.searchValue = {
      searchText: (event.target as HTMLInputElement).value,
      limit: 10,
    };

    if (this.searchValue.searchText.length < 3) {
      this.isListVisible = false;
      return;
    }
    this.ordersService.quickSearch(this.searchValue).subscribe((res: any) => {
      this.filteredItems = res['hydra:member'];
    });
    this.isListVisible = true;
  }

  setListVisibility(valueToSet: boolean): void {
    this.isListVisible = valueToSet;

    if (valueToSet === false) {
      this.focusedItemIndex = undefined;
    }
  }

  setLang(lang: string): void {
    this.translateService.use(lang);
  }

  goToDetails(id: string): void {
    this.router.navigate(['./orders/order/' + id]);
  }

  notifications() {
    const dialogRef = this.dialog.open(OrderNotificationsComponent, {
      disableClose: true,
    });

    dialogRef.afterClosed().subscribe();
  }

  comments() {
    const dialogRef = this.dialog.open(CommentsComponent, {
      disableClose: true,
    });

    dialogRef.afterClosed().subscribe();
  }

  preAuth() {
    const dialogRef = this.dialog.open(PreAuthComponent, {
      disableClose: true,
    });

    dialogRef.afterClosed().subscribe();
  }

  menuNotificationsOpened() {
    this.menuNotifications = true;
  }
  menuCommentsOpened() {
    this.menuComments = true;
  }

  menuPreAuthOpened() {
    this.menuPreAuth = true;
  }

  menuNotificationsClosed() {
    this.menuNotifications = false;
  }

  menuCommentsClosed() {
    this.menuComments = false;
  }

  menuPreAuthClosed() {
    this.menuPreAuth = false;
  }

  toggleCommentsMenuOpen() : void {
    this.isCommentsMenuOpen = !this.isCommentsMenuOpen;
  }

  toggleOrdersMenuOpen() : void {
    this.isOrdersMenuOpen = !this.isOrdersMenuOpen;
  }

  toggleNotificationsMenuOpen() : void {
    this.isNotificationsMenuOpen = !this.isNotificationsMenuOpen;
  }

    
  ngOnDestroy(): void {
    this.notificationService.stopFetchingUnreadNotifications();
    this.destroy$.next();
    this.destroy$.complete();
  }
}
