<div class="shared-modal-container">
  <div class="shared-modal-header" mat-dialog-draggable-title>
    <div class="shared-modal-header-content">
      <h6>{{ 'services-front.edit_service' | translate }}</h6>
      <p class="title-desc">
        {{ 'services-front.fill_in_the_information_below' | translate }}
      </p>
    </div>
    <button class="shared-modal-close-btn" mat-dialog-close="cancel">
        <fa-icon [icon]="faXmark"/>
    </button>
  </div>
  <div class="shared-modal-body">
    <form [formGroup]="formCompanyInfo" class="filters-inputs">
      <div class="row grid-2">
        <mat-form-field appearance="outline" class="mr-10 mt-15">
          <mat-label>{{ 'services-front.city' | translate }}</mat-label>
          <input
            matInput
            formControlName="city"
            required
            type="text"
            placeholder=" "
          />
          <mat-error *ngIf="companyNext && formCompanyInfo.controls['city'].errors?.['required']">
            {{ 'services-front.field_is_required' | translate }}
          </mat-error>
          <mat-error *ngIf="companyNext && (formCompanyInfo.controls['city'].errors?.['minlength'] || formCompanyInfo.controls['city'].errors?.['maxlength'])">
            To pole wymaga podania prawidłowej nazwy miasta
          </mat-error>
        </mat-form-field>

        <mat-form-field appearance="outline" class="mt-15">
          <mat-label>{{ 'services-front.postal_code' | translate }}</mat-label>
          <input
            matInput
            formControlName="postalCode"
            required
            mask="00-000"
            type="text"
            placeholder=" "
          />
          <mat-error *ngIf="companyNext && formCompanyInfo.controls['postalCode']?.errors?.['required']">
            {{ 'services-front.field_is_required' | translate }}
          </mat-error>
          <mat-error *ngIf="(companyNext && formCompanyInfo.controls['postalCode']?.errors?.['mask']) || (companyNext && formCompanyInfo.controls['postalCode']?.errors?.['pattern'])">
            To pole wymaga podania prawidłowego nr pocztowego
          </mat-error>
        </mat-form-field>
      </div>

      <div class="row grid-2">
        <mat-form-field appearance="outline" class="mr-10 mt-15">
          <mat-label>{{ 'services-front.street' | translate }}</mat-label>
          <input
            matInput
            formControlName="street"
            required
            type="text"
            placeholder=" "
          />
          <mat-error *ngIf="companyNext && formCompanyInfo.controls['street'].errors?.['required']">
            {{ 'services-front.field_is_required' | translate }}
          </mat-error>
          <mat-error *ngIf="companyNext && (formCompanyInfo.controls['street'].errors?.['minlength'] || formCompanyInfo.controls['street'].errors?.['maxlength'])">
            To pole wymaga podania prawidłowej nazwy ulicy
          </mat-error>
        </mat-form-field>
        <div class="row  grid-2">
          <mat-form-field appearance="outline" class="small-input mt-15 mr-10">
            <mat-label>{{ 'services-front.house_number' | translate }}</mat-label>
            <input
              matInput
              formControlName="houseNumber"
              required
              type="text"
              placeholder=" "
            />
            <mat-error *ngIf="companyNext && formCompanyInfo.controls['houseNumber'].errors?.['required']">
              {{ 'services-front.field_is_required' | translate }}
            </mat-error>
            <mat-error *ngIf="companyNext && formCompanyInfo.controls['houseNumber'].errors?.['pattern']">
              To pole wymaga podania prawidłowego numeru domu
            </mat-error>
          </mat-form-field>

          <mat-form-field appearance="outline" class="small-input mt-15">
            <mat-label>{{ 'services-front.apartment_number' | translate }}</mat-label>
            <input
              matInput
              formControlName="apartmentNumber"
              type="text"
              placeholder=" "
            />
          </mat-form-field>
        </div>
      </div>
    </form>
  </div>
  <div class="shared-modal-footer">
      <div class="shared-modal-footer-buttons-container">
        <button class="shared-modal-secondary-btn" mat-dialog-close="cancel">
          {{ 'services-front.cancel' | translate }}
        </button>
        <button class="shared-modal-primary-btn" mat-dialog-close="success" (click)="save()">
          {{ 'services-front.save' | translate }}
        </button>
      </div>
  </div>
</div>
<!-- 
<h1 mat-dialog-title class="title">
  {{ 'services-front.edit_service' | translate }}
</h1>
<p class="title-desc">
  {{ 'services-front.fill_in_the_information_below' | translate }}
</p>
<hr class="custom-hr" />

<div mat-dialog-content>
  <div [hidden]="tab !== 'companyInfo'">
    <form [formGroup]="formCompanyInfo">
      <div class="flex">
        <div class="did-floating-label-content mr-10 mt-15">
          <input
            class="did-floating-input"
            formControlName="city"
            required
            type="text"
            placeholder=" " />
          <label class="did-floating-label">{{
            'services-front.city' | translate
          }}</label>
          <mat-error
            *ngIf="
              companyNext &&
              formCompanyInfo.controls['city'].errors?.['required']
            "
            >{{ 'services-front.field_is_required' | translate }}</mat-error
          >
          <mat-error
            *ngIf="
              companyNext &&
              (formCompanyInfo.controls['city'].errors?.['minlength'] ||
                formCompanyInfo.controls['city'].errors?.['maxlength'])
            "
            >To pole wymaga podania prawidłowej nazwy miasta</mat-error
          >
        </div>
        <div class="did-floating-label-content mt-15">
          <input
            class="did-floating-input"
            formControlName="postalCode"
            required
            mask="00-000"
            type="text"
            placeholder=" " />
          <label class="did-floating-label">{{
            'services-front.postal_code' | translate
          }}</label>
          <mat-error
            *ngIf="
              companyNext &&
              formCompanyInfo.controls['postalCode']?.errors?.['required']
            "
            >{{ 'services-front.field_is_required' | translate }}</mat-error
          >
          <mat-error
            *ngIf="
              (companyNext &&
                formCompanyInfo.controls['postalCode']?.errors?.['mask']) ||
              (companyNext &&
                formCompanyInfo.controls['postalCode']?.errors?.['pattern'])
            "
            >To pole wymaga podania prawidłowego nr pocztowego</mat-error
          >
        </div>
      </div>
      <div class="flex">
        <div class="did-floating-label-content mr-10 mt-15">
          <input
            class="did-floating-input"
            formControlName="street"
            required
            type="text"
            placeholder=" " />
          <label class="did-floating-label">{{
            'services-front.street' | translate
          }}</label>
          <mat-error
            *ngIf="
              companyNext &&
              formCompanyInfo.controls['street'].errors?.['required']
            "
            >{{ 'services-front.field_is_required' | translate }}</mat-error
          >
          <mat-error
            *ngIf="
              companyNext &&
              (formCompanyInfo.controls['street'].errors?.['minlength'] ||
                formCompanyInfo.controls['street'].errors?.['maxlength'])
            "
            >To pole wymaga podania prawidłowej nazwy ulicy</mat-error
          >
        </div>
        <div class="did-floating-label-content small-input mt-15 mr-10">
          <input
            class="did-floating-input"
            formControlName="houseNumber"
            required
            type="text"
            placeholder=" " />
          <label class="did-floating-label">{{
            'services-front.house_number' | translate
          }}</label>
          <mat-error
            *ngIf="
              companyNext &&
              formCompanyInfo.controls['houseNumber'].errors?.['required']
            "
            >{{ 'services-front.field_is_required' | translate }}</mat-error
          >
          <mat-error
            *ngIf="
              companyNext &&
              formCompanyInfo.controls['houseNumber'].errors?.['pattern']
            "
            >To pole wymaga podania prawidłowego numeru domu</mat-error
          >
        </div>
        <div class="did-floating-label-content small-input mt-15">
          <input
            class="did-floating-input"
            formControlName="apartmentNumber"
            type="text"
            placeholder=" " />
          <label class="did-floating-label">{{
            'services-front.apartment_number' | translate
          }}</label>
        </div>
      </div>
    </form>
  </div>
</div>
<div class="text-right buttons" mat-dialog-actions>
  <button
    mat-button
    class="secondary-btn"
    color="accent"
    [mat-dialog-close]="'cancel'">
    {{ 'services-front.cancel' | translate }}
  </button>

  <button mat-button class="primary-btn" color="primary" (click)="save()">
    {{ 'services-front.save' | translate }}
  </button>
</div> -->
