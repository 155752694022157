import { Component, Inject } from '@angular/core';
import {
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { faXmark } from '@fortawesome/free-solid-svg-icons';

import { environment } from 'src/app/environments/environment';
import { ServicesService } from 'src/app/pages/services/services/services.service';

@Component({
  selector: 'app-edit-contact',
  templateUrl: './edit-contact.html',
  styleUrls: ['./edit-contact.scss'],
})
export class EditContactComponent {
  
  public faXmark = faXmark;

  phone1!: FormControl<string | null>;

  phone2!: FormControl<string | null>;

  public submitted!: boolean;

  public contact: any;

  public formContactInfo = this.fb.group({
    name: ['', [Validators.required, Validators.pattern(environment.validators.name)]],
    surname: ['',[Validators.required, Validators.pattern(environment.validators.surname)]],
    phoneNumber: ['', [Validators.required, Validators.pattern('[+0-9 ]*')]],
    secondNumber: ['', [Validators.pattern('[+0-9 ]*')]],
    email: [{value: '', disabled: true}, [Validators.required, Validators.pattern(environment.validators.email)]],
    type: ['', Validators.required],
  });

  constructor(
    private fb: FormBuilder,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private servicesService: ServicesService,
    private dialogRef: MatDialogRef<EditContactComponent>,
    private _snackBar: MatSnackBar
  ) {
    this.fetchUserFormData(data.contact.id);
  }

  public fetchUserFormData(contactId: string) : void {
    this.servicesService.getServiceContactById(contactId).subscribe((res: any) => {
      this.contact = res.contactDetails;
      this.formContactInfo.patchValue({
        name: this.contact.name,
        surname: this.contact.surname,
        phoneNumber: this.contact.mobileNumber,
        secondNumber: this.contact.phoneNumber,
        email: this.contact.email,
        type: this.contact.type,
      });
    });
  }

  ngOnInit(): void {
    this.phone1 = this.formContactInfo.get('phoneNumber') as FormControl;
    this.phone2 = this.formContactInfo.get('secondNumber') as FormControl;
  }

  save(): void {
    this.submitted = true;

    if (this.data.contact.id) {
      if (!this.formContactInfo.invalid) {
        const body = {
          id: this.data.contact.id,
          contactDetails: {
            email: this.formContactInfo.value.email,
            mobileNumber: this.formContactInfo.value.phoneNumber?.toString().replace(/ /g,''),
            phoneNumber: this.formContactInfo.value.secondNumber?.toString().replace(/ /g,''),
            description: 'string',
            name: this.formContactInfo.value.name,
            surname: this.formContactInfo.value.surname,
            type: this.formContactInfo.value.type,
          },
        };
        this.servicesService
          .updateServiceContact(body)
          .subscribe((res: any) => {
            this._snackBar.open('Zedytowano kontakt', 'Zamknij', {
              duration: 3000,
            });
            this.dialogRef.close(res);
          });
      }
    }
  }
}
