<div class="page-wrapper">
  <div class="cards-container">
    <button class="card" (click)="generateSettlementSummary()">
      <fa-icon [icon]="faChartColumn"/>
      <h1>Zestawienia rozliczonych przychodów</h1>
      <p>Wygeneruj zestawienie</p>
    </button>
    <button class="card" (click)="generateServiceCostsAndInsurance()">
      <fa-icon [icon]="faChartBar"/>
      <h1>Raport kosztów serwisu i kwota na polisie</h1>
      <p>Wygeneruj zestawienie</p>
    </button>
    <button class="card" (click)="generateCostsSummary()">
      <fa-icon [icon]="faFileContract"/>
      <h1>Zestawienia rozliczonych kosztów</h1>
      <p>Wygeneruj zestawienie</p>
    </button>
  </div>
</div>
