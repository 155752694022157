<div class="shared-modal-container">
    @if(!isLoading && !isCompleted){
        <div class="shared-modal-header" mat-dialog-draggable-title>
            <div class="shared-modal-header-content">
                <h6>Przypisane serwisu do zlecenia</h6>
                <p>Wybierz  serwis do obsługi zaznaczonych zleceń.</p>
            </div>
            <button class="shared-modal-close-btn" mat-dialog-close="cancel">
                <fa-icon [icon]="faXmark"/>
            </button>
        </div>
    }
    <div class="shared-modal-body">
        @if(!isLoading && !isCompleted) {
            <form [formGroup]="assignOrdersToWorkshopForm" class="filters-inputs">
                <mat-form-field appearance="outline">
                    <mat-label>Nazwa serwisu</mat-label>
                    <mat-select formControlName="workshop">
                      @for (workshop of workshops; track $index) {
                        <mat-option [value]="workshop.id">{{workshop.name}}</mat-option>
                      }
                    </mat-select>
                </mat-form-field> 
            </form>
        }
        @else if (isLoading && !isCompleted) {
            <div class="progress-bar-container">
                <p>Przypisywanie zleceń do serwisu ({{assignedOrdersCount +"/"+ selectedOrdersToAssignList.length}})</p>
                <mat-progress-bar mode="determinate" [value]="(assignedOrdersCount / selectedOrdersToAssignList.length) * 100"/>
            </div>
        }
        @else if(!isLoading && isCompleted){
            <div class="success-info-container">
                <h1>Przypisanie serwisu zostało wykonane</h1>
                <p>Wybrane zlecenia zostały skierowane do wybranego serwisu</p>
            </div>
        }
    </div>
    <div class="shared-modal-footer">
        <div class="shared-modal-footer-buttons-container">
            @if(!isLoading && !isCompleted) {
                <button mat-stroked-button mat-dialog-close="cancel">
                    Anuluj
                </button>
                <button mat-raised-button (click)="onSave()" [disabled]="!assignOrdersToWorkshopForm.valid">
                    <span style="color: #FFF;">Zapisz</span>
                </button>
            }
            @else if(!isLoading && isCompleted){
                <button mat-raised-button mat-dialog-close="success">
                    <span style="color: #FFF;">Zamknij</span>
                </button>
            }
            @else{}
        </div>
    </div>
</div>