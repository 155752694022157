<div class="shared-modal-container">
    <div class="shared-modal-header" mat-dialog-draggable-title>
        <h6>{{title}}</h6>
        <button class="shared-modal-close-btn" (click)="onModalClose()">
            <fa-icon [icon]="faXmark"/>
        </button>
    </div>
    <div class="shared-modal-body">
       <p>{{description}}</p>

       @if(list && list.length){
        <div class="shared-modal-list-container">
            <ul>
                @for (listItem of list; track $index) {
                    <li>{{listItem}}</li>
                }
            </ul>
       </div>
       }
    </div>
    <div class="shared-modal-footer">
        <div class="shared-modal-footer-buttons-container">
            <button mat-stroked-button mat-dialog-close="cancel">
                {{cancelButton || 'Anuluj'}}
            </button>
            @if(!singleAction){
                <button mat-raised-button mat-dialog-close="success">
                    <span style="color: #FFF;">{{confirmButton || 'Potwierdź'}}</span>
                </button>
            }
        </div>
    </div>
</div>