<h6 mat-dialog-title class="mat-dialog-title">Preautoryzacja</h6>
<mat-icon class="close-button" [mat-dialog-close]="'cancel'">close</mat-icon>
@if(!confirmDialog){
  <div mat-dialog-content class="mat-dialog-content">
    @if(data?.preAuth && !data?.preAuth?.decision && data?.preAuth?.requestedAt){
      <mat-card class="title-card waiting">
        <mat-card-title class="mat-card-title"><mat-icon>access_time</mat-icon></mat-card-title>
        <div class="card-content-container">
          <mat-card-content>
            <h6>Oczekuje na decyzję</h6>
            <p>Autoryzacja została przesłana do podmiotu akceptującego</p>
          </mat-card-content>
        </div>
      </mat-card>
    }
    @if(data.preAuth.decision == 'ODRZUCONO'){
      <mat-card class="title-card danger">
        <mat-card-title class="mat-card-title"><mat-icon>error_outline</mat-icon></mat-card-title>
        <div class="card-content-container">
          <mat-card-content>
            <h6>Odmowa</h6>
            <p>Autoroyzacja została odrzucona przez podmiot akceptujący.</p>
          </mat-card-content>
        </div>
      </mat-card>
    }
    @if(data.preAuth.decision == 'AKCEPTACJA'){
      <mat-card class="title-card success">
        <mat-card-title class="mat-card-title"><mat-icon>check_circle_outline</mat-icon></mat-card-title>
        <div class="card-content-container">
          <mat-card-content>
            <h6>Akceptacja</h6>
            <p>Autoroyzacja została zaakceptowana przez podmiot akceptujący.</p>
          </mat-card-content>
        </div>
    </mat-card>
    }
    @if(data.preAuth.decision == 'SZKODA CAŁKOWITA'){
      <mat-card class="title-card pending">
        <mat-card-title class="mat-card-title"><mat-icon>restore_from_trash</mat-icon></mat-card-title>
        <div class="card-content-container">
          <mat-card-content>
            <h6>Szkoda całkowita</h6>
            <p>Autoroyzacja uzyskała decyzję o szkodzi całkowitej.</p>
          </mat-card-content>
        </div>
    </mat-card>
    }
    <div class="mat-dialog-table-container">
      <ul>
        <li><p>{{ 'preAuth.added_at' | translate }}</p><p>{{ data.preAuth.createdAt | date: 'YYYY-MM-dd | HH:mm' | dashIfEmpty }}</p></li>
        <li><p>{{ 'auth_code' | translate }}</p>
          <p>
            @if(data.preAuth.code == 'W'){
              W - Wycena / Naprawa możliwa droga
            }
            @if(data.preAuth.code == 'N'){
              N - Naprawa niemożliwa
            }
          </p>
        </li>
        <li><p>{{ 'preAuth.diagnosis' | translate }}</p><p>{{ data.preAuth.diagnosis | dashIfEmpty }}</p></li>
        <li><p>{{ 'preAuth.cost' | translate }}</p><p>{{ data.preAuth.estimatedCost | dashIfEmpty }} zł</p></li>
        <li><p>{{ 'preAuth.reason_of_damage' | translate }}</p><p>{{data.preAuth?.causesOfFailure | dashIfEmpty}}</p></li>
        <li><p>{{ 'preAuth.decision_data' | translate }}</p><p>{{ data.preAuth.preauthorizedAt | date: 'YYYY-MM-dd | HH:mm' | dashIfEmpty }}</p></li>
      </ul>
    </div>
  </div>
}
@else{
  <div mat-dialog-content>
    <div class="confirm-choice-container">
      <h6 mat-dialog-title>Decyzja: {{ action | translate }}</h6>
      <p>Czy potwierdzasz wybór?</p>
    </div>
  </div>
  <div mat-dialog-actions class="confirm-actions-container">
    <button mat-stroked-button (click)="cancel()" class="btn">Anuluj</button>
    <button mat-flat-button class="btn blue" (click)="send()">Potwierdz</button>
  </div>
}
@if(Auth.isViewAllowed(['ROLE_ADMIN']) && !confirmDialog && data?.preAuth && !data?.preAuth?.decision && data?.preAuth?.requestedAt){
  <div mat-dialog-actions class="actions-container">
    <button mat-flat-button (click)="confirm('total damage')" class="btn gray">
      Szkoda całkowita
    </button>
    <button mat-flat-button (click)="confirm('reject')" class="btn red">
      Odrzuć
    </button>
    <button mat-flat-button (click)="confirm('accept')" class="btn green">
      Akceptuj
    </button>
  </div>
}