<section class="costs-to-invoice-wrapper">
    <div class="search-container filters-inputs">
        <form [formGroup]="compilationDetailsForm" class="search-container-form">
            <div class="search-inputs-container">
                <mat-form-field appearance="outline">
                    <mat-label>Data wystawienia</mat-label>
                    <input matInput placeholder="Data wystawienia" formControlName="dataWystawienia">
                </mat-form-field>

                <mat-form-field appearance="outline">
                    <mat-label>{{'Numer faktury' | translate}}</mat-label>
                    <input matInput placeholder="Numer faktury" formControlName="invoiceNumber">
                    <mat-icon matSuffix (click)="onCompilationDetailsEdit()">camera_alt</mat-icon>
                </mat-form-field>
            </div>

            <div class="form-actions-container">
                <button mat-stroked-button class="clear-btn" (click)="onCompilationDetailsDelete()">
                    Usuń zestawienie
                </button>
                <button mat-flat-button (click)="onSubmit($event)">
                    Zapisz
                </button>
            </div>
        </form>
    </div>
    <div class="summary-container">
        <div class="summary-info-box">
            <p>Liczba zleceń: <b>{{totalElementsCount}}</b></p>
            <p>Wartość: 
                <b>{{totalSummaryData.net| currency: 'PLN' : 'symbol' : '1.2' : 'pl' | dashIfEmpty}} netto</b> | 
                <b>{{totalSummaryData.gross | currency: 'PLN' : 'symbol' : '1.2' : 'pl' | dashIfEmpty}} brutto</b>
            </p>
        </div>
        <div>

        </div>
    </div>
    <div class="table-container">
        @if (compilationDetailsList) {
        <table>
            <thead>
                <tr>
                    @for (tableHeader of tableHeaders; track i; let i = $index) {
                        <th>
                            {{tableHeader}}
                        </th>   
                    }
                </tr>
            </thead>
            <tbody>
                @if (compilationDetailsList.length) {
                    @for (compilationDetails of compilationDetailsList; track i; let i = $index) {
                        <tr>
                            <td>{{compilationDetails?.faultNumber | dashIfEmpty}}</td>
                            <td>{{compilationDetails?.finishDate | date: 'YYYY-MM-dd | HH:mm' | dashIfEmpty}}</td>
                            <td>{{compilationDetails?.repairType | dashIfEmpty}}</td>
                            <td>{{compilationDetails?.customer | dashIfEmpty}}</td>
                            <td>{{compilationDetails?.closeDate | date: 'YYYY-MM-dd | HH:mm' | dashIfEmpty}}</td>
                            <td>{{compilationDetails?.period | dashIfEmpty}}</td>
                            <td>{{compilationDetails?.totalNet | currency: 'PLN' : 'symbol' : '1.2' : 'pl' | dashIfEmpty}}</td>
                            <td>{{compilationDetails?.totalGross | currency: 'PLN' : 'symbol' : '1.2' : 'pl' | dashIfEmpty}}</td>
                            <!-- <td>{{(compilationDetails?.finishDate | date: 'YYYY-MM-dd | HH:mm')}}</td>
                            <td>{{compilationDetails?.repairType | dashIfEmpty}}</td>
                            <td>{{(compilationDetails?.customer | dashIfEmpty)}}</td>
                            <td>{{(compilationDetails?.closeDate | date: 'YYYY-MM-dd | HH:mm')}}</td>
                            <td>{{(compilationDetails?.period | dashIfEmpty)}}</td>
                            <td>{{(compilationDetails?.totalGross | currency: 'PLN' : 'symbol' : '1.2' : 'pl' | dashIfEmpty)}}</td> -->
                        </tr>
                    }
                }
            </tbody>
        </table>
        }
        @else {
            <div class="no-content-container">Brak danych</div>
        }

        <div class="table-navigation-container">
            <form [formGroup]="paginatorForm">
                <div class="page-size-container">
                    <p>Ilość wyników na stronę</p>
                    <mat-form-field appearance="outline">
                        <mat-select formControlName="limit" (ngModelChange)="fetchCompilationDetailsList()">
                          <mat-option [value]="5">5</mat-option>
                          <mat-option [value]="10">10</mat-option>
                          <mat-option [value]="25">25</mat-option>
                          <mat-option [value]="50">50</mat-option>
                          <mat-option [value]="100">100</mat-option>
                        </mat-select>
                    </mat-form-field>
                </div>
                <div class="paginator-container">
                    <p>{{(paginatorForm.value.offset + 1)}}-{{paginatorForm.value.offset + paginatorForm.value.limit}} z {{totalElementsCount}} wyników</p>
                    <div class="paginator-nav-container">
                        <button mat-icon-button (click)="onPreviousPage($event)" [disabled]="paginatorForm.value.offset == 0">
                            <mat-icon>keyboard_arrow_left</mat-icon>
                        </button>
                        <button mat-icon-button (click)="onNextPage($event)" [disabled]="(paginatorForm.value.offset + paginatorForm.value.limit) >= totalElementsCount">
                            <mat-icon>keyboard_arrow_right</mat-icon>
                        </button>
                    </div>
                </div>
            </form>
        </div>
    </div>
</section>