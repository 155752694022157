import { Component, Input, OnInit } from '@angular/core';
import {
  animate,
  query,
  sequence,
  stagger,
  style,
  transition,
  trigger,
} from '@angular/animations';
import { AuthGuard } from '../../guards/access-allowed.guard';
import { NavigationEnd, Router } from '@angular/router';
import { environment } from 'src/app/environments/environment';
import { UserService } from 'src/app/shared/services/user.service';
import { KeycloakService } from 'keycloak-angular';

export interface NavigationRoute {
  type: 'link' | 'expand';
  expandProperty?: string;
  sectionEnd?: boolean;
  route?: string;
  title: string;
  icon: string;
  canActivate: boolean;
  childRoutes?: NavigationRoute[]
}

export const DropDownAnimation = trigger('dropDownMenu', [
  transition(':enter', [
    style({ height: 0, overflow: 'hidden' }),
    query('.menu-item', [
      style({ opacity: 0, transform: 'translateY(-50px)' }),
    ]),
    sequence([
      animate('200ms', style({ height: '*' })),
      query('.menu-item', [
        stagger(-50, [
          animate('400ms ease', style({ opacity: 1, transform: 'none' })),
        ]),
      ]),
    ]),
  ]),

  transition(':leave', [
    style({ height: '*', overflow: 'hidden' }),
    query('.menu-item', [style({ opacity: 1, transform: 'none' })]),
    sequence([
      query('.menu-item', [
        stagger(50, [
          animate(
            '400ms ease',
            style({ opacity: 0, transform: 'translateY(-50px)' })
          ),
        ]),
      ]),
      animate('200ms', style({ height: 0 })),
    ]),
  ]),
]);

@Component({
  selector: 'app-sidenav',
  templateUrl: './sidenav.component.html',
  styleUrls: ['./sidenav.component.scss'],
  standalone: false
})
export class SidenavComponent implements OnInit {

  public applicationName = environment.applicationName;

  [key: string]: any;
  
  readonly isUserDetailsVisible : boolean = true;

  expandedStates: { [key: string]: boolean } = {};

  language: string = environment.language;

  user: any;

  constructor(
    public Auth: AuthGuard, 
    private userService: UserService,
    private keycloak: KeycloakService
  ) { }

  @Input({required: true}) appLogo!: string;

  @Input({required: true}) appRoutes: NavigationRoute[] = [];

  logout(): void {
    localStorage.clear();
    this.keycloak.logout();
  }

  initializeExpandedStates(): void {
    this.appRoutes.forEach(route => {
      if (route.type === 'expand' && route.expandProperty) {
        this.expandedStates[route.expandProperty] = false;
      }
    });
  }
  
  toggleProperty(propertyName: string): void {
    if (propertyName in this.expandedStates) {
      this.expandedStates[propertyName] = !this.expandedStates[propertyName];
    } else {
      console.warn(`Property ${propertyName} does not exist in expandedStates.`);
    }
  }

  ngOnInit(): void {
      this.fetchUserData();
      this.initializeExpandedStates();
  }

  fetchUserData() : void {
    this.userService.getUserInfo().subscribe({
      next : (userData) => {
        this.user = userData;
        this.userService.setUser(userData);
      },
      error: (error) => {
        console.error(error);
      }
    });
  }
}
