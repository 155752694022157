@if(isChildRoute){
    <router-outlet/>
}
@else{
    <section class="costs-to-invoice-wrapper">
        <div class="search-container filters-inputs">
            <form [formGroup]="compilationsForm" class="search-container-form">
                <mat-form-field appearance="outline">
                    <mat-label>{{'faultNumber' | translate}}</mat-label>
                    <input matInput [placeholder]="('faultNumber' | translate)" formControlName="faultNumber">
                    <button
                        mat-icon-button 
                        matSuffix 
                        *ngIf="compilationsForm.get('faultNumber')?.value" 
                        (click)="clearFaultNumber()"
                    >
                    <mat-icon>close</mat-icon>
                  </button>
                </mat-form-field>
    
                <mat-form-field appearance="outline">
                    <mat-label>Numer zestawienia</mat-label>
                    <input matInput placeholder="Numer zestawienia" formControlName="invoiceListNumber">
                    <button
                        mat-icon-button 
                        matSuffix 
                        *ngIf="compilationsForm.get('invoiceListNumber')?.value" 
                        (click)="clearinvoiceListNumber()"
                    >
                    <mat-icon>close</mat-icon>
                  </button>
                </mat-form-field>
                
                <mat-form-field appearance="outline" class="date-picker-input">
                    <mat-label>Data zakończenia od - do</mat-label>
                    <mat-date-range-input [rangePicker]="createdAtRange" formGroupName="createdAtRange">
                      <input matStartDate formControlName="createdAtDateFrom" [placeholder]="'Start_date' | translate">
                      <input matEndDate formControlName="createdAtDateTo" [placeholder]="'End_date' | translate">
                    </mat-date-range-input>
                    <mat-datepicker-toggle matIconSuffix [for]="createdAtRange"></mat-datepicker-toggle>
                    <mat-date-range-picker #createdAtRange></mat-date-range-picker>
                    @if (compilationsForm.get('createdAtRange.createdAtDateFrom')?.hasError('matEndDateInvalid')) {
                      <mat-error>Nieprawidłowa data początkowa</mat-error>
                    }
                    @if (compilationsForm.get('createdAtRange.createdAtDateTo')?.hasError('matEndDateInvalid')) {
                      <mat-error>Nieprawidłowa data końcowa</mat-error>
                    }
                </mat-form-field>
    
                <div class="form-actions-container">
                    <button mat-stroked-button class="clear-btn" (click)="onClearFilters()">
                        Wyczyść
                    </button>
                    <button mat-flat-button (click)="onSubmit()">
                        Filtruj
                    </button>
                </div>
            </form>
        </div>
        <div class="table-container">
            @if (compilations) {
            <table>
                <thead>
                    <tr>
                        @for (tableHeader of tableHeaders; track i; let i = $index) {
                            <th>{{tableHeader}}</th>   
                        }
                    </tr>
                </thead>
                <tbody>
                    @if (compilations.length) {
                        @for (compilation of compilations; track i; let i = $index) {
                            <tr [routerLink]="'/costs/compilations/compilation/' + compilation.id">
                                <td>{{(compilation?.invoiceListNumber| dashIfEmpty)}}</td>
                                <td>{{(compilation?.invoiceNumber | dashIfEmpty)}}</td>
                                <td>{{(compilation?.createdAt | date: 'YYYY-MM-dd | HH:mm') | dashIfEmpty}}</td>
                                <td>{{((compilation?.status === 'not available yet' ? '-' : (compilation?.status === 'for_settlement' ? 'Do rozliczenia' : 'Rozliczone')) | dashIfEmpty)}}</td>
                                <td>{{(compilation?.closeDate | date: 'YYYY-MM-dd | HH:mm') | dashIfEmpty}}</td>
                                <td>{{(compilation?.totalGross| dashIfEmpty)}}</td>
                            </tr>
                        }
                    }
                </tbody>
            </table>
            }
            @else {
                <div class="no-content-container">Brak danych</div>
            }
    
            <div class="table-navigation-container">
                <form [formGroup]="paginatorForm">
                    <div class="page-size-container">
                        <p>Ilość wyników na stronę</p>
                        <mat-form-field appearance="outline">
                            <mat-select formControlName="limit" (ngModelChange)="fetchCompilations()">
                              <mat-option [value]="5">5</mat-option>
                              <mat-option [value]="10">10</mat-option>
                              <mat-option [value]="25">25</mat-option>
                              <mat-option [value]="50">50</mat-option>
                              <mat-option [value]="100">100</mat-option>
                            </mat-select>
                        </mat-form-field>
                    </div>
                    <!-- <div class="paginator-container">
                        <p>{{(paginatorForm.value.offset + 1)}}-{{paginatorForm.value.offset + paginatorForm.value.limit}} z {{totalElementsCount}} wyników</p>
                        <div class="paginator-nav-container">
                            <button mat-icon-button (click)="onPreviousPage($event)" [disabled]="paginatorForm.value.offset == 0">
                                <mat-icon>keyboard_arrow_left</mat-icon>
                            </button>
                            <button mat-icon-button (click)="onNextPage($event)" [disabled]="(paginatorForm.value.offset + paginatorForm.value.limit) >= totalElementsCount">
                                <mat-icon>keyboard_arrow_right</mat-icon>
                            </button>
                        </div>
                    </div> -->
                </form>
            </div>
        </div>
    </section>
}