<div class="shared-modal-container">
  <div class="shared-modal-header" mat-dialog-draggable-title>
    <div class="shared-modal-header-content">
      <h6>{{ 'orders-front.adding_a_new_contact' | translate }}</h6>
      <p class="title-desc">
        {{ 'services-front.fill_in_the_information_below' | translate }}
      </p>
    </div>
    <button class="shared-modal-close-btn" mat-dialog-close="cancel">
        <fa-icon [icon]="faXmark"/>
    </button>
  </div>
  <div class="shared-modal-body">
      <form [formGroup]="formContactInfo">
        <div class="row grid-2 filters-inputs">
          <mat-form-field appearance="outline" class="full-width">
            <mat-label>{{ 'services-front.name' | translate }}</mat-label>
            <input
              matInput
              formControlName="name"
              required
              type="text"
            />
            <mat-error
              *ngIf="submitted && formContactInfo.controls['name'].errors?.['required']"
            >
              {{ 'services-front.field_is_required' | translate }}
            </mat-error>
            <mat-error
              *ngIf="submitted && formContactInfo.controls['name'].errors?.['pattern']"
            >
              To pole wymaga podania prawidłowego imienia
            </mat-error>
          </mat-form-field>

          <mat-form-field appearance="outline" class="full-width">
            <mat-label>{{ 'services-front.surname' | translate }}</mat-label>
            <input
              matInput
              formControlName="surname"
              required
              type="text"
            />
            <mat-error
              *ngIf="submitted && formContactInfo.controls['surname'].errors?.['required']"
            >
              {{ 'services-front.field_is_required' | translate }}
            </mat-error>
            <mat-error
              *ngIf="submitted && formContactInfo.controls['surname'].errors?.['pattern']"
            >
              To pole wymaga podania prawidłowego nazwiska
            </mat-error>
          </mat-form-field>
        </div> 
        
        <div class="row grid-1">
          <div>
            <ngx-material-intl-tel-input
              class="phone-number"
              [fieldControl]="phone1"
              [preferredCountries]="['pl', 'de', 'us', 'gb']"
              [autoIpLookup]="true"
              [required]="true"
              [iconMakeCall]="false"
              [textLabels]="{
                mainLabel: 'Główny numer telefonu',
                codePlaceholder: 'Kod',
                searchPlaceholderLabel: 'Szukaj',
                noEntriesFoundLabel: 'Nie znaleziono krajów',
                nationalNumberLabel: 'Numer telefonu',
                hintLabel: '',
                invalidNumberError: 'Numer jest niepoprawny',
                requiredError: 'To pole jest wymagane'
              }">
            </ngx-material-intl-tel-input>
            <mat-error
              *ngIf="submitted && formContactInfo.controls['phoneNumber'].errors?.['required']"
              >{{ 'services-front.field_is_required' | translate }}</mat-error
            >
          </div>
          <div>
            <ngx-material-intl-tel-input
              class="phone-number"
              [fieldControl]="phone2"
              [autoIpLookup]="true"
              [iconMakeCall]="false"
              [numberValidation]="true"
              [preferredCountries]="['pl', 'de', 'us', 'gb']"
              [textLabels]="{
                mainLabel: 'Dodatkowy numer telefonu ',
                codePlaceholder: 'Kod',
                searchPlaceholderLabel: 'Szukaj',
                noEntriesFoundLabel: 'Nie znaleziono krajów',
                nationalNumberLabel: 'Numer telefonu',
                hintLabel: '',
                invalidNumberError: 'Numer jest niepoprawny',
                requiredError: 'To pole jest wymagane'
              }">
            </ngx-material-intl-tel-input>
          </div>
        </div>

        <div class="row grid-2 filters-inputs" style="margin-top: 20px;">
          <mat-form-field appearance="outline" class="full-width">
            <mat-label>{{ 'services-front.email_address' | translate }}</mat-label>
            <input
              matInput
              formControlName="email"
              required
              type="text"
            />
            <mat-error
              *ngIf="submitted && formContactInfo.controls['email'].errors?.['required']"
            >
              {{ 'services-front.field_is_required' | translate }}
            </mat-error>
            <mat-error
              *ngIf="submitted && formContactInfo.controls['email'].errors?.['pattern']"
            >
              To pole wymaga podania prawidłowego adresu email
            </mat-error>
          </mat-form-field>

          <mat-form-field appearance="outline" class="full-width">
            <mat-label>{{ 'services-front.contact_type' | translate }}</mat-label>
            <mat-select formControlName="type" required>
              <mat-option value="Main">Główny</mat-option>
              <mat-option value="Additional">Dodatkowy</mat-option>
            </mat-select>
            <mat-error
              *ngIf="submitted && formContactInfo.controls['type'].errors?.['required']"
            >
              {{ 'services-front.field_is_required' | translate }}
            </mat-error>
          </mat-form-field>
        </div>
      </form>
  </div>
  <div class="shared-modal-footer">
      <div class="shared-modal-footer-buttons-container">
        <button class="shared-modal-secondary-btn" mat-dialog-close="cancel">
          {{ 'orders-front.cancel' | translate }}
        </button>
        <button class="shared-modal-primary-btn" mat-dialog-close="success" (click)="add()">
          {{ 'orders-front.save' | translate }}
        </button>
      </div>
  </div>
</div>
<!-- 
<h1 mat-dialog-title class="title">
  {{ 'orders-front.adding_a_new_contact' | translate }}
</h1>
<p class="title-desc">
  {{ 'orders-front.fill_in_the_information_below' | translate }}
</p>
<hr class="custom-hr" />
<div mat-dialog-content>
  <div>
    <form [formGroup]="formContactInfo">
      <div class="flex">
        <div class="did-floating-label-content mr-10 mt-15">
          <input
            class="did-floating-input"
            formControlName="name"
            required
            type="text"
            placeholder=" " />
          <label class="did-floating-label">{{
            'services-front.name' | translate
          }}</label>
          <mat-error
            *ngIf="submitted && formContactInfo.controls['name'].errors?.['required']"
            >{{ 'services-front.field_is_required' | translate }}</mat-error
          >
          <mat-error
            *ngIf="submitted && formContactInfo.controls['name'].errors?.['pattern']"
            >To pole wymaga podania prawidłowego imienia</mat-error
          >
        </div>
        <div class="did-floating-label-content mt-15">
          <input
            class="did-floating-input"
            formControlName="surname"
            required
            type="text"
            placeholder=" " />
          <label class="did-floating-label">{{
            'services-front.surname' | translate
          }}</label>
          <mat-error
            *ngIf="submitted && formContactInfo.controls['surname'].errors?.['required']"
            >{{ 'services-front.field_is_required' | translate }}</mat-error
          >
          <mat-error
            *ngIf="submitted && formContactInfo.controls['surname'].errors?.['pattern']"
            >To pole wymaga podania prawidłowego nazwiska</mat-error
          >
        </div>
      </div>
      <div class="flex">
        <div class="did-floating-label-content mr-10 mt-15">
          <ngx-material-intl-tel-input
            class="phone-number"
            [fieldControl]="phone1"
            [preferredCountries]="['pl', 'de', 'us', 'gb']"
            [autoIpLookup]="true"
            [required]="true"
            [iconMakeCall]="false"
            [textLabels]="{
              mainLabel: 'Główny numer telefonu',
              codePlaceholder: 'Kod',
              searchPlaceholderLabel: 'Szukaj',
              noEntriesFoundLabel: 'Nie znaleziono krajów',
              nationalNumberLabel: 'Numer telefonu',
              hintLabel: '',
              invalidNumberError: 'Numer jest niepoprawny',
              requiredError: 'To pole jest wymagane'
            }">
          </ngx-material-intl-tel-input>
          <mat-error
            *ngIf="submitted && formContactInfo.controls['phoneNumber'].errors?.['required']"
            >{{ 'services-front.field_is_required' | translate }}</mat-error
          >
          <mat-error
            *ngIf="submitted && formContactInfo.controls['phoneNumber'].errors?.['required']"
            >{{ 'services-front.field_is_required' | translate }}</mat-error
          >
          <mat-error
            *ngIf="submitted && formContactInfo.controls['phoneNumber'].errors?.['pattern']"
            >To pole wymaga podania prawidłowego nr telefonu</mat-error
          >
        </div>
        <div class="did-floating-label-content mt-15">
          <ngx-material-intl-tel-input
            class="phone-number"
            [fieldControl]="phone2"
            [autoIpLookup]="true"
            [iconMakeCall]="false"
            [numberValidation]="true"
            [preferredCountries]="['pl', 'de', 'us', 'gb']"
            [textLabels]="{
              mainLabel:
                'Dodatkowy 
             numer telefonu ',
              codePlaceholder: 'Kod',
              searchPlaceholderLabel: 'Szukaj',
              noEntriesFoundLabel: 'Nie znaleziono krajów',
              nationalNumberLabel: 'Numer telefonu',
              hintLabel: '',
              invalidNumberError: 'Numer jest niepoprawny',
              requiredError: 'To pole jest wymagane'
            }">
          </ngx-material-intl-tel-input>
        </div>
      </div>
      <div class="flex">
        <div class="did-floating-label-content mr-10 mt-15">
          <input
            class="did-floating-input"
            formControlName="email"
            required
            type="text"
            placeholder=" " />
          <label class="did-floating-label">{{
            'services-front.email_address' | translate
          }}</label>
          <mat-error
            *ngIf="submitted && formContactInfo.controls['email'].errors?.['required']"
            >{{ 'services-front.field_is_required' | translate }}</mat-error
          >
          <mat-error
            *ngIf="submitted && formContactInfo.controls['email'].errors?.['pattern']"
            >To pole wymaga podania prawidłowego adresu email</mat-error
          >
        </div>
        <div class="did-floating-label-content mt-15">
          <select class="did-floating-select" formControlName="type">
            <option value="Main">Główny</option>
            <option value="Additional">Dodatkowy</option>
          </select>
          <label class="did-floating-label">{{
            'services-front.contact_type' | translate
          }}</label>
          <mat-error
            *ngIf="submitted && formContactInfo.controls['type'].errors?.['required']"
            >{{ 'services-front.field_is_required' | translate }}</mat-error
          >
        </div>
      </div>
    </form>
  </div>

  <div class="text-right buttons" mat-dialog-actions>
    <button
      mat-button
      class="secondary-btn"
      color="accent"
      [mat-dialog-close]="'cancel'">
      {{ 'orders-front.cancel' | translate }}
    </button>
    <button mat-button class="primary-btn" color="primary" (click)="add()">
      {{ 'orders-front.save' | translate }}
    </button>
  </div>
</div> -->
