import {
  AfterViewInit,
  Component,
  ViewChild,
  ChangeDetectorRef,
} from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { UserService } from './shared/services/user.service';
import { ActivityService } from './shared/services/activity.service';
import { LoaderService } from './shared/services/spinner.service';
import { KeycloakService } from 'keycloak-angular';
import { Observable, filter, first, map } from 'rxjs';
import { IConfig } from './shared/modals/config/config.modal';
import { TranslateService } from '@ngx-translate/core';
import { registerLocaleData } from '@angular/common';
import localeFr from '@angular/common/locales/pl';
import { MediaMatcher } from '@angular/cdk/layout';
import { UserIdleService } from 'angular-user-idle';
import { SessionEndingComponent } from './shared/modals/session-ending/session-ending.component';
import { MatDialog } from '@angular/material/dialog';
import { SessionLogoutComponent } from './shared/modals/session-logout/session-logout.component';
import { AuthGuard } from './shared/guards/access-allowed.guard';
import { MatIconRegistry } from '@angular/material/icon';
import { DomSanitizer, Title } from '@angular/platform-browser';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { TitleService } from './shared/services/title.service';
import { NavigationRoute } from 'dashboard-libs/components/sidenav/sidenav.component';

registerLocaleData(localeFr, 'pl');
@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent implements AfterViewInit {
  title = 'backoffice-dashboard';
  user: any;
  token: any;
  config$!: Observable<IConfig>;
  isSidenavOpen: boolean = false;
  mobileQuery!: MediaQueryList;

  private _mobileQueryListener: () => void;

  @ViewChild(MatPaginator) paginator!: MatPaginator;

  constructor(
    private dialog: MatDialog,
    public spinnerService: LoaderService,
    private keycloakService: KeycloakService,
    private activityService: ActivityService,
    private translateService: TranslateService,
    private userIdle: UserIdleService,
    private userService: UserService,
    media: MediaMatcher,
    changeDetectorRef: ChangeDetectorRef,
    public Auth: AuthGuard,
    iconRegistry: MatIconRegistry,
    sanitizer: DomSanitizer,
    private router: Router,
    private route: ActivatedRoute,
    private titleService: TitleService
  ) {
    iconRegistry.addSvgIcon(
      'check-engine',
      sanitizer.bypassSecurityTrustResourceUrl('../assets/check-engine.svg')
    );
    this.mobileQuery = media.matchMedia('(max-width: 600px)');
    this._mobileQueryListener = () => changeDetectorRef.detectChanges();
    this.mobileQuery.addListener(this._mobileQueryListener);
    // this.activityService.startCount();
    this.keycloakService
      .getToken()
      .then(res => {
        this.token = res;
      })
      .catch(err => {});

    keycloakService
      .updateToken(900)
      .then(function (refreshed) {
        if (refreshed) {
          console.log('Token was successfully refreshed');
        } else {
          console.log('Token is still valid');
        }
      })
      .catch(function () {
        console.log('Failed to refresh the token, or the session has expired');
      });

    translateService.addLangs(['en', 'pl', 'fr']);
    translateService.setDefaultLang('pl');

    this.keycloakService.keycloakEvents$.subscribe(res => {
      this.keycloakService
        .getToken()
        .then(res => {
          this.token = res;
        })
        .catch(err => {});
    });

    setInterval(() => {
      this.userService.getKeycloakUserInfo(this.token).subscribe(
        res => {},
        error => {
          if (error.status === 401) {
            localStorage.clear();
            this.dialog.closeAll();
            this.keycloakService.logout();
          }
        }
      );
    }, 10000);
    
    this.titleService.setTitle();
  }

  ngOnInit(): void {
    this.Auth;
    //Start watching for user inactivity.
    this.userIdle.startWatching();

    // Start watching when user idle is starting.
    this.userIdle.onTimerStart().subscribe(() => console.log(1));

    // Start watch when time is up.
    this.userIdle.onTimeout().subscribe(() => {
      this.userIdle.stopWatching();

      this.dialog.closeAll();

      this.dialog.open(SessionLogoutComponent, { disableClose: true });
    });

    this.userIdle
      .onTimerStart()
      .pipe(first())
      .subscribe(isIdle => {
        if (isIdle) {
          this.dialog.open(SessionEndingComponent, { disableClose: true });
        }
      });
  }

  ngAfterViewInit(): void {
    this.userService.getUserInfo().subscribe(res => {
      this.user = res;
      this.userService.setUser(res);
    });
  }

  ngOnDestroy(): void {
    this.mobileQuery.removeListener(this._mobileQueryListener);
  }

  stop() {
    this.userIdle.stopTimer();
  }

  stopWatching() {
    this.userIdle.stopWatching();
  }

  startWatching() {
    this.userIdle.startWatching();
  }

  restart() {
    this.userIdle.resetTimer();
  }

  readonly appLogo : string = 'assets/drs-logo.png';
  
  appRoutes : NavigationRoute[] = [
    {
      type: 'link',
      title: 'navbar.orders',
      route: '/orders',
      icon: 'dashboard',
      canActivate: true
    },
    {
      type: 'expand',
      expandProperty: 'isCostTabExpanded',
      title: 'navbar.settlements',
      icon: 'assignment',
      canActivate: this.Auth.isViewAllowed(['ROLE_BILLINGS_COSTS_VIEW', 'ROLE_BILLINGS_REVENUE_VIEW', 'ROLE_ADMIN']),
      childRoutes: [
        {
          type: 'link',
          title: 'Koszty',
          route: '/costs',
          canActivate: this.Auth.isViewAllowed(['ROLE_BILLINGS_COSTS_VIEW', 'ROLE_ADMIN']),
          icon: 'payment'
        },
        {
          type: 'link',
          title: 'navbar.compilations',
          route: '/costs/compilations',
          canActivate: true,
          icon: 'attach_file'
        },
        {
          type: 'link',
          title: 'Przychody',
          route: '/settlements',
          canActivate: this.Auth.isViewAllowed(['ROLE_BILLINGS_REVENUE_VIEW', 'ROLE_ADMIN']),
          icon: 'receipt'
        },
      ]
    },
    {
      type: 'link',
      title: 'navbar.services',
      route: '/workshops',
      icon: 'build',
      sectionEnd: true,
      canActivate: this.Auth.isViewAllowed(['ROLE_WORKSHOP_VIEW', 'ROLE_ADMIN'])
    },
    {
      type: 'link',
      title: 'navbar.archived-orders',
      route: '/archived-orders',
      icon: 'list_alt',
      canActivate: this.Auth.isViewAllowed(['ROLE_REPORTS', 'ROLE_ADMIN'])
    },
    {
      type: 'link',
      title: 'navbar.reports',
      route: '/reports',
      icon: 'bar_chart',
      canActivate: this.Auth.isViewAllowed(['ROLE_REPORTS', 'ROLE_ADMIN'])
    },
    {
      type: 'link',
      title: 'navbar.users',
      route: '/users',
      canActivate: this.Auth.isViewAllowed(['ROLE_SETTINGS_USERS_VIEW', 'ROLE_ADMIN']),
      icon: 'assignment_ind',
      sectionEnd: true
    },
    {
      type: 'expand',
      expandProperty: 'isSettingsTabExpanded',
      title: 'navbar.settings',
      icon: 'settings',
      canActivate: this.Auth.isViewAllowed(['ROLE_LOGS', 'ROLE_CRONS', 'ROLE_SYSTEM_MARGIN_SETTINGS', 'ROLE_SETTINGS_USERS_VIEW', 'ROLE_ADMIN']),
      childRoutes: [
        {
          type: 'link',
          title: 'navbar.margin_system',
          route: '/settings',
          canActivate: this.Auth.isViewAllowed(['ROLE_SYSTEM_MARGIN_SETTINGS', 'ROLE_ADMIN']),
          icon: 'extension icon'
        },
        {
          type: 'link',
          title: 'navbar.system_settings',
          route: '/system-settings',
          canActivate: this.Auth.isViewAllowed(['ROLE_ADMIN', 'ROLE_SETTINGS']),
          icon: 'settings'
        },
        {
          type: 'link',
          title: 'navbar.crons',
          route: '/admin-panel',
          canActivate: this.Auth.isViewAllowed(['ROLE_CRONS', 'ROLE_ADMIN']),
          icon: 'brightness_auto'
        },
        {
          type: 'link',
          title: 'navbar.logs',
          route: '/logs',
          canActivate: this.Auth.isViewAllowed(['ROLE_LOGS', 'ROLE_ADMIN']),
          icon: 'memory'
        },
        {
          type: 'link',
          title: 'navbar.logs-local',
          route: '/logs-local',
          canActivate: this.Auth.isViewAllowed(['ROLE_LOGS_LOCAL', 'ROLE_ADMIN']),
          icon: 'disc_full'
        }
      ]
    },
  ];
}
