import { HttpParams } from '@angular/common/http';
import { Component, Input, ViewChild } from '@angular/core';

import { MatDialog } from '@angular/material/dialog';
import { MatPaginator, PageEvent } from '@angular/material/paginator';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatTableDataSource } from '@angular/material/table';
import { ActivatedRoute } from '@angular/router';
import { AuthGuard } from 'dashboard-libs/guards/access-allowed.guard';

import { AddRepairUnitComponent } from 'dashboard-libs/modals/add-repair-unit/add-repair-unit.component';
import { OrdersService } from 'dashboard-libs/services/orders.service';
import { UserService } from 'src/app/shared/services/user.service';

@Component({
  selector: 'app-repair-unit',
  templateUrl: './repair-unit.component.html',
  styleUrls: ['./repair-unit.component.scss'],
  //fix to standalone: true later
  standalone: false,
})
export class RepairUnitComponent {
  @Input() workshopId: any;
  
  dataSource: any;
  
  displayedColumns: any = [
    'name',
    'city',
    'street',
    'contactPerson',
    'phoneNumber',
    'mobileNumber',
    'email',
    'actions',
  ];
  
  user: any;
  
  workshop: any;
  
  showButtons: boolean | undefined;
  
  workshopUserId: any;

  @ViewChild(MatPaginator) paginator!: MatPaginator;

  itemsPerPage: number = 30;
  
  offset: number = 0;

  pageIndex: number = 0;

  isLoading: boolean = true;

  constructor(
    public orderService: OrdersService,
    public userService: UserService,
    public dialog: MatDialog,
    public route: ActivatedRoute,
    public Auth: AuthGuard,
    public matSnackBar: MatSnackBar
  ) {
    this.userService.getUserInfo().subscribe((res: any) => {
      this.user = res;
      console.log(this.user);
      if (this.user.workshop != null) {
        this.workshop = this.user.workshop.split('/')[3];
        this.workshop = this.user.workshop.split('/').pop();
      }
      if (this.route.snapshot.params['id']) {
        this.workshopId = this.route.snapshot.params['id'];

        console.log(this.workshopId);
        console.log(this.workshop);
        if (this.workshop == this.workshopId) {
          this.showButtons = true;
        } else {
          this.showButtons = false;
        }
        console.log(this.showButtons);
      } else if (this.route.toString().includes('repair-units')) {
        this.workshopId = this.workshop;
        if (Auth.isViewAllowed(['ROLE_PARTNER_ADMIN', 'ROLE_ADMIN'])) {
          this.showButtons = true;
        } else {
          this.showButtons = false;
        }
      }
      this.fetchRepairUnits();
    });
  }

  private fetchRepairUnits() : void {
    this.isLoading = true;
    let params = new HttpParams();
    params = params.append('limit', this.itemsPerPage);
    params = params.append('offset', this.offset);
    this.orderService
    .getRepairUnitsByWorkshop(this.workshopId, params)
    .subscribe((res: any) => {
      this.dataSource = new MatTableDataSource(res.items['hydra:member']);
      console.log(res.items['hydra:member'])
      this.isLoading = false;
      this.paginator.length = res.totalItems;
    });
  }

  ngOnInit(): void {}

  editRepairUnit(e: Event, repairUnit: any) {
    e.stopPropagation();
    console.log(repairUnit);
    const dialogRef = this.dialog.open(AddRepairUnitComponent, {
      disableClose: true,
      width: '700px',
      data: {
        edit: true,
        name: repairUnit.name,
        streetName: repairUnit.address.streetName,
        buildingNumber: repairUnit.address.buildingNumber,
        apartmentNumber: repairUnit.address.apartmentNumber,
        postalCode: repairUnit.address.postalCode,
        city: repairUnit.address.city,
        email: repairUnit?.contactDetails[0]?.email || '',
        phoneNumber: repairUnit?.contactDetails[0]?.phoneNumber || '',
        mobileNumber: repairUnit?.contactDetails[0]?.mobileNumber || '',
      },
    });
    dialogRef.afterClosed().subscribe(submit => {
      console.log(submit)
      if (submit != 'cancel') {
        console.log(submit);
        const payLoadData = {
          name: submit.name,
          address: {
            streetName: submit.streetName,
            buildingNumber: submit.buildingNumber,
            post: submit.city,
            apartmentNumber: submit.apartmentNumber,
            postalCode: submit.postalCode,
            city: submit.city,
          },
          contactDetails: [
            {
              email: submit.email,
              phoneNumber: submit.phoneNumber,
              mobileNumber: submit.mobileNumber,
            },
          ],
          workshopId: this.workshopId,
        };
        console.log(repairUnit);
        this.orderService.editRepairUnit(repairUnit.id, payLoadData).subscribe(
          res => {
            this.matSnackBar.open(
              'Punkt serwisowy został zedytowany',
              'Zamknij'
            );
            this.reloadData();
          },
          error => {
            this.matSnackBar.open(
              'Wystąpił błąd podczas edycji punktu serwisowego: ' + error,
              'Zamknij'
            );
            console.log(error);
          }
        );
      }
    });
  }

  reloadData() {
    this.fetchRepairUnits();
  }
  addRepairUnit() {
    const dialogRef = this.dialog.open(AddRepairUnitComponent, {
      disableClose: true,
      width: '700px',
      data: {
        workshopId: this.workshopId,
        admin: false,
      },
    });
    dialogRef.afterClosed().subscribe(submit => {
      if (submit != 'cancel') {
        let payLoadData: any = {};
        if (this.route.toString().includes('repair-units')) {
          payLoadData = {
            name: submit.name,
            street: submit.streetName,
            buildingNumber: submit.buildingNumber,
            apartmentNumber: submit.apartmentNumber,
            postalCode: submit.postalCode,
            city: submit.city,
            email: submit.email,
            phoneNumber: submit.phoneNumber,
            mobileNumber: submit.mobileNumber,
          };
        } else {
          payLoadData = {
            name: submit.name,
            street: submit.streetName,
            buildingNumber: submit.buildingNumber,
            apartmentNumber: submit.apartmentNumber,
            postalCode: submit.postalCode,
            city: submit.city,
            email: submit.email,
            phoneNumber: submit.phoneNumber,
            mobileNumber: submit.mobileNumber,
            workshopId: this.workshopId,
          };
        }
        this.orderService.addRepairUnit(payLoadData).subscribe(
          res => {
            this.matSnackBar.open(
              'Punkt serwisowy został dodany',
              'Zamknij'
            );
            this.reloadData();
          },
          error => {
            this.matSnackBar.open(
              'Wystąpił błąd podczas dodawania punktu serwisowego: ' + error,
              'Zamknij'
            );
            console.log(error);
          }
        );
      }
    });
  }

  public onPaginateChange(event: PageEvent) {
    this.offset = event.pageIndex * event.pageSize;
    this.pageIndex = event.pageIndex;
    this.itemsPerPage = event.pageSize;
    this.fetchRepairUnits();
  }

  addRepairUnitAsAdmin() {
    const dialogRef = this.dialog.open(AddRepairUnitComponent, {
      width: '700px',
      disableClose: true,
      data: {
        workshopId: this.workshopId,
        admin: true,
      },
    });
    dialogRef.afterClosed().subscribe(submit => {
      console.log(submit)
      if (submit != 'cancel') {
        const payLoadData = {
          name: submit.name,
          street: submit.streetName,
          buildingNumber: submit.buildingNumber,
          apartmentNumber: submit.apartmentNumber,
          postalCode: submit.postalCode,
          city: submit.city,
          email: submit.email,
          phoneNumber: submit.phoneNumber,
          mobileNumber: submit.mobileNumber,
          workshopId: this.workshopId,
        };

        this.orderService.addRepairUnit(payLoadData).subscribe(
          res => {
            this.matSnackBar.open(
              'Punkt serwisowy został dodany',
              'Close'
            );
            this.reloadData();
          },
          error => {
            this.matSnackBar.open(
              'Wystąpił błąd podczas dodawania punktu serwisowego: ' + error,
              'Zamknij'
            );
            console.log(error);
          }
        );
      }
    });
  }
}
