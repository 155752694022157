import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, NavigationEnd, Router } from '@angular/router';
import { BehaviorSubject, Observable } from 'rxjs';
import { filter } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class BreadcrumbService {

  private _breadcrumbDetails : BehaviorSubject<string> = new BehaviorSubject<string>("");

  public readonly breadCrumbDetails$ = this._breadcrumbDetails as Observable<string>;

  public setBreadCrumbDetails(breadcrumbDetails: string) : void {
    this._breadcrumbDetails.next(breadcrumbDetails);
  }

  private readonly _breadcrumbs$ = new BehaviorSubject<Breadcrumb[]>([]);

  readonly breadcrumbs$ = this._breadcrumbs$.asObservable();

  constructor(private router: Router) {
    this.router.events
      .pipe(
        filter((event) => event instanceof NavigationEnd)
      )
      .subscribe((event) => {
        this.setBreadCrumbDetails("");
        const root = this.router.routerState.snapshot.root;
        const breadcrumbs: Breadcrumb[] = [];
        this.addBreadcrumb(root.firstChild!, [], breadcrumbs);
        this._breadcrumbs$.next(breadcrumbs);
      });
  }

  private addBreadcrumb(
    route: ActivatedRouteSnapshot,
    parentUrl: string[],
    breadcrumbs: Breadcrumb[]
  ) {
    if (!route) return;
  
    if (breadcrumbs.length === 0) {
      breadcrumbs.push({
        label: 'home',
        title: 'Home',
        url: '/',
      });
    }
  
    const routeUrl = parentUrl.concat(route.url.map((url) => url.path));
    const fullUrl = '/' + routeUrl.join('/');
  
    const routeTitle = route.data['title'];
    if (routeTitle) {
      if (!breadcrumbs.some(breadcrumb => breadcrumb.url === fullUrl)) {
        breadcrumbs.push({
          label: this.getLabel(routeTitle),
          disabled: route.data['disabled'],
          title: routeTitle,
          url: fullUrl,
        });
      }
    }
  
    if (route.firstChild) {
      this.addBreadcrumb(route.firstChild, routeUrl, breadcrumbs);
    }
  }

  buildDepth(iterations: number, constituentFolders: string[]) {
    var depthStr = '';

    for (let i = 1; i <= iterations; i++) {
      depthStr = depthStr + '/' + constituentFolders[i];
    }

    return depthStr;
  }

  getLabel(input: string) {
    return input.replace(/-/g, ' ');
  }
}

export interface Breadcrumb {
  label: string;
  title: string;
  disabled?: string;
  url: string;
}
