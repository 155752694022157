import { HttpParams } from '@angular/common/http';
import { Component, ViewChild } from '@angular/core';
import { FormControl } from '@angular/forms';
import { MatPaginator, PageEvent } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import { Router } from '@angular/router';
import { OrdersService } from '../orders/services/orders.service';
import { environment } from 'src/app/environments/environment';
import { AuthGuard } from 'src/app/shared/guards/access-allowed.guard';
import { MatSnackBar } from '@angular/material/snack-bar';

import { GenerateSettlementReportComponent } from 'src/app/shared/modals/generate-settlement-report/generate-settlement-report';
import { MatDialog } from '@angular/material/dialog';
import { ServicesService } from '../services/services/services.service';
import { Sort } from '@angular/material/sort';
import { Clipboard } from '@angular/cdk/clipboard';
@Component({
  selector: 'app-settlement',
  templateUrl: './settlement.component.html',
  styleUrls: ['./settlement.component.scss'],
})
export class SettlementComponent {
  @ViewChild(MatPaginator) paginator!: MatPaginator;

  itemsPerPage: number = 10;
  offset: number = 0;
  pageIndex: number = 0;
  public tab: string = 'Oczekuje na akceptacje';
  statusGroup: any = environment.settlements;
  workshops: any = [];
  showFilters: any = false;
  sort: any = {
    active: 'orderFinishDate',
    direction: 'desc',
  };
  faultNumber = new FormControl();
  orderCreatedAt = new FormControl();
  customer = new FormControl();
  orderWorkshopName = new FormControl();
  totalGross = new FormControl();
  orderFinishDateFrom = new FormControl();
  orderFinishDateTo = new FormControl();
  orderCreatedAtFrom = new FormControl();
  orderCreatedAtTo = new FormControl();
  revenueInvoiceCreatedAt = new FormControl();
  revenueInvoiceNumber = new FormControl();
  orderStatus = new FormControl();
  filteredStates: any[] = [];

  dataSource: any;

  filteredValues = {
    faultNumber: '',
    orderCreatedAt: '',
    customer: '',
    orderWorkshopName: '',
    totalGross: '',
    orderFinishDateFrom: '',
    orderFinishDateTo: '',
    orderCreatedAtFrom: '',
    orderCreatedAtTo: '',
    revenueInvoiceCreatedAt: '',
    revenueInvoiceNumber: '',
    orderStatus: '',
  };
  filterArray: any;
  faultNumberValue: any;
  orderCreatedAtFromValue: any;
  orderCreatedAtToValue: any;
  orderFinishDateFromValue: any;
  orderFinishDateToValue: any;
  orderWorkshopNameValue: any;
  statesCountGrouped: any[string] = [];
  statesCount: any;

  constructor(
    private ordersService: OrdersService,
    private router: Router,
    public Auth: AuthGuard,
    private _snackBar: MatSnackBar,
    public dialog: MatDialog,
    private clipboard: Clipboard,
    private servicesService: ServicesService
  ) {}

  displayedColumns: string[] = [
    'Lp',
    'faultNumber',
    'orderFinishDate',
    'customer',
    'orderWorkshopName',
    'totalGross',
    'orderState',
    'orderCreatedAt',
    'pre-A',
    'revenueInvoiceCreatedAt',
    'revenueInvoiceNumber',
  ];

  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSource.filter = filterValue.trim().toLowerCase();
    if (this.dataSource.paginator) {
      this.dataSource.paginator.firstPage();
    }
  }

  goToDetails(id: string, event: any): void {
    if (event.ctrlKey) {
      window.open('/settlements/' + id, '_blank');
    } else {
      this.router.navigate(['/settlements/' + id]);
    }
  }

  ngOnInit(): void {
    this.tab =
      localStorage.getItem('settlementGroup') ?? 'Oczekuje na akceptacje';

    this.faultNumber.valueChanges.subscribe(positionFilterValue => {
      this.filteredValues['faultNumber'] = positionFilterValue;
    });
    this.orderCreatedAt.valueChanges.subscribe(positionFilterValue => {
      this.filteredValues['orderCreatedAt'] = positionFilterValue;
    });

    this.customer.valueChanges.subscribe(weightFilterValue => {
      this.filteredValues['customer'] = weightFilterValue;
    });
    this.orderWorkshopName.valueChanges.subscribe(weightFilterValue => {
      this.filteredValues['orderWorkshopName'] = weightFilterValue;
    });
    this.totalGross.valueChanges.subscribe(weightFilterValue => {
      this.filteredValues['totalGross'] = weightFilterValue;
    });
    this.orderFinishDateFrom.valueChanges.subscribe(weightFilterValue => {
      this.filteredValues['orderFinishDateFrom'] = weightFilterValue;
    });
    this.orderFinishDateTo.valueChanges.subscribe(weightFilterValue => {
      this.filteredValues['orderFinishDateTo'] = weightFilterValue;
    });
    this.orderCreatedAtFrom.valueChanges.subscribe(weightFilterValue => {
      this.filteredValues['orderCreatedAtFrom'] = weightFilterValue;
    });
    this.orderCreatedAtTo.valueChanges.subscribe(weightFilterValue => {
      this.filteredValues['orderCreatedAtTo'] = weightFilterValue;
    });
    this.revenueInvoiceCreatedAt.valueChanges.subscribe(weightFilterValue => {
      this.filteredValues['revenueInvoiceCreatedAt'] = weightFilterValue;
    });
    this.revenueInvoiceNumber.valueChanges.subscribe(weightFilterValue => {
      this.filteredValues['revenueInvoiceNumber'] = weightFilterValue;
    });
    this.orderStatus.valueChanges.subscribe(weightFilterValue => {
      this.filteredValues['orderStatus'] = weightFilterValue;
    });

    if (localStorage.getItem('settlementFilters') != null) {
      const filterArray = JSON.parse(
        localStorage.getItem('settlementFilters') ?? '{}'
      );
      this.filterArray = filterArray;
    }
    if (this.filterArray != null) {
      this.faultNumber.setValue(this.filterArray['faultNumber']);
      this.orderCreatedAt.setValue(this.filterArray['orderCreatedAt']);
      this.customer.setValue(this.filterArray['customer']);
      this.orderWorkshopName.setValue(this.filterArray['orderWorkshopName']);
      this.totalGross.setValue(this.filterArray['totalGross']);
      this.orderFinishDateFrom.setValue(
        this.filterArray['orderFinishDateFrom']
      );
      this.orderFinishDateTo.setValue(this.filterArray['orderFinishDateTo']);
      this.orderCreatedAtFrom.setValue(this.filterArray['orderCreatedAtFrom']);
      this.orderCreatedAtTo.setValue(this.filterArray['orderCreatedAtTo']);
      this.revenueInvoiceCreatedAt.setValue(
        this.filterArray['revenueInvoiceCreatedAt']
      );
      this.revenueInvoiceNumber.setValue(
        this.filterArray['revenueInvoiceNumber']
      );
      this.orderStatus.setValue(this.filterArray['orderStatus']);
    }

    let params = new HttpParams();
    this.filteredStates = ['finished'];
    params = params.append('limit', this.itemsPerPage);
    this.ordersService.getStatesCount().subscribe((res: any) => {
      this.statesCount = res['hydra:member'];

      this.statusGroup.forEach((element1: any) => {
        this.statesCount.forEach((element2: any) => {
          element1.statuses.forEach((element3: any) => {
            if (element3 == element2.state) {
              this.statesCountGrouped[element1.name] =
                (this.statesCountGrouped[element1.name] || 0) +
                element2.ordersCount;
            }
          });
        });
      });
    });

    this.sendFilters();
  }

  sendFilters() {
    localStorage.setItem(
      'settlementFilters',
      JSON.stringify(this.filteredValues)
    );
    this.statusGroup.some((status: any) => {
      if (status.name == this.tab) {
        this.filteredStates = status.statuses;
      }
    });
    let params = new HttpParams();

    params = params.append(
      'sort[' + this.sort.active + ']',
      this.sort.direction
    );
    this.filteredStates.forEach(element => {
      params = params.append('orderState[]', element);
    });

    params = params.append('limit', this.itemsPerPage);
    params = params.append('offset', this.offset);

    if (
      this.filteredValues['faultNumber'] != null &&
      this.filteredValues['faultNumber'] != ''
    ) {
      params = params.append('faultNumber', this.filteredValues['faultNumber']);
    }
    if (
      this.filteredValues['orderCreatedAt'] != null &&
      this.filteredValues['orderCreatedAt'] != ''
    ) {
      const createdAtFrom = new Date(this.filteredValues['orderCreatedAt']);
      const formattedDate = createdAtFrom.toLocaleDateString('es-CL', {
        day: '2-digit',
        month: '2-digit',
        year: 'numeric',
      });
      params = params.append('orderCreatedAt', formattedDate);
    }
    if (
      this.filteredValues['customer'] != null &&
      this.filteredValues['customer'] != ''
    ) {
      params = params.append('customer', this.filteredValues['customer']);
    }
    if (
      this.filteredValues['orderWorkshopName'] != null &&
      this.filteredValues['orderWorkshopName'] != ''
    ) {
      params = params.append(
        'orderWorkshopName',
        this.filteredValues['orderWorkshopName']
      );
    }
    if (
      this.filteredValues['totalGross'] != null &&
      this.filteredValues['totalGross'] != ''
    ) {
      params = params.append('totalGross', this.filteredValues['totalGross']);
    }
    if (
      this.filteredValues['orderFinishDateFrom'] != null &&
      this.filteredValues['orderFinishDateFrom'] != ''
    ) {
      const orderFinishDate = new Date(
        this.filteredValues['orderFinishDateFrom']
      );
      const formattedDate = orderFinishDate.toLocaleDateString('es-CL', {
        day: '2-digit',
        month: '2-digit',
        year: 'numeric',
      });
      params = params.append('orderFinishDateFrom', formattedDate);
    }
    if (
      this.filteredValues['orderCreatedAtFrom'] != null &&
      this.filteredValues['orderCreatedAtFrom'] != ''
    ) {
      const orderFinishDate = new Date(
        this.filteredValues['orderCreatedAtFrom']
      );
      const formattedDate = orderFinishDate.toLocaleDateString('es-CL', {
        day: '2-digit',
        month: '2-digit',
        year: 'numeric',
      });
      params = params.append('orderCreatedAtFrom', formattedDate);
    }
    if (
      this.filteredValues['orderCreatedAtTo'] != null &&
      this.filteredValues['orderCreatedAtTo'] != ''
    ) {
      const orderFinishDate = new Date(this.filteredValues['orderCreatedAtTo']);
      const formattedDate = orderFinishDate.toLocaleDateString('es-CL', {
        day: '2-digit',
        month: '2-digit',
        year: 'numeric',
      });
      params = params.append('orderCreatedAtTo', formattedDate);
    }
    if (
      this.filteredValues['orderFinishDateTo'] != null &&
      this.filteredValues['orderFinishDateTo'] != ''
    ) {
      const orderFinishDate = new Date(
        this.filteredValues['orderFinishDateTo']
      );
      const formattedDate = orderFinishDate.toLocaleDateString('es-CL', {
        day: '2-digit',
        month: '2-digit',
        year: 'numeric',
      });
      params = params.append('orderFinishDateTo', formattedDate);
    }
    if (
      this.filteredValues['revenueInvoiceCreatedAt'] != null &&
      this.filteredValues['revenueInvoiceCreatedAt'] != ''
    ) {
      params = params.append(
        'revenueInvoiceCreatedAt',
        this.filteredValues['revenueInvoiceCreatedAt']
      );
    }
    if (
      this.filteredValues['revenueInvoiceNumber'] != null &&
      this.filteredValues['revenueInvoiceNumber'] != ''
    ) {
      params = params.append(
        'revenueInvoiceNumber',
        this.filteredValues['revenueInvoiceNumber']
      );
    }

    this.ordersService.getBillingListFiltered(params).subscribe((res: any) => {
      this.dataSource = new MatTableDataSource(res.items['hydra:member']);
      setTimeout(() => {
        this.paginator.length = res.totalItems;
      });
    });
  }
  sortData(sort: Sort) {
    this.sort = sort;
    this.sendFilters();
  }

  getShortName(fullName: any) {
    return fullName
      .split(' ')
      .map((n: any) => n[0])
      .join('');
  }
  clearFilters() {
    this.faultNumber.setValue('');
    this.orderCreatedAt.setValue('');
    this.customer.setValue('');
    this.orderWorkshopName.setValue('');
    this.totalGross.setValue('');
    this.orderFinishDateFrom.setValue('');
    this.orderFinishDateTo.setValue('');
    this.orderCreatedAtTo.setValue('');
    this.orderCreatedAtFrom.setValue('');
    this.revenueInvoiceCreatedAt.setValue('');
    this.revenueInvoiceNumber.setValue('');
    this.orderStatus.setValue('');
    localStorage.removeItem('settlementFilters');
    localStorage.removeItem('settlementGroup');
    this.tab = 'Oczekuje na akceptacje';

    this.sendFilters();
  }

  onPaginateChange(event: PageEvent) {
    this.offset = event.pageIndex * event.pageSize;
    this.pageIndex = event.pageIndex;
    this.itemsPerPage = event.pageSize;
    this.sendFilters();
  }

  filter(filterArray: []) {
    this.filteredStates = filterArray;

    this.paginator.pageIndex = 0;
    this.offset = 0;
    this.sendFilters();
  }

  downloadSummary(): void {
    const dialogRef = this.dialog.open(GenerateSettlementReportComponent, {
      disableClose: true,
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result === 'show') {
      }
    });
  }

  getWorkshops() {
    this.servicesService.getServices().subscribe((res: any) => {
      this.workshops = res['hydra:member'];
    });
  }

  changeGroup(item: any) {
    this.tab = item.name;
    this.filter(item.statuses);
    localStorage.setItem('settlementGroup', item.name);
    console.log(item);
    console.log(this.tab);
  }

  openInNewTabOrder(row: any) {
    window.open('/orders/order/' + row.value.id, '_blank');
  }

  openInNewTabCost(row: any) {
    window.open('/costs/' + row.value.id, '_blank');
  }

  openInNewTabSettlement(row: any) {
    window.open('/settlements/' + row.value.id, '_blank');
  }

  public copy(row: any, itemName: string) {
    if (itemName == 'faultNumber') {
      this.clipboard.copy(row.value.id);
    } else if (itemName == 'workshopName') {
      this.clipboard.copy(row.value.workshopName);
    } else if (itemName == 'deviceName') {
      this.clipboard.copy(row.value.deviceName);
    } else if (itemName == 'revenueInvoiceNumber') {
      this.clipboard.copy(row.value.revenueInvoiceNumber);
    }
    this._snackBar.open('Skopiowano do schowka', 'Zamknij', {
      duration: 3000,
    });
  }

  openLogsOfOrder(row: any) {
    console.log(row);
    window.open('/logs/' + row.value.faultNumber, '_blank');
  }
}
